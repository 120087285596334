import { Button, Card, Descriptions, Form, FormInstance, Tag } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import dayjs from 'dayjs'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { FC, useEffect, useState } from 'react'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { AbsFormInput } from 'shared/ui/abs-form-items/abs-form-input'
import { AbsFormSelect } from 'shared/ui/abs-form-items/abs-form-select'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	useWbsTaskCard,
	useWbsTaskCardConstructionObjects,
	useWbsTaskCardContainers,
	useWbsTaskCardContracts,
	useWbsTaskCardEstimates,
	useWbsTaskCardPotentialAssignUsers,
	useWbsTaskCardType,
	useWbsTaskCardTypeSections,
	useWbsTaskCardWorkPackages
} from '../api/wbs-task-card-queries'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'
import { WbsTaskModalAssign } from './wbs-task-modal-assign'
import { WbsTaskModal } from './wbs-task-modal-basis'

type TProps = {
	form: FormInstance
	formValues: TWbsTaskForm
	canEdit: boolean
}

export enum WBS_TASK {
	wbs,
	estimate,
	common
}

export const WbsTaskCardDetails: FC<TProps> = ({ form, formValues, canEdit }) => {
	const checkPermissions = useCheckPermissions()
	const canEditAssignEconomy = checkPermissions([PERMISSIONS.WbsTasksAssignedTo])
	const canEditAssignDesign = checkPermissions([PERMISSIONS.WbsTasksAssignedToDES])
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const { openModalAssign, setOpenModal, setOpenModalAssign } = useWbsTaskCardState()
	// const requestType = Object.entries(WBS_TASK_TYPE).map(item => ({
	// 	id: item[1],
	// 	name: item[1]
	// }))
	const WBS_TASK_TYPE = {
		new: { name: 'Новый ВОР', id: '0c81dc39-3cf1-49a2-b436-bf44dfbc4e3c' },
		wbsAdjustment: { name: 'Кор Вор через БЫЛО-СТАЛО', id: '4383ff3a-ada7-4ca6-a7f2-0355262b7192' },
		estimateAdjustment: {
			name: 'Кор ЛС через БЫЛО-СТАЛО',
			id: 'de9d5968-f261-4c2e-84c4-603d6f9465bf'
		}
	}
	const requestType = Object.entries(WBS_TASK_TYPE).map(item => ({
		id: item[1].id,
		name: item[1].name
	}))
	const { data, isFetching } = useWbsTaskCard()
	const { data: constructionObjects, isFetching: constructionObjectsFetching } =
		useWbsTaskCardConstructionObjects()
	const { data: workPackages, isFetching: workPackagesFetching } = useWbsTaskCardWorkPackages()
	const { data: typeSections, isFetching: typeSectionsFetching } = useWbsTaskCardTypeSections()
	const { data: contracts, isFetching: contractsFetching } = useWbsTaskCardContracts()
	const { data: potentialAssignUsers, isFetching: potentialAssignUsersFetching } =
		useWbsTaskCardPotentialAssignUsers(data?.wbsTaskStatus?.id!)
	const { data: estimates, isFetching: estimatesFetching } = useWbsTaskCardEstimates(
		formValues?.contractId!,
		formValues?.constructionObjectId!
	)
	const { data: containers, isFetching: containersFetching } = useWbsTaskCardContainers(
		formValues?.typeSectionId!,
		formValues?.constructionObjectId!
	)
	const { data: wbsTaskType, isFetching: wbsTaskTypeFetching } = useWbsTaskCardType()
	const { currentTaskId } = useWbsTaskState()
	const contractField = Form.useWatch('contractId', form)
	const constructionObjectField = Form.useWatch('constructionObjectId', form)
	const wbsTaskTypeField = Form.useWatch('wbsTaskTypeId', form)
	const [assigned, setAssigned] = useState<string[]>([])

	useEffect(() => {
		setAssigned(data!?.assignedUsers?.map(a => a?.id!) ?? [])
	}, [data, openModalAssign])

	useEffect(() => {
		form.setFieldValue('assignedUsers', assigned)
	}, [assigned])

	const onAssignedSelect = (user: string) => {
		setAssigned(prev => [...prev, user])
	}

	const onAssignedDeselect = (user: string) => {
		const users = assigned.filter(u => u !== user)
		setAssigned(users)
	}

	useEffect(() => {
		if (estimates?.length === 0 || contractField || constructionObjectField || wbsTaskTypeField) {
			form.setFieldValue('estimateId', undefined)
			form.setFieldValue('estimateLabel', undefined)
		}
		if (containers?.length === 0 || contractField || constructionObjectField || wbsTaskTypeField) {
			form.setFieldValue('containerId', undefined)
			form.setFieldValue('containerLabel', undefined)
		}
	}, [estimates, containers])

	console.log('estimates', estimates)

	const items: DescriptionsItemType[] = [
		{
			key: 'wbsTaskType',
			children: (
				<AbsFormSelect
					label="Тип заявки"
					name="wbsTaskTypeId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={wbsTaskType}
					isLoading={wbsTaskTypeFetching}
					dataSourceValue={data?.wbsTaskType.name}
				/>
			)
		},
		{
			key: 'constructionObject',
			children: (
				<AbsFormSelect
					label="Объект строительства"
					name="constructionObjectId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={constructionObjects}
					isLoading={constructionObjectsFetching}
					dataSourceValue={data?.constructionObject.name}
				/>
			)
		},
		{
			key: 'createdAt',
			children: (
				<Form.Item label="Дата создания">
					<span className="app-form-text">
						{dayjs(data?.createdAt).format(DATE_FORMAT.viewWithHours) ?? Date.now()}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'author',
			children: (
				<Form.Item label="Автор">
					<span className="app-form-text">
						{data?.createdBy?.name ?? `${user?.firstName} ${user?.lastName}`}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'workPackage',
			children: (
				<AbsFormSelect
					label="Пакет работ"
					name="workPackageId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={workPackages}
					isLoading={workPackagesFetching}
					dataSourceValue={data?.workPackage.name}
				/>
			)
		},
		{
			key: 'typeSection',
			children: (
				<AbsFormSelect
					label="Вида раздела"
					name="typeSectionId"
					rules={[{ required: true }]}
					canEdit={canEdit}
					form={form}
					options={typeSections}
					isLoading={typeSectionsFetching}
					dataSourceValue={data?.typeSection.name}
				/>
			)
		},
		{
			key: 'title',
			children: (
				<AbsFormInput
					label="Наименование"
					name="title"
					rules={[{ required: true }]}
					canEdit={canEdit}
					dataSourceValue={data?.title}
				/>
			)
		},
		{
			key: 'assignedUsers',
			children: !data ? (
				<Form.Item label="Ответственный">
					{potentialAssignUsers?.map(assignedUser => (
						<Tag key={assignedUser?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
							{assignedUser?.name}
						</Tag>
					))}
				</Form.Item>
			) : data?.wbsTaskStatus.id === WBS_TASK_STATUS.new ||
			  data?.wbsTaskStatus.id === WBS_TASK_STATUS.done ? (
				<Form.Item label="Ответственный">
					{data.assignedUsers.map(assignedUser => (
						<Tag key={assignedUser?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
							{assignedUser?.name}
						</Tag>
					))}
				</Form.Item>
			) : (
				<Form.Item
					label="Ответственный"
					name={'assignedUsers'}
					className="wbsTaskFieldBasis"
					rules={[{ required: true }]}
				>
					<span className="app-form-text">
						{data?.assignedUsers?.map(a => (
							<Tag key={a?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
								{a?.name}
							</Tag>
						)) ?? ''}
					</span>

					{((canEditAssignEconomy && data.wbsTaskStatus.id === WBS_TASK_STATUS.docs) ||
						(canEditAssignDesign && data.wbsTaskStatus.id === WBS_TASK_STATUS.inProgress)) && (
						<Button onClick={setOpenModalAssign} style={{ marginRight: 5, marginTop: 5 }}>
							Назначить ответственных
						</Button>
					)}
				</Form.Item>
			)
		},
		{
			key: 'instruction',
			children: (
				<AbsFormInput
					label="Поручение"
					name="instruction"
					canEdit={canEdit}
					isArea={true}
					dataSourceValue={data?.instruction}
				/>
			)
		},
		{
			key: 'project',
			children: (
				<Form.Item label="Проект">
					<span className="app-form-text">{data?.project?.name ?? currentProject.name}</span>
				</Form.Item>
			)
		},
		{
			key: 'contract',
			children: (
				<AbsFormSelect
					label="Договор"
					name="contractId"
					// rules={[{ required: formValues.requestTypeId === WBS_TASK_TYPE.estimateAdjustment }]}
					canEdit={canEdit}
					form={form}
					options={contracts}
					isLoading={contractsFetching}
					dataSourceValue={data?.contract?.name}
				/>
			)
		},
		{
			key: 'wbs',
			children: (
				<Form.Item label="Основание" name={'containerLabel'} className="wbsTaskFieldBasis">
					<span className="app-form-text">
						{formValues?.containerLabel ?? data?.container?.name ?? ''}
					</span>
					<Button onClick={setOpenModal} style={{ marginRight: 5, marginTop: 5 }}>
						Выбрать основание
					</Button>
				</Form.Item>
			)
		},
		{
			key: 'estimate',
			children: (
				<Form.Item label="Основание" name={'estimateLabel'} className="wbsTaskFieldBasis">
					<span className="app-form-text">
						{formValues?.estimateLabel ?? data?.estimate?.name ?? ''}
					</span>
					<Button onClick={setOpenModal} style={{ marginRight: 5, marginTop: 5 }}>
						Выбрать основание
					</Button>
				</Form.Item>
			)
		},
		{
			key: 'estimateValue',
			children: <Form.Item name={'estimateId'} style={{ display: 'none' }}></Form.Item>
		},
		{
			key: 'wbsValue',
			children: <Form.Item name={'containerId'} style={{ display: 'none' }}></Form.Item>
		}
	]

	const wbsTaskFields: Record<WBS_TASK, string[]> = {
		[WBS_TASK.wbs]: [
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'project',
			'wbs',
			'wbsValue'
		],

		[WBS_TASK.estimate]: [
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'project',
			'contract',
			'estimate',
			'estimateValue'
		],
		[WBS_TASK.common]: [
			'wbsTaskType',
			'title',
			'createdAt',
			'author',
			'constructionObject',
			'workPackage',
			'typeSection',
			'instruction',
			'assignedUsers',
			'project'
		]
	}

	const mergedItems = () => {
		const wbsTaskItems = wbsTaskFields[
			formValues?.wbsTaskTypeId === '4383ff3a-ada7-4ca6-a7f2-0355262b7192'
				? WBS_TASK.wbs
				: formValues?.wbsTaskTypeId === 'de9d5968-f261-4c2e-84c4-603d6f9465bf'
				? WBS_TASK.estimate
				: WBS_TASK.common
		].map(x => items.find(i => i.key === x)!)
		return wbsTaskItems
	}

	return (
		<>
			<WbsTaskModal
				isEstimate={
					formValues?.wbsTaskTypeId === 'de9d5968-f261-4c2e-84c4-603d6f9465bf' ? true : false
				}
				form={form}
				formValues={formValues}
			/>
			<WbsTaskModalAssign
				// canEdit={canEditAssign}
				options={potentialAssignUsers}
				form={form}
				isLoading={potentialAssignUsersFetching}
				tagRender={tagRender}
				assigned={assigned}
				setAssigned={setAssigned}
				onAssignedSelect={onAssignedSelect}
				onAssignedDeselect={onAssignedDeselect}
			/>
			<Card
				title="Основные параметры"
				className="app-work-card"
				classNames={{ body: 'app-work-card__body' }}
			>
				<Descriptions
					items={mergedItems()}
					size="small"
					column={1}
					colon={false}
					labelStyle={{ display: 'none' }}
					className={'app-drawer-descriptions'}
					bordered
				/>
			</Card>
		</>
	)
}

const tagRender = (props: CustomTagProps) => {
	const { label, value, closable, onClose } = props
	const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault()
		event.stopPropagation()
	}
	return (
		<Tag
			color={'blue'}
			onMouseDown={onPreventMouseDown}
			closable={false}
			onClose={onClose}
			style={{ marginRight: 3 }}
		>
			{label}
		</Tag>
	)
}
