import {
	BranchesOutlined,
	DeleteOutlined,
	EditOutlined,
	PartitionOutlined,
	PlusSquareOutlined,
	SaveOutlined,
	StopOutlined
} from '@ant-design/icons'
import { Button, Col, Dropdown, MenuProps, Row, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useRef } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { WbsAndLocalActionsImportExport } from 'widgets/wbs/ui/WbsAndLocalActionsImportExport'
import { ILocalEstimate, useLocalEstimateRegistryState } from '..'

interface IProps {
	record: ILocalEstimate
	editable: boolean
	isLoading: boolean
	onSave: (record: ILocalEstimate) => void
	data?: ILocalEstimate[] | null
}

export const LocalEstimateRegistryActions: FC<IProps> = params => {
	const checkPermissions = useCheckPermissions()
	const uploadRef = useRef<any>(null)
	const { record, editable, isLoading, data, onSave } = params

	const {
		editableRow,
		setEditableRow,
		setCorrectionRecord,
		setAdjustmentRecord,
		setContainerToRemove
	} = useLocalEstimateRegistryState()

	const { user } = useAppSelector(state => state.environment)

	const isAuthor =
		(record?.author?.id === user?.id || record?.redactors.some(r => r.id === user?.id)) ?? false

	const hasAdjustment = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return !!res?.children.filter(
			c => c.adjustmentId !== null && c.status?.name !== CONTAINER_STATUS.approved
		).length
	}

	const canCreateAdjustmentEstimate = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return record.mainParent === null
			? !res?.children?.find(c => c.adjustmentId !== null)
			: !res?.children?.filter(c => c.adjustmentId !== null).find(r => r.parent?.id === record.id)
	}

	const canCreateCorrectionEstimate = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return record.mainParent === null
			? !res?.children?.find(c => c.adjustmentId === null)
			: !res?.children?.find(r => r.parent?.id === record.id && r.adjustmentId === null)
	}

	const canCreateAdjustment: boolean =
		canCreateAdjustmentEstimate() &&
		record.status?.name === 'Approved' &&
		checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
		record.adjustmentId === null

	const itemsEstimates: MenuProps['items'] = [
		{
			key: 'correction',
			label: `Корректировка`,
			type: 'group',
			children: [
				{
					key: 'createCorrection',
					label: (
						<Tooltip
							zIndex={999999999}
							placement="left"
							title={
								(record.author?.id === user?.id ||
									record.redactors?.some(r => r.id === user?.id)) &&
								checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
								record.status?.name !== 'Approved'
									? record.adjustmentId
										? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
										: 'Создать Корректировку Локальной сметы можно только из КЛС в статусе «Завершена оценка НМТР. Локальная смета отправлена в ЕИС»'
									: ''
							}
						>
							Создать Корректировку Локальной сметы
						</Tooltip>
					),
					icon: <BranchesOutlined />,
					disabled:
						canCreateCorrectionEstimate() === false ||
						(!user?.isAdmin &&
							(!(
								record.status?.name === 'Approved' &&
								((record.mainParent !== null &&
									(record.author?.id === user?.id ||
										record.redactors?.some(r => r.id === user?.id))) ||
									record.mainParent === null)
							) ||
								!checkPermissions([PERMISSIONS.LocalEstimateCreate]))) ||
						// record.children?.length >= 1 ||
						(record.status?.name !== 'Approved' && record.mainParent !== null),
					onClick: () => setCorrectionRecord(record)
				},
				{
					key: 'createAdjustment',
					label: 'Создать документ "Было/Стало"',
					icon: <PartitionOutlined />,
					disabled: !canCreateAdjustment,
					onClick: () => setAdjustmentRecord(record)
				}
			]
		}
	]

	const items: MenuProps['items'] = [
		{
			key: 'main',
			label: 'Основные',
			type: 'group',
			children: [
				{
					key: 'edit',
					label: 'Редактировать',
					icon: <EditOutlined />,
					disabled:
						record.adjustmentId !== null
							? record.status?.name === CONTAINER_STATUS.approved ||
							  (!user?.isAdmin &&
									!(
										checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
										editableRow === undefined &&
										isAuthor
									))
							: !checkPermissions([PERMISSIONS.LocalEstimateCreate]) ||
							  (!checkPermissions([PERMISSIONS.LocalEstimateCreate]) && !isAuthor) ||
							  editableRow !== undefined,

					onClick: () => setEditableRow(record)
				},
				{
					key: 'delete',
					label:
						record.adjustmentId !== null
							? 'Удалить документ "Было/Стало"'
							: 'Удалить Корректировку Локальной сметы',
					danger: true,
					icon: <DeleteOutlined />,
					disabled: record.adjustmentId
						? !checkPermissions([PERMISSIONS.AdjustmentDelete])
						: record.ready || !user?.isAdmin,
					onClick: () => setContainerToRemove(record)
				}
			]
		},
		{
			key: 'correction',
			label: `Корректировка`,
			type: 'group',
			children: [
				{
					key: 'createCorrection',
					label: (
						<Tooltip
							zIndex={999999999}
							placement="left"
							title={
								(record.author?.id === user?.id ||
									record.redactors?.some(r => r.id === user?.id)) &&
								checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
								record.status?.name !== 'Approved'
									? record.adjustmentId
										? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
										: 'Создать Корректировку Локальной сметы можно только из КЛС в статусе «Завершена оценка НМТР. Локальная смета отправлена в ЕИС»'
									: ''
							}
						>
							Создать Корректировку Локальной сметы
						</Tooltip>
					),
					icon: <BranchesOutlined />,
					disabled:
						canCreateCorrectionEstimate() === false ||
						(!user?.isAdmin &&
							(!(
								record.status?.name === 'Approved' &&
								((record.mainParent !== null &&
									(record.author?.id === user?.id ||
										record.redactors?.some(r => r.id === user?.id))) ||
									record.mainParent === null)
							) ||
								!checkPermissions([PERMISSIONS.LocalEstimateCreate]))) ||
						// record.children?.length >= 1 ||
						(record.status?.name !== 'Approved' && record.mainParent !== null),
					onClick: () => setCorrectionRecord(record)
				},
				{
					key: 'createAdjustment',
					label: 'Создать документ "Было/Стало"',
					icon: <PartitionOutlined />,
					disabled: !canCreateAdjustment,
					onClick: () => setAdjustmentRecord(record)
				}
			]
		},
		{
			key: 'importExport',
			label: `Импорт / Экспорт ${record.adjustmentId ? 'Было/Стало' : 'Локальной сметы'}`,
			type: 'group',
			children: WbsAndLocalActionsImportExport({ record, uploadRef, isLocal: true })
		}
	]

	return editable ? (
		<Row gutter={2} wrap={false}>
			<Col>
				<Tooltip title="Сохранить">
					<Button
						icon={<SaveOutlined />}
						type="primary"
						loading={isLoading}
						onClick={() => {
							onSave(record)
						}}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Отмена">
					<Button
						icon={<StopOutlined />}
						onClick={() => setEditableRow(undefined)}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
		</Row>
	) : (
		<>
			<Dropdown
				trigger={['click']}
				menu={{
					items: record.mainParent === null ? itemsEstimates : items,
					style: { minWidth: '256px' }
				}}
				placement="bottomRight"
			>
				<Button type="link" icon={<PlusSquareOutlined />} />
			</Dropdown>
			<span style={{ display: 'none' }}>
				<EstimateImportData
					containerId={record.adjustmentId ? record?.adjustmentId : record?.id}
					disabled={record?.ready}
					trigger={uploadRef}
					registry="localEstimateRegistry"
					isAdjustment={record.adjustmentId ? true : false}
				/>
			</span>
		</>
	)
}

// export const LocalEstimateRegistryActions: FC<IProps> = params => {
// const checkPermissions = useCheckPermissions()
// const { record, editable, isLoading, onSave } = params
// const {
// 	editableRow,
// 	setEditableRow,
// 	setCorrectionRecord,
// 	setAdjustmentRecord,
// 	setContainerToRemove
// } = useLocalEstimateRegistryState()

// const { user, permissions } = useAppSelector(state => state.environment)
// const wbsPermissions = useWbsPermits()

// const isAuthor =
// 	(user?.isAdmin ||
// 		record?.author?.id === user?.id ||
// 		record?.redactors.some(r => r.id === user?.id)) ??
// 	false

// const canCreateAdjustment: boolean =
// 	record.status?.name === 'Approved' &&
// 	(user?.isAdmin || checkPermissions([PERMISSIONS.AdjustmentCreate]))

// return editable ? (
// 	<Row gutter={2} wrap={false}>
// 		<Col>
// 			<Tooltip title="Сохранить">
// 				<Button
// 					icon={<SaveOutlined />}
// 					type="primary"
// 					loading={isLoading}
// 					onClick={() => onSave(record)}
// 					style={{ marginRight: 8 }}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		<Col>
// 			<Tooltip title="Отмена">
// 				<Button
// 					icon={<StopOutlined />}
// 					onClick={() => setEditableRow(undefined)}
// 					style={{ marginRight: 8 }}
// 				/>
// 			</Tooltip>
// 		</Col>
// 	</Row>
// ) : (
// 	<Row gutter={6} wrap={false}>
// 		<Col>
// 			<Tooltip title="Создать Корректировку Локальной сметы">
// 				<Button
// 					data-attr="buttonCreateLocalEstimateCorrect"
// 					icon={<BranchesOutlined />}
// 					loading={isLoading}
// 					disabled={
// 						(record.status?.name !== 'Approved' && record.mainParent !== null) ||
// 						(!user?.isAdmin && !wbsPermissions.canLocalEdit) ||
// 						record.children?.length >= 1
// 					}
// 					onClick={() => setCorrectionRecord(record)}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		<Col>
// 			<Tooltip title='Создать документ "Было/Стало"'>
// 				<Button
// 					data-attr="buttonCreateLocalEstimateAdjustment"
// 					icon={<PartitionOutlined />}
// 					loading={isLoading}
// 					disabled={!canCreateAdjustment}
// 					onClick={() => setAdjustmentRecord(record)}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		{record.mainParent !== null && (
// 			<>
// 				<Col>
// 					<Tooltip title="Редактировать">
// 						<Button
// 							data-attr="buttonEditVOR"
// 							icon={<EditOutlined />}
// 							disabled={
// 								record.ready ||
// 								!wbsPermissions.canLocalEdit ||
// 								(wbsPermissions.canLocalEdit && !isAuthor) ||
// 								editableRow !== undefined
// 							}
// 							onClick={() => setEditableRow(record)}
// 						/>
// 					</Tooltip>
// 				</Col>
// 				<Col>
// 					<WbsToExcel row={record} registry="localEstimateRegistry" />
// 				</Col>
// 				<Col>
// 					<Tooltip title="Удалить Корректировку Локальной сметы">
// 						<Button
// 							data-attr="buttonDeleteVOR"
// 							type="primary"
// 							loading={isLoading}
// 							disabled={record.ready || !user?.isAdmin}
// 							danger
// 							onClick={() => setContainerToRemove(record)}
// 							icon={<DeleteOutlined />}
// 						/>
// 					</Tooltip>
// 				</Col>
// 			</>
// 		)}
// 	</Row>
// )
// }
