import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormInstance, Row, Select } from 'antd'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { ISSUE_STATUS } from 'shared/constants/issue-types'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useShallow } from 'zustand/react/shallow'
import { useIssueCardState } from '../model/issue-card-state'

interface IProps {
	isNew: boolean
	canEdit: boolean
	form: FormInstance<TIssueDetailForm>
	isLoading: boolean
}

/*
	(user?.id !== issueDto?.ownerId &&
		user?.companyId === issueDto?.contractorId &&
		status.id !== statuses.ready!) ||
	(status.id === statuses.ready! && !permissions.canSetToReady) ||
	(status.id !== statuses.ready! && !permissions.canEdit) ||
	(status.id === statuses.ready && issueInit?.issueStatusId !== statuses.open)
*/

export const IssueCardHeader: FC<IProps> = ({ isNew, canEdit, form, isLoading }) => {
	const { initForm, setModalType } = useIssueCardState(
		useShallow(state => ({
			initForm: state.initForm,
			setModalType: state.setCommentModalType
		}))
	)
	const checkPermissions = useCheckPermissions()
	const user = useAppSelector(state => state.environment.user)
	const canSetToReady =
		checkPermissions([PERMISSIONS.IssueToReadyForControl]) &&
		// user?.id !== issueDto?.ownerId &&
		// user?.companyId === issueDto?.contractorId &&
		// initForm.issueStatusId !== ISSUE_STATUS.ready! ||
		// (initForm.issueStatusId === ISSUE_STATUS.ready! &&
		// checkPermissions([PERMISSIONS.IssueToReadyForControl])) ||
		initForm?.issueStatusId === ISSUE_STATUS.open
	const issueTypes = [
		{
			id: '2d9df9da-5340-4f2a-ad30-77a24bb1bfea',
			name: 'Закрыто',
			identityName: 'closed',
			disabled: !canEdit
		},
		{
			id: '5758e9f3-b35b-4d97-8616-aa3bbb96131f',
			name: 'Готово к проверке',
			identityName: 'ready_to_inspect',
			disabled: !canSetToReady
		},
		{
			id: '4ae3200c-d2df-4c70-922a-780ea1575a35',
			name: 'Открыто',
			identityName: 'open',
			disabled: !canEdit || (initForm?.issueStatusId === ISSUE_STATUS.closed && !user?.isAdmin)
		},
		{
			id: '958da9b4-0ac3-43bc-8f0c-3b61341083b7',
			name: 'Отменено',
			identityName: 'cancel',
			disabled: !canEdit || (initForm?.issueStatusId === ISSUE_STATUS.closed && !user?.isAdmin)
		}
	]

	const onStatusChange = (statusTo: TIssueDetailForm['issueStatusId']) => {
		if (initForm.issueStatusId === ISSUE_STATUS.open && statusTo === ISSUE_STATUS.ready)
			setModalType('fromOpenToReady')
		if (initForm.issueStatusId === ISSUE_STATUS.ready && statusTo === ISSUE_STATUS.open)
			setModalType('fromReadyToOpen')
	}

	return (
		<Row style={{ width: '480px' }} wrap={false} align="middle" gutter={16} justify="end">
			{!isNew && (
				<Col flex={1}>
					<Form.Item labelAlign="right" label="Статус" name="issueStatusId" style={{ margin: 0 }}>
						<Select
							options={issueTypes}
							fieldNames={{ label: 'name', value: 'id' }}
							style={{ width: '100%' }}
							onChange={onStatusChange}
							className="issue-select-status"
						/>
					</Form.Item>
				</Col>
			)}
			<Col>
				<Button
					htmlType="submit"
					icon={<SaveOutlined />}
					onClick={() => form.submit()}
					disabled={!canEdit}
					loading={isLoading}
					ghost
					style={{ borderColor: '#fff', color: '#fff' }}
				>
					Сохранить
				</Button>
			</Col>
		</Row>
	)
}
