import { IBase } from 'interfaces/IBase'
import { IContactPersons, IContractor, IProject } from 'interfaces/IEnvironment'
import { IEstimate } from 'interfaces/IEstimate'
import { API } from './ApiService'

export const getProjectList = async (projectId?: string | null, permissionId?: string) => {
	return await API<IProject[]>({
		method: 'get',
		url: '/v1/projects',
		params: { projectId, permissionId }
	}).then(async response => response.data)
}

export const setSectionCount = async (dto: Partial<IProject>) => {
	return await API({
		method: 'patch',
		url: '/v1/projects',
		data: dto
	}).then(async response => response)
}

export const getContractorList = async (projectId?: string | null) => {
	return await API<IContractor[]>({
		method: 'get',
		url: '/v1/contractors',
		params: { projectId }
	}).then(async response => response.data)
}

export const getEstimateList = async () => {
	return await API<IEstimate[]>({
		method: 'get',
		url: '/v1/estimates2'
	}).then(async response => response.data)
}

export const getAllContactPersons = async () => {
	return await API<IContactPersons[]>({
		method: 'get',
		url: '/v1/contactPersons/allContactPerson'
	}).then(async response => response.data)
}

export const getContractorsCommission = async (contractorId: string) => {
	return await API<IBase[]>({
		url: `/v1/contractors/commissioning`,
		method: 'get',
		params: {contractorId}
	}).then(async response => response.data)
}