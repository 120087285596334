import { IPagination } from "interfaces/IPagination"
import { IWbsTaskFilters } from "pages/wbs-tasks-page/model/wbs-tasks-interfaces"
import { API } from "services/ApiService"
import { PAGE_LIMIT } from "shared/constants"
import { IWbsTask } from "../model/wbs-task-registry-interface"

export const wbsTaskRegistryApi = {
	async get(currentPage: number, wbsTaskFilters?: IWbsTaskFilters, sortState?: number) {
		return await API<IPagination<IWbsTask[]>>({
			url: `/v1/wbsTask`,
			method: 'get',
            params: {
				limit: PAGE_LIMIT,
				offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT,
				sortState,
                ...wbsTaskFilters
			}
		}).then(response => response.data)
	}
}