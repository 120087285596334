import { FilterOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Form, Input } from 'antd'
import { Switch } from 'antd/lib'
import { useAppDispatch } from 'hooks/appReduxHook'
import { useEffect } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { DashboardLayout } from 'widgets/dashboards'
import { WbsTaskCard } from 'widgets/wbs-task-card'
import { WbsTaskRegistry } from 'widgets/wbs-task-registry'
import { useWbsTaskState } from './model/wbs-tasks-state'
import WbsTasksFilters from './ui/wbs-tasks-filters'

export const WbsTasksPage = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Заявки ВОР' }))
	}, [])

	const { showFilters, wbsTaskFilters, openCard, setShowFilters, setWbsTaskFilters, onPagination } =
		useWbsTaskState()
	const queryClient = useQueryClient()

	const onSearch = (searchValue: string | undefined) => {
		const filters = { ...wbsTaskFilters, search: searchValue! }
		setWbsTaskFilters({ ...filters })
		onPagination(1)
	}

	return (
		<>
			<WbsTasksFilters open={showFilters} />
			<DashboardLayout
				body={<WbsTaskRegistry />}
				overlays={[<WbsTaskCard />]}
				headerLeft={[
					// <Button icon={<SettingOutlined />} />,
					<Button
						icon={<ReloadOutlined />}
						onClick={() => queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })}
					/>,
					<Button
						icon={<FilterOutlined />}
						onClick={() => setShowFilters(true)}
						type={
							Object.entries(wbsTaskFilters).some(
								x => x[0] !== 'search' && x[0] !== 'myWbsTask' && typeof x[1] !== 'undefined'
							)
								? 'primary'
								: 'default'
						}
					/>,
					<Input.Search
						allowClear
						addonAfter={false}
						placeholder="Поиск..."
						defaultValue={wbsTaskFilters.search}
						onSearch={value => onSearch(value)}
						style={{ width: 256 }}
					/>,
					<Form>
						<Form.Item label="Только мои задачи" style={{ margin: '0 0 0 20px' }}>
							<Switch
								size="small"
								onChange={() => {
									setWbsTaskFilters({ ...wbsTaskFilters, myWbsTask: !wbsTaskFilters.myWbsTask })
									onPagination(1)
								}}
							/>
						</Form.Item>
					</Form>
				]}
				headerRight={[
					<Button type="primary" icon={<PlusOutlined />} onClick={() => openCard()}>
						Создать заявку
					</Button>
				]}
			/>
		</>
	)
}
