import { Card, Descriptions, Form } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { IIssueCard } from 'entities/issues'
import { FC } from 'react'

interface IProps {
	dataSource?: IIssueCard | null
}

export const IssueCardDetailsRelated: FC<IProps> = ({ dataSource }) => {
	const items: DescriptionsItemType[] = [
		{
			key: 'ordinance',
			children: (
				<Form.Item label="Предписание">
					<span className="app-form-text">
						{dataSource?.ordinance?.name ?? dataSource?.ordinance?.number ?? '-'}
					</span>
				</Form.Item>
			)
		},
		{
			key: 'checklist',
			children: (
				<Form.Item label="Чек-лист">
					<span className="app-form-text">{dataSource?.checklist?.number ?? '-'}</span>
				</Form.Item>
			)
		}
	]
	return (
		<Card
			title="Дополнительная информация"
			className="app-work-card"
			classNames={{ body: 'app-work-card__body' }}
		>
			<Descriptions
				items={items}
				size="small"
				column={1}
				colon={false}
				labelStyle={{ display: 'none' }}
				className={'app-drawer-descriptions'}
				bordered
			/>
		</Card>
	)
}
