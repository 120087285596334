import { ColorPicker, Form, Input, Select } from 'antd'
import { FC } from 'react'
import { mainSelectProps } from 'shared/helpers'
import { IWbsTaskStatusRegistry, IWbsTaskVisa } from '../model/wbs-task-status-interface'
import { useWbsTaskRoles } from '../model/wbs-task-status-queries'
import { useWbsTaskStatusState } from '../model/wbs-task-status-state'

interface EditableCellProps {
	title: React.ReactNode
	record: IWbsTaskStatusRegistry | IWbsTaskVisa
	dataIndex: 'name' | 'rolesId' | 'color'
	children: React.ReactNode
	editing: boolean
}

export const WbsTaskStatusCell: FC<EditableCellProps> = props => {
	const { title, record, dataIndex, children, ...restProps } = props
	const { editing: editingCell, setEditing } = useWbsTaskStatusState()
	const { data: roles } = useWbsTaskRoles()
	const getRuleMessage = () => {
		switch (true) {
			case dataIndex === 'name':
				return 'Укажите наименование'
			case dataIndex === 'rolesId':
				return 'Выберете хотя бы одну роль'
		}
	}

	const getNode = () => {
		switch (true) {
			case dataIndex === 'name':
				return <Input allowClear></Input>
			case 'parentId' in record && dataIndex === 'rolesId':
				return (
					<Select
						{...mainSelectProps}
						allowClear
						mode="multiple"
						options={roles?.map(item => ({
							key: item.id,
							value: item.id,
							label: item.name
						}))}
					/>
				)
			case 'color' in record && dataIndex === 'color':
				return (
					<ColorPicker format="hex" defaultValue={'color' in record ? record.color : undefined} />
				)
			default:
				return children
		}
	}

	const childNode =
		editingCell === record?.id! ? (
			<Form.Item
				name={dataIndex}
				style={{ margin: 0 }}
				rules={[
					{
						required: dataIndex === 'name' || ('roles' in record && dataIndex === 'rolesId'),
						message: getRuleMessage()
					}
				]}
			>
				{getNode()}
			</Form.Item>
		) : (
			children
		)

	return (
		<td {...restProps}>
			<div className="app-wbs-table__cell">{childNode}</div>
		</td>
	)
}
