import { RowSelectionState } from '@tanstack/react-table'
import { SelectProps } from 'antd'
import dayjs from 'dayjs'
import { IProject } from 'interfaces/IEnvironment'
import { API } from 'services/ApiService'
import { DATE_FORMAT, PRODUCTION_CALENDAR } from './constants'

export const findNestedObj = <T>(entireObj: any[], keyToFind: string, valToFind: any) => {
	let foundObj: T | undefined
	JSON.stringify(entireObj, (_, nestedValue) => {
		if (nestedValue && nestedValue[keyToFind] === valToFind) {
			foundObj = nestedValue
		}
		return nestedValue
	})
	return foundObj ?? null
}

export const mainSelectProps: Partial<SelectProps<any, any>> = {
	showSearch: true,
	optionFilterProp: 'children',
	style: { width: '100%' },
	filterOption: (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
	filterSort: (optionA, optionB) =>
		(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}

export const downloadDocument = (data: Blob, filename: string) => {
	const href = URL.createObjectURL(data)
	const link = document.createElement('a')
	link.href = href
	link.setAttribute('download', filename)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	URL.revokeObjectURL(href)
}

export const getNearestWorkingDate = (
	format: 'view' | 'dto' = 'view',
	from: dayjs.Dayjs = dayjs(),
	offset: number = 0
): string => {
	const startDate = PRODUCTION_CALENDAR.findIndex(d => d.date === from.format('DD.MM.YYYY'))
	const timeOffset = Number(from.format('HH')) >= 18 ? 1 : 0
	const nearestDate = PRODUCTION_CALENDAR.slice(
		startDate +
			1 +
			(PRODUCTION_CALENDAR.find(elem => elem.date === from.add(1, 'day').format('DD.MM.YYYY'))
				?.type_id !== 1
				? timeOffset
				: 0) +
			offset,
		startDate + 30
	).find(x => [1, 5].includes(x.type_id))?.date
	return nearestDate
		? dayjs(nearestDate, 'DD.MM.YYYY')
				.add(19, 'h')
				.add(0, 'm')
				.add(0, 's')
				.format(`${DATE_FORMAT[format]}THH:mm:ss`)
		: dayjs().add(19, 'h').add(0, 'm').add(0, 's').format(`${DATE_FORMAT[format]}THH:mm:ss`)
}

// export const checkPermissions = (
// 	permissions: IUserPermission[] | null,
// 	currentPermits: string[]
// ) => {
// 	for (let i = 0; i < currentPermits.length; i++) {
// 		if (permissions?.some((el: IUserPermission) => el.name === currentPermits[i])) {
// 			return true
// 		}
// 	}
// 	return false
// }

export const checkPermissionByProject = async (permissionId: number, projectId: string) => {
	const projects = await API<IProject[]>({
		url: '/v1/projects',
		method: 'get',
		params: { permissionId }
	}).then(response => response.data)
	return typeof projects.find(p => p.id === projectId) !== 'undefined'
}

export const CheckDeclension = (int: number, words: string[], array?: any) => {
	return (
		(array = array || words) &&
		array[int % 100 > 4 && int % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][int % 10 < 5 ? int % 10 : 5]]
	)
}

export const removeEmpty = (obj: any) => {
	let newObj: any = {}
	Object.keys(obj as Object)?.forEach(key => {
		if (obj[key] !== undefined) newObj[key] = obj[key]
	})
	return newObj
}

export const moveRow = (
	positionEstimates: any,
	rowMainIndex: number,
	move?: 'up' | 'down',
	adjustment: boolean = false
) => {
	if (positionEstimates?.length! > 1) {
		if (adjustment) {
			if (positionEstimates![rowMainIndex! - 1]?.isUnrelated! === false && move === 'up') {
				return true
			}
			return false
		} else if (positionEstimates![rowMainIndex! - 1]?.parentId! !== null && move === 'up') {
			return true
		}
		return false
	}
	return false
}

export const moveSomeRow = (
	positionEstimates: any,
	selectedRows: { parentId?: string; rows: RowSelectionState },
	adjustment: boolean = false,
	move?: 'up' | 'down'
) => {
	if (!!Object.keys(selectedRows?.rows!).length) {
		const firstIndex = positionEstimates!?.findIndex((elem: any) =>
			Object.keys(selectedRows?.rows!).includes(elem.id)
		)
		const lastIndex = positionEstimates!?.findLastIndex((elem: any) =>
			Object.keys(selectedRows?.rows!).includes(elem.id)
		)
		if (adjustment) {
			if (
				(positionEstimates![firstIndex! - 1]?.isUnrelated! === false &&
					move === 'up' &&
					firstIndex !== -1) ||
				(positionEstimates![lastIndex! + 1]?.isUnrelated! === false &&
					move === 'down' &&
					lastIndex !== -1) ||
				Object.keys(selectedRows?.rows!)?.some(elemId =>
					positionEstimates
						?.filter((elem: any) => elem.isUnrelated === false)
						?.map((pe: any) => pe.id)
						?.includes(elemId)
				)
			) {
				return true
			}
			return false
		} else if (
			(positionEstimates![firstIndex! - 1]?.parentId! !== null &&
				move === 'up' &&
				firstIndex !== -1) ||
			(positionEstimates![lastIndex! + 1]?.parentId! !== null &&
				move === 'down' &&
				lastIndex !== -1) ||
			Object.keys(selectedRows?.rows!)?.some(elemId =>
				positionEstimates
					?.filter((elem: any) => elem.parentId !== null)
					?.map((pe: any) => pe.id)
					?.includes(elemId)
			)
		) {
			return true
		}
		return false
	}
	return false
}
