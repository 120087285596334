import dayjs from 'dayjs'
import { IssueSource } from 'enums'
import { IContract } from 'interfaces/IContract'
import { IContractor, IProject } from 'interfaces/IEnvironment'
import { IEstimate } from 'interfaces/IEstimate'
import {
	IIssue,
	IIssueComment,
	IIssueDTO,
	IIssueSort,
	IIssueStatus,
	IIssueSubType,
	IIssueViolationType,
	IIssuesFilters,
	IIssuesType
} from 'interfaces/IIssue'
import { IPagination } from 'interfaces/IPagination'
import { IIssueDTOExtended, IIssue as IIssueNew } from 'widgets/issue/model/issue-interface'
import { API } from './ApiService'

export const issuesElementTranslate = (element: string) => {
	if (element && typeof element === 'string') {
		switch (element!?.toLowerCase()) {
			case 'open':
				return 'Открыто'
			case 'draft':
				return 'Черновик'
			case 'work completed':
				return 'Работа завершена'
			case 'ready_to_inspect':
				return 'Готово к проверке'
			case 'in dispute':
				return 'В споре'
			case 'not approved':
				return 'Не утверждено'
			case 'cancel':
				return 'Отменено'
			case 'void':
				return 'Недействительно'
			case 'quality':
				return 'Качество'
			case 'safety':
				return 'Безопасность'
			default:
				return element
		}
	} else {
		return 'error'
	}
}

export const getIssuesList = async ({
	filters,
	limit = 50,
	offset = 0,
	sort
}: {
	filters: IIssuesFilters | undefined
	limit?: number
	offset?: number
	sort: IIssueSort
}) => {
	if (filters?.dueDate !== undefined && filters?.dueDate !== null) {
		filters = {
			...filters,
			dueDate: dayjs(filters?.dueDate).format('YYYY-MM-DD')
		}
	}
	if (filters?.createdAt !== undefined && filters?.createdAt !== null) {
		filters = {
			...filters,
			createdAt: dayjs(filters?.createdAt).format('YYYY-MM-DD')
		}
	}

	const response = await API<IPagination<IIssue[]>>({
		url: `/v1/issues`,
		method: 'get',
		params: { limit: limit, offset: offset, ...sort, ...filters }
	})
	return response.data
}

export const getIssueById = async (issueId: string) => {
	return await API<IIssue>({
		url: `/v1/issues/${issueId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getIssueStatusList = async () => {
	const response = await API<IIssueStatus[]>({
		url: `/v1/issueStatuses`,
		method: 'get'
	})
	return response.data
}

export const getIssueTypes = async () => {
	const response = await API<IIssuesType[]>({
		url: `/v1/issueTypes`,
		method: 'get'
	})
	return response.data
}

export const getIssueSubTypes = async (IssueTypeId?: string) => {
	const response = await API<IIssueSubType[]>({
		url: `/v1/issueSubTypes`,
		method: 'get',
		params: { IssueTypeId }
	})
	return response.data
}

export const createOrUpdateIssue = async (
	issue: IIssueDTO | IIssueNew | IIssueDTOExtended,
	method: string,
	issueSource?: IssueSource
) => {
	const project = localStorage.getItem('project')
	const parsedProject: IProject = project ? JSON.parse(project) : null
	if (issue.projectId.length === 0) {
		issue = { ...issue, projectId: parsedProject.id }
	}
	const response = await API({
		url: `/v1/issues`,
		method: method,
		data: {
			...issue,
			dueDate: dayjs(issue.dueDate).isValid() ? dayjs(issue.dueDate).format('YYYY-MM-DD') : null,
			issueSource
		},
		params: { projectId: parsedProject.id }
	})
	return response
}

export const getViolationTypes = async (issueSubTypeId?: string) => {
	const response = await API<IIssueViolationType[]>({
		url: `/v1/violationTypes`,
		method: 'get',
		params: { issueSubTypeId }
	})
	return response.data
}

export const getIssueAttachments = async (issueId: string) => {
	const response = await API<any[]>({
		url: `/v1/issues/${issueId}/attachments`,
		method: 'get'
	})
	return response.data
}

export const addIssueAttachments = async (issueId: string, body: FormData, conf: any) => {
	const response = await API({
		url: `/v1/issues/${issueId}/attachments`,
		method: 'post',
		data: body,
		...conf
	})
	return response.data
}

export const getIssueAttachment = async (fileId: string) => {
	const response = await API<Blob>({
		url: `/v1/files/${fileId}/download`,
		method: 'get',
		responseType: 'blob'
	})
	return response.data
}

export const getContractsByProject1CId = async (project1CId?: string, contractorId?: string) => {
	const response = await API<IContract[]>({
		url: `/v1/contracts`,
		method: 'get',
		params: { project1CId, contractorId }
	})
	return response.data
}

export const getContractors = async (projectId?: string) => {
	const response = await API<IContractor[]>({
		url: `/v1/contractors`,
		method: 'get',
		params: { projectId: projectId }
	})
	return response.data
}

export const getContractorsById = async (id: string) => {
	const response = await API<IContractor>({
		url: `/v1/contractors/${id}`,
		method: 'get'
	})
	return response.data
}

export const getEstimates = async (project1CId: string, contractorId: string) => {
	const response = await API<IEstimate[]>({
		url: `/v1/estimates`,
		method: 'get',
		params: { project1CId: project1CId, contractorId: contractorId }
	})
	return response.data
}

export const setReadyToInsepct = async (issueId: string, dto: FormData) => {
	const response = await API({
		headers: { 'content-type': 'multipart/form-data' },
		url: `/v1/issues/${issueId}/readyForControl`,
		method: 'patch',
		data: dto
	})
	return response
}

export const getAuthors = async (projectId: string) => {
	return await API({
		url: `/v1/issues/get-authors/${projectId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getPotentialAuthors = async (projectId: string) => {
	return await API({
		url: `/v1/issues/potentialAuthors/${projectId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getComments = async (issueId: string) => {
	return await API<IIssueComment[]>({
		url: `/v1/issueComment/${issueId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const addComment = async (commentDto: FormData) => {
	return await API({
		headers: { 'content-type': 'multipart/form-data' },
		url: '/v1/issueComment',
		method: 'post',
		data: commentDto
	}).then(async response => response.data)
}

export const deleteIssueAttachment = async (issueId: string, attachmentId: string) => {
	return await API({
		url: `/v1/issues/${issueId}/attachments/${attachmentId}`,
		method: 'delete'
	}).then(async response => response)
}

export const deleteIssueAttachments = async (issueId: string, attachments: React.Key[]) => {
	return await API({
		url: `/v1/issues/${issueId}/attachments`,
		method: 'delete',
		data: { attachments }
	}).then(async response => response)
}

export const deleteIssue = async (issueId: string) => {
	return await API({
		url: `/v1/issues/${issueId}`,
		method: 'delete'
	}).then(async response => response)
}

export const issueTypeService = {
	async addType(name: string) {
		return await API({
			url: '/v1/issueTypes',
			method: 'post',
			data: [{ name }]
		}).then(async response => response.data)
	},

	async addSubType(name: string, issueTypeId: string, order: number) {
		return await API({
			url: '/v1/issueSubTypes',
			method: 'post',
			data: [{ name, issueTypeId, code: 0, order }]
		}).then(async response => response.data)
	},

	async addViolationType(name: string, issueSubTypeId: string) {
		return await API({
			url: '/v1/violationTypes',
			method: 'post',
			data: [{ name, issueSubTypeId, code: 0, violationWeight: 0 }]
		}).then(async response => response.data)
	},

	async updateType({ id, name, isDeleted }: { id: string; name: string; isDeleted: boolean }) {
		return await API({
			url: '/v1/issueTypes',
			method: 'post',
			data: [{ id, name, isDeleted }]
		}).then(async response => response.data)
	},

	async updateSubType({
		id,
		name,
		issueTypeId,
		order,
		code,
		isDeleted
	}: {
		id: string
		name: string
		issueTypeId: string
		order?: number | null
		code: number
		isDeleted: boolean
	}) {
		return await API({
			url: '/v1/issueSubTypes',
			method: 'post',
			data: [{ id, name, issueTypeId, order, code, isDeleted }]
		}).then(async response => response.data)
	},

	async updateViolationType({
		id,
		name,
		issueSubTypeId,
		code,
		violationWeight,
		isDeleted
	}: {
		id: string
		name: string
		issueSubTypeId: string
		code: number
		violationWeight: number
		isDeleted: boolean
	}) {
		return await API({
			url: '/v1/violationTypes',
			method: 'post',
			data: [{ id, name, issueSubTypeId, code, violationWeight, isDeleted }]
		}).then(async response => response.data)
	},

	async deleteType(type: string, id: string) {
		return await API({
			url: `/v1/${type}/${id}`,
			method: 'delete'
		}).then(async response => response.data)
	}
}
