import { DeleteOutlined, ProfileOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, notification, Row, Select, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { NavLink, useNavigate } from 'react-router-dom'
import { DATE_FORMAT } from 'shared/constants'
import { CheckDeclension } from 'shared/helpers'
import { useWbsTaskDelete } from '../api/wbs-task-registry-queries'
import { IWbsTask } from '../model/wbs-task-registry-interface'

const StatusCell = ({ value, status }: { value: string; status: string }) => {
	return (
		<Row gutter={8} wrap={false} align="middle">
			<Col>
				<div className={`indicator indicator--${status}`} />
			</Col>
			<Col flex={1}>{value}</Col>
		</Row>
	)
}
export const useWbsRegistryColumns = () => {
	const queryClient = useQueryClient()
	const { mutate } = useWbsTaskDelete()
	const navigate = useNavigate()
	const columns: ColumnsType<IWbsTask> = [
		{
			key: 'wbsTaskStatus',
			dataIndex: ['wbsTaskStatus', 'description'],
			title: 'Статус',
			width: 220,
			sorter: true,
			fixed: 'left',
			render: (value, record) => <StatusCell value={value} status={record.wbsTaskStatus.name} />
		},
		{
			key: 'wbsTaskType',
			dataIndex: ['wbsTaskType', 'name'],
			title: 'Тип заявки',
			sorter: true,
			fixed: 'left',
			width: 220
		},
		{
			key: 'number',
			dataIndex: ['number'],
			title: 'Номер заявки',
			width: 130,
			sorter: true,
			fixed: 'left',
			align: 'center'
		},
		{
			dataIndex: ['title'],
			title: 'Наименование',
			align: 'center',
			fixed: 'left',
			width: 200
		},
		{
			key: 'createdAt',
			dataIndex: ['createdAt'],
			title: 'Дата создания',
			width: 192,
			sorter: true,
			align: 'center',
			fixed: 'left',
			render: value => dayjs(value).format(DATE_FORMAT.viewWithHours)
		},
		{
			dataIndex: ['createdBy', 'name'],
			title: 'Автор',
			align: 'center',
			width: 240
		},
		{
			dataIndex: ['assignedUsers'],
			title: 'Исполнитель',
			align: 'center',
			width: 220,
			render: (_, record) =>
				record.assignedUsers &&
				(record.assignedUsers.length > 1 ? (
					<Select
						popupClassName="app-selector-no-flow"
						showSearch={false}
						value={record
							?.assignedUsers!?.sort(
								(a, b) => +b?.isDeleted! - +a?.isDeleted! || a?.name!?.localeCompare(b?.name!)
							)
							.map(x => x?.id)}
						tagRender={props => (
							<Tag color="blue" style={{ fontSize: 14 }} bordered={false}>
								{props.label}
							</Tag>
						)}
						maxTagCount={1}
						mode="multiple"
						variant="borderless"
						style={{ width: '100%' }}
						options={record?.assignedUsers?.map(x => ({
							key: x?.id,
							value: x?.id,
							label: x?.name
						}))}
					/>
				) : (
					record.assignedUsers.map(assignedUser => (
						<Tag key={assignedUser?.id} style={{ fontSize: 14 }} bordered={false} color="blue">
							{assignedUser?.name}
						</Tag>
					))
				))
		},
		{
			dataIndex: ['contract', 'name'],
			title: 'Договор',
			width: 200
		},
		{
			dataIndex: ['container', 'name'],
			title: 'ВОР-основание',
			align: 'center',
			width: 180,
			render: (_, record) =>
				record.container && (
					<Tooltip title={record.container.name}>
						<NavLink to={`/unmodel/${record.container.id}`} target="_blank">
							{<ProfileOutlined />}
						</NavLink>
					</Tooltip>
				)
		},
		{
			dataIndex: ['estimate', 'name'],
			title: 'Смета-основание',
			align: 'center',
			width: 180,
			render: (_, record) =>
				record.estimate && (
					<Tooltip title={record.estimate.name}>
						<NavLink to={`/local/${record.estimate.id}`} target="_blank">
							{<ProfileOutlined />}
						</NavLink>
					</Tooltip>
				)
		},
		{
			key: 'duration',
			dataIndex: ['duration'],
			title: 'Общее время',
			width: 140,
			align: 'center',
			render: value =>
				value!?.slice(0, 5).includes('.')
					? `${value!?.split('.')[0]} ${CheckDeclension(+value!?.split('.')[0], [
							'день',
							'дня',
							'дней'
					  ])} ${value!.split('.')[1]?.slice(0, 2)} ${CheckDeclension(
							+value!.split('.')[1]?.slice(0, 2),
							['час', 'часа', 'часов']
					  )}`
					: value!?.slice(0, 2) !== '00'
					? `${value!?.slice(0, 2)} ${CheckDeclension(+value!?.slice(0, 2), [
							'час',
							'часа',
							'часов'
					  ])}`
					: 'менее часа'
		},
		{
			width: 64,
			align: 'center',
			render: record => (
				<Button
					danger
					icon={<DeleteOutlined />}
					onClick={() => {
						mutate(
							{ wbsTaskId: record?.id },
							{
								onSuccess: () => {
									notification.success({
										message: 'Заявка успешно удалена',
										placement: 'topLeft'
									})
									queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })
								},
								onError: (error: any) => {
									notification.error({
										message: 'Произошла ошибка во время удаления',
										description: error?.response?.data?.detail,
										placement: 'topLeft'
									})
								}
							}
						)
					}}
				/>
			)
		}
	]
	return columns
}
