import { Flex, FormInstance } from 'antd'
import { UploadFile } from 'antd/lib'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { FC } from 'react'
import { useWbsTaskAttachments } from '../api/wbs-task-card-queries'
import { WbsTaskAttachmentList } from './wbs-task-attachments-list'
import { WbsTaskAttachmentsUploader } from './wbs-task-attachments-uploader'

interface IProps {
	form: FormInstance<TWbsTaskForm>
	formValues: TWbsTaskForm
	setFilesToUpload: (x: UploadFile[]) => void
	filesToUpload: UploadFile[]
	canEdit: boolean
}

export const WbsTaskCardAttachments: FC<IProps> = ({
	form,
	formValues,
	filesToUpload,
	canEdit,
	setFilesToUpload
}) => {
	const { currentTaskId } = useWbsTaskState()
	const { data: attachments, isFetching } = useWbsTaskAttachments(currentTaskId!)

	return (
		<Flex vertical>
			<WbsTaskAttachmentsUploader
				attachments={attachments ?? []}
				form={form}
				setFilesToUpload={setFilesToUpload}
				filesToUpload={filesToUpload}
				canEdit={canEdit}
			/>
			{!!attachments?.length && (
				<WbsTaskAttachmentList
					// loading={isFetching}
					attachments={attachments}
					canEdit={canEdit}
				/>
			)}
		</Flex>
	)
}
