import { useQuery } from "@tanstack/react-query"
import { getContractorsCommission } from "services/EnvironmentService"

export const useChecklistContractor = (contractorId?: string) => {
	return useQuery({
		queryKey: ['checklistContractor', contractorId],
		queryFn: async () => {
			return await getContractorsCommission(contractorId!).then(data => data)
		},
		refetchOnWindowFocus: false
	})

	}