import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Drawer, Form, notification, Tabs, TabsProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/lib'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DATE_FORMAT } from 'shared/constants'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import History from 'UI/history'
import { wbsTaskCardApi } from '../api/wbs-task-card-api'
import { useWbsTaskCard, useWbsTaskSave, useWbsTaskUpdate } from '../api/wbs-task-card-queries'
import { WbsTaskCardAttachments } from './wbs-task-attachments'
import { WbsTaskCardHeader } from './wbs-task-card-header'
import { WbsTaskCardDetails } from './wbs-task-details'

export const WbsTaskCard = () => {
	const { showCard, currentTaskId, initForm, setInitForm, unset, closeCard } = useWbsTaskState()
	const [wbsTaskCardForm] = Form.useForm<TWbsTaskForm>()
	const formValues = Form.useWatch(values => values, wbsTaskCardForm)
	const { data, isSuccess, isFetching } = useWbsTaskCard()
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const navigate = useNavigate()
	const { id: wbsTaskId } = useParams()
	const queryClient = useQueryClient()
	const { mutate: mutateSave, isLoading: isLoadingSave } = useWbsTaskSave()
	const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useWbsTaskUpdate()
	const canEdit = user?.isAdmin ?? false
	const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([])

	useEffect(() => {
		if (data && isSuccess) {
			const init: TWbsTaskForm = {
				wbsTaskTypeId: data.wbsTaskType.id ?? undefined,
				constructionObjectId: data.constructionObject.id ?? undefined,
				workPackageId: data.workPackage.id ?? undefined,
				typeSectionId: data.typeSection.id ?? undefined,
				title: data.title ?? '',
				instruction: data.instruction ?? '',
				projectId: data.project.id ?? currentProject.id,
				contractId: data.contract?.id ?? undefined,
				estimateId: data.estimate?.id ?? undefined,
				containerId: data.container?.id ?? undefined
			}
			setInitForm(init)
			wbsTaskCardForm.setFieldsValue(init)
		}
	}, [data])

	const tabItems: TabsProps['items'] = [
		{
			key: 'details',
			label: 'Детали',
			children: (
				<WbsTaskCardDetails form={wbsTaskCardForm} formValues={formValues} canEdit={canEdit} />
			)
		},
		{
			key: 'attachments',
			label: 'Файлы',
			children: (
				<WbsTaskCardAttachments
					formValues={formValues!}
					form={wbsTaskCardForm}
					setFilesToUpload={setFilesToUpload}
					filesToUpload={filesToUpload}
					canEdit={canEdit!}
				/>
			)
		},
		{
			label: 'История',
			key: 'history',
			disabled: currentTaskId === null,
			children: <History id={currentTaskId!} target={'wbsTask'} />
		}
	]

	const onCloseHandler = () => {
		queryClient.invalidateQueries(['wbsTasks'])
		closeCard()
		wbsTaskCardForm.resetFields()
		unset()
		navigate('/wbs-tasks')
		setFilesToUpload([])
	}

	const onSave = (values: TWbsTaskForm) => {
		const dto = {
			...values,
			projectId: data?.project.id ?? currentProject.id
		}
		if (data?.id) {
			mutateUpdate(
				{ id: data?.id!, dto: dto! },
				{
					onSuccess: answer => {
						notification.success({
							message: 'Заявка успешно обновлена',
							placement: 'topLeft'
						})
						addAttachments(data?.id ?? answer)
						onCloseHandler()
					},
					onError: (error: any) => {
						notification.error({
							message: 'Произошла ошибка во время сохранения',
							description: error.response?.data?.detail,
							placement: 'topLeft'
						})
					}
				}
			)
		} else {
			mutateSave(dto!, {
				onSuccess: answer => {
					notification.success({
						message: 'Заявка успешно сохранена',
						placement: 'topLeft'
					})
					addAttachments(answer)
					onCloseHandler()
				},
				onError: (error: any) => {
					notification.error({
						message: 'Произошла ошибка во время сохранения',
						description: error.response?.data?.detail,
						placement: 'topLeft'
					})
				}
			})
		}
	}

	const { mutate: mutateAttachments, isLoading: isLoadingAttachments } = useMutation({
		mutationKey: ['wbsTask', 'attachments'],
		mutationFn: async ({
			wbsTaskId,
			fmData,
			config
		}: {
			wbsTaskId: string
			fmData: FormData
			config: any
		}) => await wbsTaskCardApi.addAttachments(wbsTaskId!, fmData, config),
		onSuccess: () => {
			queryClient.invalidateQueries(['wbsTaskCard', 'attachments'])
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const addAttachments = (wbsTaskId: string) => {
		if (filesToUpload.length > 0 && wbsTaskId) {
			const config = {
				headers: { 'content-type': 'multipart/form-data' }
			}
			const fmData = new FormData()
			filesToUpload?.map(file => fmData.append('files', file.originFileObj as RcFile))
			mutateAttachments({ wbsTaskId, fmData, config })
		}
	}

	const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
		<>
			{label}
			{required && <sup style={{ color: 'red' }}>*</sup>}
		</>
	)

	return (
		<Form
			name="wbsTaskCardForm"
			scrollToFirstError
			form={wbsTaskCardForm}
			labelAlign="left"
			labelCol={{ span: 6 }}
			colon={false}
			requiredMark={customizeRequiredMark}
			layout="inline"
			initialValues={{ statusId: WBS_TASK_STATUS.new }}
			onFinish={onSave}
		>
			<Drawer
				classNames={{
					body: 'app-entity-card__body',
					header: `app-entity-card__header indicator--${data?.wbsTaskStatus.name ?? 'New'}`
				}}
				styles={{ body: { background: '#fafafa', padding: 0 } }}
				loading={isLoadingSave || isLoadingUpdate}
				onClose={onCloseHandler}
				destroyOnClose={true}
				open={showCard || currentTaskId !== null}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					data && currentTaskId
						? `Заявка №${data.number} от ${dayjs(data.createdAt).format(DATE_FORMAT.view)}`
						: 'Новая заявка'
				}
				extra={
					<WbsTaskCardHeader
						form={wbsTaskCardForm}
						isNew={data && currentTaskId ? false : true}
						canEdit={true}
						isLoading={isLoadingSave || isLoadingUpdate}
						status={data?.wbsTaskStatus!}
						wbsTaskId={data?.id!}
					/>
				}
			>
				<Tabs items={tabItems} className="app-tabs-sticky" size="large" tabBarGutter={16} />
			</Drawer>
		</Form>
	)
}
