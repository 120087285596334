import { notification } from 'antd'
import { TAdjustmentExcel } from 'shared/schemas/adjustment-excel-schema'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type TState = {
	isFormed: boolean
	download?: TAdjustmentExcel
}

type TActions = {
	startFormed: () => void
	setDownload: (download: TAdjustmentExcel | undefined) => void
	unset: () => void
}

const init: TState = {
	isFormed: false,
	download: undefined
}

export const useAppState = create(
	persist<TState & TActions>(
		set => ({
			...init,
			startFormed: () => {
				notification.info({
					message: 'Файл формируется',
					placement: 'topLeft'
				})
				set(() => ({ isFormed: true }))
			},
			setDownload: download => {
				set(() => ({ download }))
			},
			unset: () => {
				set(() => ({ ...init }))
			}
		}),
		{
			name: 'app-state',
			storage: createJSONStorage(() => localStorage)
		}
	)
)
