import {
	BookTwoTone,
	DeleteOutlined,
	EditOutlined,
	PlusSquareTwoTone,
	SaveTwoTone,
	StopOutlined,
	TagTwoTone,
	XFilled
} from '@ant-design/icons'
import { Button, Col, Row, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useMemo } from 'react'
import {
	IWbsTaskStatusRegistry,
	IWbsTaskVisa,
	IWbsTaskVisaWithParent
} from '../model/wbs-task-status-interface'
import { useWbsTaskStatusState } from '../model/wbs-task-status-state'

export const useWbsTaskStatusColumns = ({
	onEdit,
	onCancelEditing,
	removeVisaFromStatus,
	onSave
}: {
	onEdit: (record: IWbsTaskStatusRegistry | IWbsTaskVisa) => void
	onCancelEditing: () => void
	removeVisaFromStatus: (item: IWbsTaskVisaWithParent) => void
	onSave: (record: IWbsTaskStatusRegistry | IWbsTaskVisaWithParent) => void
}) => {
	const { editing, setVisaAdd } = useWbsTaskStatusState()

	const columns = useMemo<ColumnsType<IWbsTaskStatusRegistry | IWbsTaskVisa>>(
		() => [
			{
				dataIndex: ['name'],
				title: 'Наименование',
				width: 250,
				align: 'center',
				onCell: record => ({
					title: 'Наименование',
					record,
					dataIndex: 'name'
				}),
				render: (_, record) => (
					<Row gutter={8}>
						<Col style={{ marginLeft: !('color' in record) ? '1rem' : 0 }}>
							{!('color' in record) ? (
								<TagTwoTone twoToneColor="#7A77D9" />
							) : (
								<BookTwoTone twoToneColor={record?.children?.length! ? '#1677ff' : 'lightgray'} />
							)}
						</Col>
						<Col>{!('color' in record) ? record.name : record.description}</Col>
					</Row>
				)
			},
			{
				dataIndex: ['visas', 'roles'],
				title: 'Роли',
				onCell: record => ({
					title: 'Роли',
					record,
					dataIndex: 'rolesId'
				}),
				render: (_, record) =>
					'roles' in record &&
					record?.roles?.map(r => (
						<Tag color="blue" style={{ fontSize: 14, padding: '4px 8px' }}>
							{r.name}
						</Tag>
					))
			},
			{
				dataIndex: ['color'],
				title: 'Цвет',
				width: 64,
				align: 'center',
				render: (value, record) =>
					'color' in record && <XFilled style={{ fontSize: 24, color: `#${value}` }} />,
				onCell: record => ({
					title: 'Цвет',
					record,
					dataIndex: 'color'
				})
			},
			{
				width: 200,
				align: 'center',
				render: (_, record) => (
					<Row gutter={8}>
						{editing === record.id ? (
							<>
								<Col>
									<Tooltip title="Сохранить">
										<Button
											icon={<SaveTwoTone />}
											type="text"
											onClick={e => {
												e.stopPropagation()
												onSave(record)
											}}
										/>
									</Tooltip>
								</Col>
								<Col>
									<Tooltip title="Отмена">
										<Button
											danger
											icon={<StopOutlined />}
											type="text"
											onClick={e => {
												e.stopPropagation()
												onCancelEditing()
											}}
										/>
									</Tooltip>
								</Col>
							</>
						) : (
							<>
								<Col>
									<Tooltip title="Редактировать">
										<Button
											icon={<EditOutlined />}
											type="text"
											disabled={editing !== null}
											onClick={e => {
												e.stopPropagation()
												onEdit(record)
											}}
										/>
									</Tooltip>
								</Col>
								{'color' in record && (
									<Col>
										<Tooltip title="Добавить визу">
											<Button
												icon={
													<PlusSquareTwoTone twoToneColor={editing !== null ? 'gray' : '#1677ff'} />
												}
												type="text"
												disabled={editing !== null}
												onClick={e => {
													e.stopPropagation()
													setVisaAdd(record)
												}}
											/>
										</Tooltip>
									</Col>
								)}
								{!('color' in record) && (
									<Col>
										<Tooltip title="Открепить визу">
											<Button
												danger
												icon={<DeleteOutlined />}
												type="text"
												disabled={editing !== null}
												onClick={e => {
													e.stopPropagation()
													'color' in record
														? console.log('delete', record.id)
														: removeVisaFromStatus(record)
												}}
											/>
										</Tooltip>
									</Col>
								)}
							</>
						)}
					</Row>
				)
			}
		],
		[editing]
	)
	return columns
}
