import { IBase } from 'interfaces/IBase'
import { IContainerUpdateDto } from 'interfaces/IContainer'
import { IEstimatePositionDto } from 'interfaces/IEstimatePosition'
import { IStatusRouteItem } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'
import { TEstimateType } from 'shared/schema'
import { TAdjustmentExcel } from 'shared/schemas/adjustment-excel-schema'
import {
	IAdjustmentPosition,
	IAdjustmentPositionCommentsUpdate,
	IAdjustmentPositionSduUpdate,
	IAdjustmentSection,
	IAdjustmentToggleIsNominatedSduDto
} from '..'

const url = '/v1/adjustment'
enum ROUTE {
	wbs = 'container',
	local = 'estimate'
}

export const adjustmentApi = {
	async create(containerId: string, name: string, type: TEstimateType, projectId: string) {
		return await API({
			url: `${url}`,
			method: 'post',
			data: { id: containerId, name, isContainer: type === 'wbs', projectId }
		}).then(response => response.data)
	},

	async get(containerId: string, type: TEstimateType) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}`,
			method: 'get'
		}).then(response => response.data)
	},

	async getSections(containerId: string, type: TEstimateType) {
		return await API<IAdjustmentSection[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/sections`,
			method: 'get'
		}).then(response => response.data)
	},

	async getSectionPositions(containerId: string, sectionId: string, type: TEstimateType) {
		return await API<IAdjustmentPosition[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get'
		}).then(response => response.data)
	},

	async saveStatement(data: IEstimatePositionDto, method: string) {
		return await API({
			url: `${url}/estimatePosition`,
			method: method,
			data
		}).then(response => response.data)
	},

	async saveStatementSdu(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/estimatePositionPriceCorrection`,
			method: 'patch',
			data
		}).then(response => response.data)
	},
	async saveStatementSduNominated(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/estimatePositionPriceCorrectionIsNominated`,
			method: 'patch',
			data
		}).then(response => response.data)
	},

	async saveStatementComments(data: IAdjustmentPositionCommentsUpdate) {
		return await API({
			url: `${url}/estimatePositionComments`,
			method: 'patch',
			data
		}).then(response => response.data)
	},

	async copyEstimatePosition(data: string[]) {
		return await API<string[]>({
			url: `${url}/estimatePosition/copy`,
			method: 'post',
			data
		}).then(response => response.data)
	},

	async deleteEstimatePosition(data: string[]) {
		return await API({
			url: `${url}/estimatePosition`,
			method: 'delete',
			data
		}).then(response => response.data)
	},

	async deleteForClosedEstimatePosition(id: string) {
		return await API({
			url: `${url}/${id}/closed`,
			method: 'patch'
		}).then(response => response.data)
	},

	async toggleIsNominatedSdu(data: IAdjustmentToggleIsNominatedSduDto) {
		return await API({
			url: `${url}/estimatePositionMaterialIsNominated`,
			method: 'patch',
			data
		}).then(response => response.data)
	},

	async getContainerVisas(containerId: string, type: TEstimateType) {
		return await API<IStatusRouteItem[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/visas`,
			method: 'get'
		}).then(response => response.data)
	},

	async setVisaApprove(containerId: string, type: TEstimateType, visaId?: string) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/approve`,
			method: 'post',
			params: { visaId }
		}).then(response => response.data)
	},

	async unsetVisaApprove(containerId: string, type: TEstimateType, visaId: string) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/unapprove`,
			method: 'post',
			params: { visaId }
		}).then(response => response.data)
	},

	async getCanVisaApprove(containerId: string, type: TEstimateType) {
		return await API<string[]>({
			url: `${url}/${ROUTE[type]}/${containerId}/approvalAvailable`,
			method: 'get'
		}).then(response => response.data)
	},

	async createCorrection(containerId: string, name: string, type: TEstimateType) {
		return await API({
			url: `${url}/${ROUTE[type]}/${containerId}/correction`,
			method: 'post',
			data: { name }
		}).then(response => response.status)
	},

	async savePriceSecond(data: IAdjustmentPositionSduUpdate) {
		return await API({
			url: `${url}/EstimatePositionPriceOuter`,
			method: 'patch',
			data
		}).then(response => response.data)
	},
	async exportToExcel(adjustmentId: string, withPrice?: boolean) {
		return await API({
			url: `/v1/export/exportAdjustmentToExcel`,
			method: 'get',
			params: { adjustmentId, withPrice },
			responseType: 'blob'
		}).then(async response => response)
	},
	async updContainer(containerId: string, dto: IContainerUpdateDto) {
		return await API({
			url: `${url}/${containerId}`,
			method: 'patch',
			data: dto
		}).then(async response => response.data)
	},
	async exportAdjustmentToExcel({
		adjustmentId,
		withPrice
	}: {
		adjustmentId?: string
		withPrice: boolean
	}) {
		if (adjustmentId) {
			return await API<TAdjustmentExcel>({
				url: '/v1/export/exportAdjustmentToExcel',
				method: 'get',
				params: { adjustmentId, withPrice }
			}).then(async response => response.data)
		}
	},

	async getPotentialRedactors() {
		return await API<IBase[]>({
			url: `${url}/potentialRedactors`,
			method: 'get'
		}).then(response => response.data)
	},
}
