import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

import { ReactComponent as Bad } from 'assets/icons/bad.svg'
import { ReactComponent as Box } from 'assets/icons/box.svg'
import { ReactComponent as BoxOpen } from 'assets/icons/boxOpen.svg'
import { ReactComponent as Good } from 'assets/icons/good.svg'
import { ReactComponent as Analytics } from 'assets/icons/icon_analytics.svg'
import { ReactComponent as Checklist } from 'assets/icons/icon_checklist.svg'
import { ReactComponent as Documents } from 'assets/icons/icon_documents.svg'
import { ReactComponent as Guide } from 'assets/icons/icon_guide.svg'
import { ReactComponent as Remark } from 'assets/icons/icon_remark.svg'
import { ReactComponent as Settings } from 'assets/icons/icon_settings.svg'
import { ReactComponent as Unmodel } from 'assets/icons/icon_unmodel.svg'
import { ReactComponent as Maintance } from 'assets/icons/maintance.svg'
import { ReactComponent as Sigma } from 'assets/icons/sigma.svg'
import { ReactComponent as Tasks } from 'assets/icons/tasks.svg'

const settings = (props: Partial<CustomIconComponentProps>) => {
	return {
		...props,
		style: {
			fontSize: props.style?.fontSize ? props.style?.fontSize : 24,
			color: props.style?.color ? props.style?.color : 'rgb(85, 93, 119)'
		}
	}
}

export const ATIconCheckList = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Checklist} {...settings(props)} />
}

export const ATIconDocuments = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Documents} {...settings(props)} />
}

export const ATIconAnalytics = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Analytics} {...settings(props)} />
}

export const ATIconUnmodel = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Unmodel} {...settings(props)} />
}

export const ATIconSettings = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Settings} {...settings(props)} />
}

export const ATIconSigma = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Sigma} {...settings(props)} />
}
export const ATIconRemark = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Remark} {...settings(props)} />
}
export const ATIconGuide = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Guide} {...settings(props)} />
}
export const ATResultMaintance = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Maintance} {...settings(props)} />
}
export const ATGood = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Good} {...settings(props)} />
}
export const ATBad = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Bad} {...settings(props)} />
}
export const ATBox = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Box} {...settings(props)} />
}
export const ATBoxOpen = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={BoxOpen} {...settings(props)} />
}
export const ATTasks = (props: Partial<CustomIconComponentProps>) => {
	return <Icon component={Tasks} {...settings(props)} />
}
