import { Image } from 'antd'
import notFoundImage from 'assets/not-found.png'
import { IIssueCardAttachment } from 'entities/issues/model/issue-card-schema'
import { FC, useEffect, useState } from 'react'
import { getIssueAttachment } from 'services/IssuesService'

interface IProps {
	initialSlide: string | null
	attachments?: IIssueCardAttachment[]
	show: boolean
	onClose: () => void
}

interface IGallery {
	id: string
	title: string
	src: string | null
}

export const ImageCarousel: FC<IProps> = props => {
	const { initialSlide, show, attachments, onClose } = props
	const [gallery, setGallery] = useState<IGallery[]>(
		attachments?.map(a => ({
			id: a.id,
			title: a.name,
			src: null
		})) ?? []
	)
	const [currentSlide, setCurrentSlide] = useState<number | undefined>(undefined)

	useEffect(() => {
		const init = async () => {
			setCurrentSlide(gallery.findIndex(g => g.id === initialSlide))
			const image = await getImage(initialSlide!)
			const newGallery = attachments?.map(a => {
				if (a.id === initialSlide) {
					return {
						id: a.id,
						title: a.name,
						src: image
					}
				} else {
					return {
						id: a.id,
						title: a.name,
						src: null
					}
				}
			})
			setGallery(newGallery ?? [])
		}
		if (show) init()
	}, [show])

	useEffect(() => {
		const update = async () => {
			if (gallery[currentSlide!]?.src === null) {
				const item = gallery[currentSlide!].id
				const image = await getImage(item)
				const newGallery: IGallery[] = gallery?.map(g => {
					if (g.id === item) {
						return {
							id: g.id,
							title: g.title,
							src: image
						}
					} else {
						return g
					}
				})
				setGallery(newGallery ?? [])
			}
		}
		update()
	}, [currentSlide])

	const getImage = async (id: string) => {
		const blobToBase64 = async (id: string) => {
			const blob = await getIssueAttachment(id)
				.then(data => data)
				.catch(() => null)

			if (blob && blob !== null) {
				return await new Promise<string>((resolve, reject) => {
					const reader = new FileReader()
					reader.readAsDataURL(blob)
					reader.onload = () => resolve(reader.result!.toString())
					reader.onerror = error => reject(error)
				})
			}
		}

		return await blobToBase64(id).then(data => {
			return data!
		})
	}

	const onCloseGallery = () => {
		setCurrentSlide(undefined)
		onClose()
	}
	return (
		<Image.PreviewGroup
			items={gallery.map(g => g.src ?? notFoundImage)}
			preview={{
				current: currentSlide,
				visible: show,
				onChange: (current: number, prevCurrent: number) => setCurrentSlide(current),
				onVisibleChange: onCloseGallery
			}}
		>
			<Image width={200} style={{ display: 'none' }} src={notFoundImage} />
		</Image.PreviewGroup>
	)
}
