import { useQueryClient } from '@tanstack/react-query'
import { Form, Table } from 'antd'
import { Color } from 'antd/es/color-picker'
import { WbsTaskStatusApi } from '../api/wbs-task-status-api'
import {
	IWbsTaskStatusRegistry,
	IWbsTaskVisa,
	IWbsTaskVisaWithParent
} from '../model/wbs-task-status-interface'
import { useWbsTaskStatuses } from '../model/wbs-task-status-queries'
import { useWbsTaskStatusState } from '../model/wbs-task-status-state'
import { WbsTaskStatusCell } from './wbs-task-status-cell'
import { useWbsTaskStatusColumns } from './wbs-task-status-columns'
import { WbsTaskVisaAdd } from './wbs-task-status-modal'

export const WbsTaskStatusRegistry = () => {
	const { editing: editingRegistry, setEditing } = useWbsTaskStatusState()
	const { data, isFetching } = useWbsTaskStatuses()
	const [wbsTaskStatusGuideForm] = Form.useForm()
	const queryClient = useQueryClient()

	const onEdit = (record: IWbsTaskStatusRegistry | IWbsTaskVisa) => {
		if ('roles' in record) {
			wbsTaskStatusGuideForm.setFieldsValue({
				name: record.name,
				rolesId: record.roles?.flatMap(r => r.id),
				color: 'color' in record ? '#' + record.color : undefined
			})
		} else {
			wbsTaskStatusGuideForm.setFieldsValue({ name: record.description })
		}
		setEditing(record?.id!)
	}

	const removeVisaFromStatus = async (item: IWbsTaskVisaWithParent) => {
		const parent = data?.find(d => d.id === item?.parentId!)
		if (parent) {
			const dto = {
				name: parent.name!,
				description: parent.description ?? null,
				color: parent.color ?? null,
				order: parent.order,
				visasIds: (parent.children.filter(v => v.id !== item.id).flatMap(v => v.id) as any) ?? []
			}
			await WbsTaskStatusApi.updateStatus(item.parentId!, dto).then(() => {
				queryClient.invalidateQueries({
					queryKey: ['wbsTaskStatusGuide']
				})
				queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
			})
		}
	}

	const onCancelEditing = () => {
		wbsTaskStatusGuideForm.resetFields()
		setEditing(null)
	}

	const onSave = async (record: IWbsTaskStatusRegistry | IWbsTaskVisaWithParent) => {
		const validate = await wbsTaskStatusGuideForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (validate) {
			const formData = wbsTaskStatusGuideForm.getFieldsValue()
			if ('roles' in record) {
				await WbsTaskStatusApi.updateVisa(record.id!, formData).then(() => {
					queryClient.invalidateQueries({
						queryKey: ['wbsTaskStatusGuide']
					})
					queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
				})
			} else {
				const dto = {
					name: record.name!,
					description: formData.name!,
					color: formData.color ? (formData.color as Color).toHex()! : record.color,
					order: record.order!,
					visasIds: record.children.flatMap(v => v.id) as any
				}
				await WbsTaskStatusApi.updateStatus(record.id!, dto).then(() => {
					queryClient.invalidateQueries({
						queryKey: ['wbsTaskStatusGuide']
					})
					queryClient.invalidateQueries({ queryKey: ['wbsTaskVisas'] })
				})
			}
			onCancelEditing()
		}
	}

	const columns = useWbsTaskStatusColumns({ onEdit, onCancelEditing, removeVisaFromStatus, onSave })

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<WbsTaskVisaAdd />
			<Form form={wbsTaskStatusGuideForm} component={false}>
				<Table
					components={{
						body: {
							cell: WbsTaskStatusCell
						}
					}}
					loading={isFetching}
					className="app-wbs-table app-wbs-table--on-top"
					sticky={{ offsetHeader: 0 }}
					bordered
					rowKey={row => row?.id!}
					dataSource={data as any}
					columns={columns}
					pagination={false}
					size="small"
					onRow={row => ({
						style: { cursor: 'color' in row ? 'pointer' : 'default' }
					})}
					expandable={{
						showExpandColumn: false,
						columnWidth: 64,
						expandRowByClick: editingRegistry === null,
						rowExpandable: (row: any) => 'color' in row && !!row?.children?.length!
					}}
				/>
			</Form>
		</div>
	)
}
