import { Row } from '@tanstack/table-core'
import { Row as AntRow, Col } from 'antd'
import { memo, useCallback } from 'react'
import { findNestedObj } from 'shared/helpers'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { useShallow } from 'zustand/react/shallow'

import modal from 'antd/es/modal'
import { useAppSelector } from 'hooks/appReduxHook'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { EstimatePositionClosed } from 'widgets/estimate/ui/EstimatePositionClosed'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { AdjustmentPositionChat } from './AdjustmentPositionChat'
import { AdjustmentPositionCopy } from './AdjustmentPositionCopy'
import { AdjustmentPositionDelete } from './AdjustmentPositionDelete'
import { AdjustmentPositionMove } from './AdjustmentPositionMove'

interface IProps {
	row: IAdjustmentPosition
	callBack?: () => void
	mainRow?: Row<IAdjustmentPosition>
}

const ellipsisCell = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap'
}
export const AdjustmentPositionTitle = memo(function (params: IProps) {
	const { row, callBack, mainRow } = params
	const parent = mainRow?.getParentRow()?.original
	const checkPermissions = useCheckPermissions()
	const { setToEdit } = useEstimatePositionState()
	const { container, sections } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			sections: state.sections
		}))
	)
	const { user: currentUser } = useAppSelector(state => state.environment)

	const currentSection = useCallback(() => {
		return (
			(sections &&
				findNestedObj<IAdjustmentSection>(sections, 'id', row.containerSectionId)?.children
					?.length) ??
			1
		)
	}, [row])

	const canEdit =
		checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
		(currentUser?.isAdmin ||
			container?.status.name === CONTAINER_STATUS.new ||
			container?.status.name === CONTAINER_STATUS.preparation)

	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false

	return (
		<AntRow style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
			<Col
				flex={1}
				onClick={() => (callBack ? callBack() : null)}
				onDoubleClick={() =>
					row.isDeleted
						? modal.error({
								title: 'Позиция сметы удалена',
								content: 'С этой позицией сметы работать нельзя'
						  })
						: container &&
						  setToEdit(
								row.id,
								{ id: row.containerSectionId, name: row.workName },
								container?.id,
								true
						  )
				}
			>
				<div style={ellipsisCell}>{`${row.order} - ${row.workName}`}</div>
			</Col>
			<Col>
				<AntRow gutter={8} align="middle" wrap={false}>
					{!container?.ready && !row.isDeleted && (
						<Col>
							<AntRow gutter={8} align="middle" wrap={false} className="app-wbs-move-controls">
								{currentSection() > 1 &&
									((typeof container?.isLocalEstimate === 'undefined' && !row.isClosed) ||
										container?.isLocalEstimate === false) &&
									checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
									(currentUser?.isAdmin ||
										container?.status.name === CONTAINER_STATUS.new ||
										container?.status.name === CONTAINER_STATUS.preparation) &&
									(container?.isLocalEstimate === false ||
										(typeof container?.isLocalEstimate === 'undefined' &&
											isAuthor &&
											(currentUser?.isAdmin ||
												container?.status.name === CONTAINER_STATUS.new ||
												container?.status.name === CONTAINER_STATUS.preparation))) && (
										<Col>
											<AdjustmentPositionMove
												sectionId={row.containerSectionId}
												rowId={row.id}
												row={mainRow}
												isAuthor={isAuthor}
											/>
										</Col>
									)}

								{canEdit &&
									isAuthor &&
									'hasTechnologies' in parent! &&
									parent?.hasTechnologies === true && (
										<Col>
											<AdjustmentPositionCopy id={row.id} sectionId={row.containerSectionId} />
										</Col>
									)}

								{(container?.status.name === CONTAINER_STATUS.new ||
									container?.status.name === CONTAINER_STATUS.preparation ||
									currentUser?.isAdmin) &&
									((currentUser?.isAdmin &&
										row.isUnrelated === false &&
										container?.isLocalEstimate === false) ||
									(row.isUnrelated === false && container?.isLocalEstimate === false)
										? canEdit &&
										  isAuthor &&
										  (container?.status.name === CONTAINER_STATUS.new ||
												container?.status.name === CONTAINER_STATUS.preparation ||
												currentUser?.isAdmin) &&
										  container?.isLocalEstimate === false && (
												<Col>
													<EstimatePositionClosed
														id={row.id}
														sectionId={row.containerSectionId}
														type={'adjustment'}
														isClosed={row?.isClosed}
													/>
												</Col>
										  )
										: canEdit &&
										  ((typeof container?.isLocalEstimate === 'undefined' && !row.isClosed) ||
												container?.isLocalEstimate === false) &&
										  isAuthor &&
										  ((row.isUnrelated && container?.isLocalEstimate === false) ||
												typeof container?.isLocalEstimate === 'undefined') && (
												<Col>
													<AdjustmentPositionDelete
														id={row.id}
														sectionId={row.containerSectionId}
														firstVersion={
															row.isUnrelated === false &&
															typeof container?.isLocalEstimate === 'undefined'
																? true
																: false
														}
													/>
												</Col>
										  ))}
							</AntRow>
						</Col>
					)}
					{!row.isDeleted && (
						<Col>
							<AdjustmentPositionChat row={row} />
						</Col>
					)}
				</AntRow>
			</Col>
		</AntRow>
	)
})
