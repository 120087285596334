import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, message, Modal, Row, UploadFile } from 'antd'
import { RcFile } from 'antd/es/upload'
import { Upload, UploadProps } from 'antd/lib'
import { AxiosError } from 'axios'
import { ICurrentChecklist } from 'interfaces/IChecklist'
import { FC, useState } from 'react'
import { checkListSigning } from 'services/ChecklistsService'
import LoaderSpinner from 'UI/loaderSpinner'

interface ISignModalProps {
	currentChecklist: ICurrentChecklist
	show: boolean
	onClose: () => void
	onConfirm?: () => void
}

interface ISignData {
	status: string
	message: string
}

export interface ISignDto {
	extension: string
	mimoType: string
	base64File: string
}

const SignModal: FC<ISignModalProps> = ({ show, currentChecklist, onClose, onConfirm }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [signFile, setSignFile] = useState<UploadFile | null>(null)
	const [base64File, setBase64File] = useState<string | null>(null)
	const checkListActFile = currentChecklist!?.attachments?.find(chl => chl.typeId === 5)

	const onSignConfirm = async () => {
		setIsLoading(true)
		const extension = signFile!?.name.substring(
			signFile!?.name.lastIndexOf('.') + 1,
			signFile!?.name.length
		)
		const signDto: ISignDto = {
			base64File: base64File!,
			extension: '.' + extension,
			mimoType: signFile!?.type!?.length === 0 ? '' : signFile!?.type!
		}
		await checkListSigning(currentChecklist?.id!, checkListActFile?.id!, signDto)
			.then(response => {
				onSignModalClose()
				message.success(response!?.data!?.message! ?? 'Акт передачи успешно подписан')
				onConfirm!()
			})
			.catch((error: AxiosError) => {
				const response: ISignData | null = (error.response!?.data as ISignData) ?? null
				message.error(response!.message ?? 'Произошла ошибка')
			})
			.finally(() => setTimeout(() => setIsLoading(false), 500))
	}

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const onSignModalClose = () => {
		setSignFile(null)
		setBase64File(null)
		onClose()
	}

	const props: UploadProps = {
		showUploadList: false,
		maxCount: 1,
		onRemove: () => {
			setSignFile(null)
		},
		beforeUpload: file => {
			const isSign = file.type === 'application/pdf'

			if (isSign) {
				setSignFile(file)
				getBase64(file).then(data => setBase64File(data.split(',')[1]))
			} else {
				message.error(`${file.name} не является подписью`)
			}
			return isSign || Upload.LIST_IGNORE
		}
	}

	return (
		<Modal
			open={show}
			onCancel={onSignModalClose}
			onOk={onSignConfirm}
			okButtonProps={{ disabled: signFile === null || isLoading }}
			cancelButtonProps={{ disabled: isLoading }}
			title="Подписание акта передачи"
			zIndex={9999999999}
		>
			{isLoading && <LoaderSpinner />}
			<div style={{ textAlign: 'left', width: '100%', marginTop: '.5rem' }}>
				<i>*Ручное подписание</i>
			</div>
			<br />
			{signFile === null ? (
				<Upload {...props}>
					<Button type="primary" icon={<UploadOutlined />}>
						Выберите файл
					</Button>
				</Upload>
			) : (
				<Row gutter={8} style={{ alignItems: 'center' }}>
					<Col flex={1}>Подпись загружена</Col>
					<Col>
						<Button danger icon={<DeleteOutlined />} onClick={() => setSignFile(null)} />
					</Col>
				</Row>
			)}
			<div style={{ textAlign: 'right', width: '100%', marginTop: '.5rem' }}>
				<i>
					*Допустимые форматы файлов: <b>{'.pdf'}</b>
				</i>
			</div>
		</Modal>
	)
}

export default SignModal
