import { Card, Col, Image, Row } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { IIssueCard } from 'entities/issues'
import { ICommentsData, IIssueCardAttachment } from 'entities/issues/model/issue-card-schema'
import { ICommentFile } from 'pages/issuesPage/issueDrawer/IssueComments'
import { IIssueFileList } from 'pages/issuesPage/issueDrawer/IssueTabs'
import { FC, useEffect, useState } from 'react'
import { getIssueAttachment } from 'services/IssuesService'
import { DATE_FORMAT } from 'shared/constants'

interface IProps {
	dataSource: IIssueCard['comments']
	attachments: IIssueCardAttachment[]
}
const commentsCols: ColumnsType<ICommentsData> = [
	{
		key: 'author',
		title: 'Автор',
		width: 160,
		dataIndex: 'author'
	},
	{
		key: 'comment',
		title: 'Комментарий',
		dataIndex: 'comment'
	},
	{
		key: 'created',
		title: 'Дата',
		width: 140,
		dataIndex: 'created',
		render: (_, record) =>
			dayjs(record.created).isValid()
				? dayjs(record.created).format(DATE_FORMAT.viewWithHours)
				: '-'
	}
]

export const IssueComments: FC<IProps> = ({ dataSource, attachments }) => {
	const [commentsAttachments, setCommentsAttachments] = useState<ICommentFile[]>([])
	const commentsData: ICommentsData[] = dataSource!?.map(comment => ({
		author: comment.author.name,
		comment: comment.comment,
		created: comment!.created,
		attachments: attachments!?.filter(att => comment.attacmentId.some(f => f === att.id)) || []
	}))

	const blobToBase64 = async (id: string) => {
		const blob: Blob = await getIssueAttachment(id).then(data => data)

		if (blob) {
			return await new Promise<string>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result!.toString())
				reader.onerror = error => reject(error)
			})
		}
	}

	useEffect(() => {
		const getBlobs = async () => {
			const blobList: IIssueFileList[] = await Promise.all(
				(attachments as any[])!?.map(async (item): Promise<ICommentFile> => {
					const blob = await blobToBase64(item.id).then(data => {
						return data!
					})
					return { ...item, blob: blob! }
				})
			)
			setCommentsAttachments(blobList)
		}
		if (attachments!?.length! > 0) getBlobs()
	}, [attachments])
	return (
		<Card
			title="Комментарии"
			className="app-work-card"
			classNames={{ body: 'app-work-card__body' }}
		>
			<Table
				rowKey={(record, index) => index!}
				pagination={false}
				columns={commentsCols}
				dataSource={commentsData ?? []}
				expandable={{
					expandedRowRender: record => (
						<Row gutter={[8, 8]}>
							{record?.attachments!?.map(att => (
								<Col>
									<Image
										width={100}
										src={commentsAttachments?.find(file => file.id === att.id)?.blob}
									/>
								</Col>
							))}
						</Row>
					),
					rowExpandable: record => !!record.attachments.length,
					expandedRowClassName: record => 'at-expandable'
				}}
			/>
		</Card>
	)
}
