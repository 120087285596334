import { Form, Select, Skeleton } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { AxiosError } from 'axios'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC, useEffect } from 'react'

interface IProps {
	canEdit: boolean
	disabled?: boolean
	options?: DefaultOptionType[]
	dataSourceValue?: string | null
	name: string
	label: string
	rules?: Rule[]
	isLoading: boolean
	error?: any
	form: FormInstance<TIssueDetailForm>
}

export const IssueFormSelectorItem: FC<IProps> = props => {
	const {
		canEdit,
		disabled,
		options,
		name,
		label,
		dataSourceValue,
		rules,
		isLoading,
		error,
		form
	} = props

	useEffect(() => {
		if (options?.length === 1) form.setFieldValue(name, options[0].id)
	}, [options])

	return canEdit ? (
		<Form.Item label={label} name={name} rules={rules}>
			{isLoading ? (
				<Skeleton.Button block active />
			) : error instanceof AxiosError ? (
				<span className="app-form-text danger">
					{error.response?.status === 403
						? 'У вас недостаточно прав для редактирования этого поля'
						: 'Ошибка получения данных'}
				</span>
			) : (
				<Select
					showSearch={true}
					optionFilterProp={'children'}
					filterOption={(input, option) =>
						(option?.name ?? '').toLowerCase().includes(input.toLowerCase())
					}
					filterSort={(optionA, optionB) =>
						name !== 'workPackageId' &&
						(optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
					}
					loading={isLoading}
					disabled={disabled || options?.length === 0}
					options={options}
					fieldNames={{ label: 'name', value: 'id' }}
					allowClear
					variant="borderless"
				/>
			)}
		</Form.Item>
	) : (
		<Form.Item label={label}>
			<span className="app-form-text">{dataSourceValue ?? '-'}</span>
		</Form.Item>
	)
}
