import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import { MaintanceOnProgress } from 'components/MaintanceOnProgress'
import { useAppSelector } from 'hooks/appReduxHook'
import AcceptancePage from 'pages/acceptancePage'
import AdjustmentPage from 'pages/adjustmentPage'
import OAuth3 from 'pages/adminPage/OAuth3'
import AnalyticsPage from 'pages/analyticsPage'
import EstimatesPage from 'pages/estimatesPage'
import Forbidden from 'pages/forbidden'
import GuidesPage from 'pages/guidesPage'
import HelpPage from 'pages/helpPage'
import { ReleaseNotesPage } from 'pages/releaseNotesPage'
import WbsTasksPage from 'pages/wbs-tasks-page'
import { FC, useEffect } from 'react'
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import useMaintanceState from 'store/maintanceState'
import { EstimateContainer } from 'widgets/estimate'
import AdminPage from '../../pages/adminPage'
import ChecklistsPage from '../../pages/checklistsPage'
import HomePage from '../../pages/homePage'
import IssuesPage from '../../pages/issuesPage'
import NotFound from '../../pages/notFound'
import OrdinancePage from '../../pages/ordinancePage'

interface IRoutePermissions {
	issues: boolean
	ordinance: boolean
	fines: boolean
	checklist: boolean
	admin: boolean
	AsmView: boolean
}

const DashboardBody: FC = () => {
	const checkPermissions = useCheckPermissions()
	const pageTitle = useAppSelector(state => state.root.pageTitle)
	const location = useLocation()
	const navigate = useNavigate()
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const { isMaintance } = useMaintanceState()

	const routePermissions: IRoutePermissions = {
		issues: checkPermissions([PERMISSIONS.IssueView]),
		ordinance: checkPermissions([PERMISSIONS.OrdinanceView]),
		fines: checkPermissions([PERMISSIONS.OrdinanceFine]),
		checklist: checkPermissions([
			PERMISSIONS.ChecklistsUksNdc,
			PERMISSIONS.ChecklistsUkKc,
			PERMISSIONS.ChecklistsTemplatesUksNdc,
			PERMISSIONS.ChecklistsTemplatesUkKc,
			PERMISSIONS.ChecklistPlanedDate
		]),
		admin: currentUser?.isAdmin!,
		AsmView:
			(currentUser?.isAdmin ||
				checkPermissions([
					PERMISSIONS.WbsView,
					PERMISSIONS.WbsSdu,
					PERMISSIONS.WbsDp,
					PERMISSIONS.WbsDzMtr,
					PERMISSIONS.WbsDzSmr,
					PERMISSIONS.WbsCreator,
					PERMISSIONS.WbsNoteContractor,
					PERMISSIONS.WbsChangeReady
				])) ??
			false
	}
	useEffect(() => {
		if (location.pathname === '/403' && PermissionsService(location.pathname) === false) {
			// было location.pathname !== '/403' - ???
			navigate('/403')
		}
	}, [location, permissions])

	useEffect(() => {
		if (isMaintance && location.pathname !== '/maintance') {
			navigate('/maintance')
		}
	}, [])

	const PermissionsService = (pathname: string) => {
		switch (true) {
			case pathname === '/issues':
				return routePermissions.issues

			case pathname === '/ordinance':
				return routePermissions.ordinance

			case pathname === '/fines':
				return routePermissions.fines

			case pathname === '/checklist':
				return routePermissions.checklist

			case pathname === '/control':
				return routePermissions.issues || routePermissions.ordinance

			case pathname === '/control/issues':
				return routePermissions.issues

			case pathname === '/control/ordinance':
				return routePermissions.ordinance

			case pathname === '/admin':
			case pathname === '/admin/forge':
			case pathname === '/oauth3':
			case pathname === '/guides':
				return currentUser?.isAdmin

			case pathname.includes('/unmodel'):
				return routePermissions.AsmView

			case pathname.includes('/wbs-tasks'):
				return routePermissions.AsmView

			case pathname === '/':
			case pathname === '/help':
			case pathname === '/analytics':
			case pathname === '/releases':
			case pathname === '/maintance':
				return true

			default:
				return false
		}
	}

	const checkAcceptance = () => {
		switch (true) {
			case routePermissions.issues:
				return 'issues'
			case routePermissions.ordinance:
				return 'ordinance'
			case routePermissions.fines:
				return 'fines'
			default:
				return 'issues'
		}
	}

	const bcItems = [
		{
			title: (
				<NavLink to="/">
					<HomeOutlined />
				</NavLink>
			)
		},
		{
			title: pageTitle.mainTitle && <b>{pageTitle.mainTitle}</b>
		}
	]

	if (pageTitle.subTitle) {
		bcItems.push({
			title: pageTitle.subTitle && <b>{pageTitle.subTitle}</b>
		})
	}

	return (
		<div className="dashboard__body">
			{location.pathname !== '/' && (
				<Breadcrumb className="dashboard__breadcrumbs" separator="|" items={bcItems}></Breadcrumb>
			)}
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/admin/*" element={<AdminPage />} />
				<Route path="/unmodel" element={<EstimatesPage init={'unmodel'} />} />
				<Route path="/unmodel/:id" element={<EstimateContainer type="wbs" />} />
				<Route path="/unmodel/adjustment/:id" element={<AdjustmentPage type="wbs" />} />
				<Route path="/local" element={<EstimatesPage init={'local'} />} />
				<Route path="/local/:id" element={<EstimateContainer type="local" />} />
				<Route path="/local/adjustment/:id" element={<AdjustmentPage type="local" />} />
				<Route path="/wbs-tasks" element={<WbsTasksPage />} />
				<Route path="/wbs-tasks/:id" element={<WbsTasksPage />} />
				<Route path="/issues" element={<IssuesPage />} />
				<Route path="/ordinance" element={<OrdinancePage />} />
				<Route path="/ordinance/:id" element={<OrdinancePage />} />
				<Route path="/checklist" element={<ChecklistsPage />} />
				<Route path="/checklist/:id" element={<ChecklistsPage />} />
				<Route path="/checklist/ndc/:id" element={<ChecklistsPage />} />
				<Route path="/guides" element={<GuidesPage />} />
				<Route path="/analytics" element={<AnalyticsPage />} />
				<Route path="/oauth3" element={<OAuth3 />} />
				<Route path="/403" element={<Forbidden />} />
				<Route path="/control" element={<AcceptancePage tab={checkAcceptance()} />}></Route>
				<Route path="/control/issues" element={<AcceptancePage tab="issues" />}></Route>
				<Route path="/control/issues/:id" element={<AcceptancePage tab="issues" />}></Route>
				<Route path="/control/ordinance" element={<AcceptancePage tab="ordinance" />}></Route>
				<Route path="/control/ordinance/:id" element={<AcceptancePage tab="ordinance" />}></Route>
				<Route path="/control/fines" element={<AcceptancePage tab="fines" />}></Route>
				<Route path="/control/fines/:id" element={<AcceptancePage tab="fines" />}></Route>
				<Route path="/help" element={<HelpPage />}></Route>
				<Route path="/releases" element={<ReleaseNotesPage />}></Route>
				<Route path="/maintance" element={<MaintanceOnProgress />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	)
}

export default DashboardBody
