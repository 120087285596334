import { IPagination } from 'interfaces/IPagination'
import { API } from 'services/ApiService'
import {
	IFilterListItem,
	INotification,
	INotificationsFilters
} from '../model/wbs-notifications-interface'

export const NotificationsApi = {
	async get({
		limit = 50,
		offset = 0,
		desc = false,
		filters
	}: {
		limit?: number
		offset: number
		desc?: boolean
		filters: INotificationsFilters
	}) {
		return await API<IPagination<INotification[]>>({
			url: 'v1/notification/notificationForBell',
			method: 'get',
			params: { offset, limit, desc, ...filters }
		}).then(async response => response.data)
	},

	async getNotificationTypesList(notificationObjects?: number[]) {
		return await API<IFilterListItem[]>({
			url: 'v1/notification/notificationTypes',
			method: 'get',
			params: {notificationObjects}
		}).then(async response => response.data)
	},

	async getWbsList() {
		return await API<IFilterListItem[]>({
			url: 'v1/notification/notificationVORByAuthor',
			method: 'get'
		}).then(async response => response.data)
	}
}
