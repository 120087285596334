import { Table } from 'antd'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PAGE_LIMIT } from 'shared/constants'
import { useWbsTaskRegistry } from '../api/wbs-task-registry-queries'
import { useWbsRegistryColumns } from './wbs-task-registry-columns'

export const WbsTaskRegistry = () => {
	const columns = useWbsRegistryColumns()
	const navigate = useNavigate()
	const { id: wbsTaskId } = useParams()
	const { totalItems, currentPage, onPagination, setTask, setSortState } = useWbsTaskState()
	const { data, isFetching } = useWbsTaskRegistry()

	useEffect(() => {
		if (wbsTaskId) {
			setTask(wbsTaskId)
		}
	}, [wbsTaskId])

	return (
		<Table
			size="small"
			loading={isFetching}
			scroll={{
				x: 2000,
				y: '72vh'
			}}
			showHeader={!!data?.length}
			sticky={{ offsetHeader: 0 }}
			rowKey="id"
			dataSource={data}
			columns={columns}
			onRow={record => ({
				onDoubleClick: () => {
					if (record?.id !== null) {
						setTask(record?.id!)
						navigate(`/wbs-tasks/${record?.id}`)
					}
				}
			})}
			pagination={{
				hideOnSinglePage: true,
				total: totalItems,
				pageSize: PAGE_LIMIT,
				showSizeChanger: false,
				onChange: onPagination,
				current: currentPage
			}}
			onChange={(pagination, filters, FilterValue) => {
				const columnName: any = {
					number: 2,
					wbsTaskStatus: 4,
					wbsTaskType: 0,
					createdAt: 6
				}

				const columnKey = (FilterValue as any).columnKey
				const order = sortVariation((FilterValue as any).order)
				const sortOrder =
					columnName.hasOwnProperty(columnKey) && order
						? columnName[columnKey] + (order === 'ASC' ? 0 : 1)
						: undefined
				setSortState(sortOrder)
			}}
		/>
	)
}

const sortVariation = (order?: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}
