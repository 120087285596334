import { FolderFilled, FolderOpenFilled, MinusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Modal, Table, message } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { IContainer } from 'interfaces/IContainer'
import { useWbsPotentialRedactors } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'
import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { updContainer } from 'services/UmdService'
import { PAGE_LIMIT } from 'shared/constants'
import { adjustmentApi } from 'widgets/adjustment'
import { useAdjustmentPotentialRedactors } from 'widgets/adjustment/model/adjustment-query'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { WbsRegistryCell, useWbsRegistry, useWbsRegistryState } from '..'
import { useConstructionObjects } from '../model/wbs-queries'
import { WbsRegistryColumns } from './WbsRegistryColumns'

interface IProps {}

export const WbsRegistry: FC<IProps> = props => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const location = useLocation()
	const { setContainer } = useEstimateState()
	const { setContainer: setAdjustmentContainer } = useAdjustmentState()
	const { data, isFetching } = useWbsRegistry()
	const { data: potentialRedactors } = useWbsPotentialRedactors()
	const { data: adjustmentPotentialRedactors } = useAdjustmentPotentialRedactors()
	const { data: constructionObjects } = useConstructionObjects()
	const {
		currentPage,
		editableRow,
		totalItems,
		setSortState,
		onPagination,
		setEditableRow,
		recordSave
	} = useWbsRegistryState()
	const [wbsRegistryForm] = Form.useForm()

	useEffect(() => {
		if (editableRow) {
			wbsRegistryForm.setFieldsValue({
				name: editableRow.name,
				redactors: editableRow.redactors?.map(r => r.id),
				constructionObjectId: editableRow.constructionObject?.id
			})
		} else {
			wbsRegistryForm.resetFields()
		}
	}, [editableRow])

	const onSave = async (record: IContainer) => {
		const dto = wbsRegistryForm.getFieldsValue()
		await (record.adjustmentId
			? adjustmentApi.updContainer(record.id, {
					...dto,
					redactors: wbsRegistryForm
						.getFieldValue('redactors')
						?.filter((item: any) =>
							adjustmentPotentialRedactors?.flatMap(({ id }) => id).includes(item)
						),
					isContainer: true
			  })
			: updContainer(record.id, {
					...dto,
					redactors: wbsRegistryForm
						.getFieldValue('redactors')
						?.filter((item: any) => potentialRedactors?.flatMap(({ id }) => id).includes(item))
			  })
		)
			.then(() => {
				queryClient.invalidateQueries(['wbsRegistry'])
				message.success('Данные ВОР сохранены')
				setEditableRow(undefined)
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				Modal.error({ title: 'Ошибка сохранения', content: err.response?.data.detail })
			)
	}

	useEffect(() => {
		setContainer(undefined)
		setAdjustmentContainer(undefined)
	}, [])

	return (
		<>
			<Form form={wbsRegistryForm} component={false} onFinish={() => onSave(recordSave!)}>
				<Table
					size="small"
					rowKey="id"
					dataSource={data ?? []}
					sticky={{ offsetHeader: 0 }}
					scroll={{
						x: 2000,
						y: '62vh'
					}}
					columns={WbsRegistryColumns({
						props: {
							isLoading: isFetching,
							potentialRedactors,
							adjustmentPotentialRedactors,
							constructionObjects,
							wbsRegistryForm,
							onSave,
							data
						}
					})}
					showHeader={!!data?.length}
					className="table-wbs-header"
					rowClassName="at-tblrow"
					components={{
						body: {
							cell: WbsRegistryCell
						}
					}}
					onRow={record => ({
						onDoubleClick: () => {
							record.adjustmentId ? navigate('adjustment/' + record.id) : navigate(record.id)
						}
					})}
					expandable={{
						rowExpandable: row => !!row.children?.length,
						expandIcon: ({ expanded, onExpand, record }) =>
							record.children?.length ? (
								expanded ? (
									<FolderOpenFilled
										style={{ color: '#feb300' }}
										onClick={e => onExpand(record, e)}
									/>
								) : (
									<FolderFilled style={{ color: '#feb300' }} onClick={e => onExpand(record, e)} />
								)
							) : record.mainParent === null ? (
								<FolderFilled style={{ color: '#ddd' }} />
							) : (
								<MinusOutlined style={{ color: '#aaa' }} />
							)
					}}
					loading={isFetching}
					pagination={{
						hideOnSinglePage: true,
						total: totalItems,
						pageSize: PAGE_LIMIT,
						showSizeChanger: false,
						onChange: onPagination,
						current: currentPage
					}}
					onChange={(pagination, filters, FilterValue) => {
						const columnName: any = {
							name: 2,
							typeSection: 4,
							createdDate: 0,
							author: 6,
							ready: 8
						}

						const columnKey = (FilterValue as any).columnKey
						const order = sortVariation((FilterValue as any).order)
						const sortOrder =
							columnName.hasOwnProperty(columnKey) && order
								? columnName[columnKey] + (order === 'ASC' ? 0 : 1)
								: undefined
						setSortState(sortOrder)
					}}
				/>
			</Form>
		</>
	)
}

const sortVariation = (order?: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}
