import { FolderFilled, FolderOpenFilled, MinusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Table, message } from 'antd'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useLocalEstimatePotentialRedactors } from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { updContainer } from 'services/UmdService'
import { PAGE_LIMIT } from 'shared/constants'
import { adjustmentApi } from 'widgets/adjustment'
import { useAdjustmentPotentialRedactors } from 'widgets/adjustment/model/adjustment-query'
import { useWbsPermits } from 'widgets/wbs'
import { useConstructionObjects } from 'widgets/wbs/model/wbs-queries'
import { ILocalEstimate, LocalEstimateRegistryCell, LocalEstimateRegistryColumns } from '..'
import { useLocalEstimateRegistry } from '../model/local-estimate-registry-query'
import { useLocalEstimateRegistryState } from '../model/local-estimate-registry-state'

interface IProps {}

export const LocalEstimateRegistry: FC<IProps> = props => {
	const navigate = useNavigate()
	const location = useLocation()
	const queryClient = useQueryClient()
	const user = useAppSelector(state => state.environment.user)
	const { data, isFetching } = useLocalEstimateRegistry()
	const { data: potentialRedactors } = useLocalEstimatePotentialRedactors()
	const { data: adjustmentPotentialRedactors } = useAdjustmentPotentialRedactors()
	const { data: constructionObjects } = useConstructionObjects()
	const { currentPage, editableRow, totalItems, setEditableRow, onPagination, setSortState } =
		useLocalEstimateRegistryState()
	const [localEstimateRegistryForm] = Form.useForm()
	const wbsPermissions = useWbsPermits()

	useEffect(() => {
		if (editableRow) {
			localEstimateRegistryForm.setFieldsValue({
				name: editableRow.name,
				redactors: editableRow.redactors?.map(r => r.id),
				constructionObjectId: editableRow.constructionObject?.id
			})
		} else {
			localEstimateRegistryForm.resetFields()
		}
	}, [editableRow])

	const onSave = async (record: ILocalEstimate) => {
		const dto = localEstimateRegistryForm.getFieldsValue()
		await (record.adjustmentId
			? adjustmentApi.updContainer(record.id, {
					...dto,
					redactors: localEstimateRegistryForm
						.getFieldValue('redactors')
						?.filter((item: any) =>
							adjustmentPotentialRedactors?.flatMap(({ id }) => id).includes(item)
						),
					isContainer: false
			  })
			: updContainer(
					record.id,
					{
						...dto,
						redactors: localEstimateRegistryForm
							.getFieldValue('redactors')
							?.filter((item: any) => potentialRedactors?.flatMap(({ id }) => id).includes(item))
					},
					'local'
			  )
		)
			.then(() => {
				queryClient.invalidateQueries(['localEstimateRegistry'])
				message.success('Данные Локальной сметы сохранены')
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				message.error('Произошла ошибка во время сохранения')
			)
			.finally(() => {
				setEditableRow(undefined)
			})
	}

	return (
		<Form form={localEstimateRegistryForm} component={false}>
			<Table
				size="small"
				rowKey="id"
				dataSource={data ?? []}
				sticky={{ offsetHeader: 0 }}
				scroll={{
					x: 2000,
					y: '62vh'
				}}
				columns={LocalEstimateRegistryColumns({
					props: {
						isLoading: isFetching,
						potentialRedactors,
						adjustmentPotentialRedactors,
						constructionObjects,
						onSave,
						data
					}
				})}
				showHeader={!!data?.length}
				className="table-wbs-header"
				rowClassName="at-tblrow"
				components={{
					body: {
						cell: LocalEstimateRegistryCell
					}
				}}
				onRow={record => ({
					onDoubleClick: () => {
						record.adjustmentId ? navigate('adjustment/' + record.id) : navigate(record.id)
					}
				})}
				expandable={{
					rowExpandable: row => !!row.children?.length,
					expandIcon: ({ expanded, onExpand, record }) =>
						record.children?.length ? (
							expanded ? (
								<FolderOpenFilled style={{ color: '#feb300' }} onClick={e => onExpand(record, e)} />
							) : (
								<FolderFilled style={{ color: '#feb300' }} onClick={e => onExpand(record, e)} />
							)
						) : record.mainParent === null ? (
							<FolderFilled style={{ color: '#ddd' }} />
						) : (
							<MinusOutlined style={{ color: '#aaa' }} />
						)
				}}
				loading={isFetching}
				pagination={{
					hideOnSinglePage: true,
					total: totalItems,
					pageSize: PAGE_LIMIT,
					showSizeChanger: false,
					onChange: onPagination,
					current: currentPage
				}}
				onChange={(pagination, filters, FilterValue) => {
					const columnName: any = {
						name: 2,
						typeSection: 4,
						createdDate: 0,
						author: 6
					}

					const columnKey = (FilterValue as any).columnKey
					const order = sortVariation((FilterValue as any).order)
					const sortOrder =
						columnName.hasOwnProperty(columnKey) && order
							? columnName[columnKey] + (order === 'ASC' ? 0 : 1)
							: undefined
					setSortState(sortOrder)
				}}
			/>
		</Form>
	)
}

const sortVariation = (order?: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}
