import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import {
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	Descriptions,
	Empty,
	Popconfirm,
	Row,
	Tooltip,
	Typography,
	message
} from 'antd'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IIssue } from 'interfaces/IIssue'
import { IIssueNonEliminationAct, IOrdinance, IOrdinanceIssue } from 'interfaces/IOrdinance'
import SignModal from 'pages/ordinancePage/SignModal'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { downloadFile } from 'services/AdminService'
import { deleteAct } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { ISSUE_CARD_SOURCE, IssueCard, useIssueCardState } from 'widgets/issue-card'
import { SignBlock } from '../SignBlock'
import FileViewer from '../fileViewer'

interface IOrdinanceIssuesProps {
	ordinance: IOrdinance
	canDeleteFiles: boolean
	issues: IOrdinanceIssue[]
	issuesForAct: string[]
	// onDeleteAttachment: (type: 'ordinance' | 'issue', attachmentId: string, issueId: string) => void
	onDeleteAct: () => void
	onIssuesSelect: (issue: string) => void
	onChange: () => void
	onUpdate: () => void
}
const { Text } = Typography

export const OrdinanceIssues: FC<IOrdinanceIssuesProps> = ({
	ordinance,
	canDeleteFiles,
	issues,
	issuesForAct,
	onChange,
	// onDeleteAttachment,
	onDeleteAct,
	onIssuesSelect,
	onUpdate
}) => {
	const checkPermissions = useCheckPermissions()
	const navigate = useNavigate()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [showViewer, setShowViewer] = useState(false)
	const [selectedIssue, setSelectedIssue] = useState<IIssue | null>(null)
	const [showIssueDrawer, setShowIssueDrawer] = useState(false)
	const [signShow, setSignShow] = useState(false)
	const [attachmentType, setAttachmentType] = useState(2)
	const [signingFileId, setSigningFileId] = useState<string>()
	const { permissions } = useAppSelector(state => state.environment)
	const canApprove = checkPermissions([PERMISSIONS.OrdinanceApproval])

	const canNotCheckIssue = (issue: IOrdinanceIssue) =>
		!(
			issue!?.issueStatus!?.identityName! === 'closed' &&
			issue!?.eliminationAct! === null &&
			issue.isActFormed === false
		) &&
		!(
			currentUser?.isAdmin &&
			issue!?.issueStatus!?.identityName! === 'open' &&
			// (dayjs(issue.dueDate).format('YYYY-MM-DD') >= dayjs(Date.now()).format('YYYY-MM-DD') &&
			issue.nonEliminationActs.length >= 0
		)
	const canCheckIssueNonElimination = (issue: IOrdinanceIssue) =>
		currentUser?.isAdmin &&
		issue!?.issueStatus!?.identityName! === 'open' &&
		// (dayjs(issue.dueDate).format('YYYY-MM-DD') >= dayjs(Date.now()).format('YYYY-MM-DD') &&
		issue.nonEliminationActs.length >= 0

	const badgeStatusColor = (status: string) => {
		switch (status) {
			case 'ready_to_inspect':
				return '#7A77D9'
			case 'not approved':
			case 'in dispute':
				return '#FF142D'
			case 'open':
				return '#FFAD14'
			case 'work completed':
			case 'work_completed':
			case 'answered':
				return '#0770C5'
			case 'draft':
				return '#9DAEAF'
			default:
				return '#8099A8'
		}
	}

	const onPreviewOpen = (issue: IIssue) => {
		setSelectedIssue(issue)
		setShowViewer(true)
	}

	const onPreviewClose = () => {
		setSelectedIssue(null)
		setShowViewer(false)
	}

	const onIssueDrawerClose = () => {
		navigate('/control/ordinance')
		// setSelectedIssue(null)
		// setShowIssueDrawer(false)
		// if (mustUpdate) {
		// 	onChange()
		// }
	}

	const onSigning = (type: number, actId?: string) => {
		setAttachmentType(type)
		setSigningFileId(actId)
		setSignShow(true)
	}

	const downloadAct = async (issue: IOrdinanceIssue, actType: string, id?: string) => {
		const act: { id: string; name: string } | null =
			Object.entries(issue)?.find(item => item[0] === actType)?.[1] || null
		const nonEliminationAct =
			actType === 'nonEliminationActs'
				? Object.entries(issue)
						?.find(item => item[0] === actType)?.[1]!
						?.find((elem: IIssueNonEliminationAct) => elem.attachmentId === id)!
				: null
		if ((actType === 'nonEliminationActs' ? nonEliminationAct : act) !== null)
			await downloadFile(
				actType === 'nonEliminationActs' ? nonEliminationAct.attachmentId : act?.id
			)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					const extension =
						ordinance!?.attachments!?.find(
							file =>
								file.id ===
								(actType === 'nonEliminationActs' ? nonEliminationAct.attachmentId : act?.id)
						)?.extension! || '.zip'
					link.href = href
					link.setAttribute(
						'download',
						(actType === 'nonEliminationActs' ? nonEliminationAct.name : act?.name) + extension
					)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch(() => message.error('Во время загрузки файла произошла ошибка'))
		else message.error('Файл не найден')
	}

	return (
		<>
			<FileViewer show={showViewer} onClose={onPreviewClose} src={selectedIssue} />
			<IssueCard
				source={ISSUE_CARD_SOURCE.ordinance}
				onClose={onIssueDrawerClose}
				updateList={onUpdate}
				// drawerOpen={showIssueDrawer}
				// currentIssue={selectedIssue!?.id!}
				// onDrawerClose={onIssueDrawerClose}
				// opacity={0}
			/>
			<SignModal
				ordinance={ordinance}
				show={signShow}
				attachmentType={attachmentType}
				signingFileId={signingFileId}
				onClose={() => setSignShow(false)}
				onConfirm={onUpdate}
			/>
			{issues! && issues!?.length > 0 ? (
				issues
					.sort((a, b) => a.number - b.number)
					.map((issue, index) => (
						<Card className="at-workcard" key={issue!?.id!}>
							<Row align="middle" gutter={8} wrap={false}>
								{checkPermissions([PERMISSIONS.OrdinanceCreateEliminationAct]) && (
									<Col>
										<Tooltip
											title={
												canNotCheckIssue(issue) && issue!?.issueStatus!?.identityName! === 'open'
													? 'По открытым замечаниям акты нельзя формировать, нужно закрыть Замечание. Нельзя сформировать Акт о неустранении.'
													: canNotCheckIssue(issue)
													? 'Нельзя сформировать Акт об устранении и Акт о неустранении.'
													: issue!?.issueStatus!?.identityName! === 'open' &&
													  canCheckIssueNonElimination(issue)
													? 'По открытым замечаниям акты нельзя формировать, нужно закрыть Замечание. Можно сформировать Акт о неустранении.'
													: issue!?.issueStatus!?.identityName! === 'closed' &&
													  (issue.isActFormed || issue!?.eliminationAct! !== null)
													? 'Акт уже был сформирован. Если файла нет, обратитесь в тех поддержку. Нельзя сформировать Акт о неустранении.'
													: issue!?.issueStatus!?.identityName! === 'closed' &&
													  !canCheckIssueNonElimination(issue)
													? 'По закрытым замечаниям можно формировать Акт об устранении. Нельзя сформировать Акт о неустранении.'
													: null
											}
										>
											<Checkbox
												data-issue-box={index + 1}
												disabled={canNotCheckIssue(issue)}
												checked={issuesForAct!?.some(row => row === issue!?.id!)}
												onChange={() => onIssuesSelect(issue!?.id!)}
											/>
										</Tooltip>
									</Col>
								)}
								<Col flex={1}>
									<Badge.Ribbon
										text={<Tooltip title="Статус замечания">{issue!?.issueStatus!?.name!}</Tooltip>}
										color={badgeStatusColor(issue!?.issueStatus!?.identityName!)}
									>
										<Descriptions
											layout="horizontal"
											column={12}
											bordered
											className="at-descriptions"
											labelStyle={{
												fontWeight: 'bold',
												width: '20%',
												alignItems: 'center',
												padding: '4px 8px'
											}}
											contentStyle={{
												width: '80%',
												padding: '4px 8px'
											}}
										>
											<Descriptions.Item label="Номер замечания:" span={12}>
												<Text>{issue!?.number!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Наименование:" span={12}>
												{checkPermissions([PERMISSIONS.IssueView]) ? (
													<Button
														type="link"
														style={{
															padding: 0,
															margin: 0,
															whiteSpace: 'pre-wrap',
															textAlign: 'left'
														}}
														onClick={() => {
															setSelectedIssue(issue)
															setShowIssueDrawer(true)
															useIssueCardState.setState({ showCard: true })
															useIssueCardState.setState({ issueId: issue.id })
														}}
													>
														{issue!?.title!}
													</Button>
												) : (
													issue!?.title!
												)}
											</Descriptions.Item>
											<Descriptions.Item label="Владелец:" span={12}>
												<Text>{issue!?.owner!?.name!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Генподрядчик:" span={12}>
												<Text>{issue!?.contractor?.name!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Субподрядчик:" span={12}>
												<Text>
													{issue!?.subcontractor !== null ? issue!?.subcontractor?.name! : '-'}
												</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Дата создания:" span={12}>
												<Text>{dayjs(issue!?.createdAt!).format('DD.MM.YYYY')}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Дата устранения:" span={12}>
												<Text>{dayjs(issue!?.dueDate!).format('DD.MM.YYYY')}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Номер пункта нормативного акта:" span={12}>
												<Text>{issue!?.actPointMinDto?.name! ? issue!?.actPointMinDto?.name! : issue?.actPoints}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Акт об устранении:" span={12}>
												{issue.eliminationAct !== null && (
													<Row align="middle" gutter={8} wrap={false}>
														<Col flex={1}>
															<Button
																className="at-btn-wrap"
																style={{
																	padding: 0,
																	margin: 0,
																	whiteSpace: 'pre-wrap',
																	textAlign: 'left'
																}}
																type="link"
																onClick={() => downloadAct(issue, 'eliminationAct')}
																disabled={
																	!(
																		ordinance?.attachments?.find(
																			file => file!?.id! === issue!?.eliminationAct!?.id!
																		)?.statusId! === 2 ||
																		//  &&
																		// 	ordinance?.attachments?.find(
																		// 		file => file!?.id! === issue!?.eliminationAct!?.id!
																		// 	)?.contentType === 'application/zip'
																		ordinance?.attachments?.find(
																			file => file!?.id! === issue!?.eliminationAct!?.id!
																		)?.statusId! === 3 ||
																		ordinance?.attachments?.find(
																			file => file!?.id! === issue!?.eliminationAct!?.id!
																		)?.statusId! === 4
																	)
																}
															>
																{issue!?.eliminationAct!?.name!}
															</Button>
														</Col>
														{issue!?.eliminationAct !== null &&
															(ordinance?.attachments?.find(
																file => file!?.id! === issue!?.eliminationAct!?.id!
															)?.statusId! === 2 ||
																//  &&
																// 	ordinance?.attachments?.find(
																// 		file => file!?.id! === issue!?.eliminationAct!?.id!
																// 	)?.contentType === 'application/zip'
																ordinance?.attachments?.find(
																	file => file!?.id! === issue!?.eliminationAct!?.id!
																)?.statusId! === 3 ||
																ordinance?.attachments?.find(
																	file => file!?.id! === issue!?.eliminationAct!?.id!
																)?.statusId! === 4) && (
																<Col>
																	<SignBlock
																		actId={issue!?.eliminationAct!?.id!}
																		ordinance={ordinance}
																		typeId={2}
																		onClick={(type, actId) => onSigning(type, actId)}
																	/>
																</Col>
															)}
														{canDeleteFiles &&
															(ordinance?.attachments?.find(file =>
																issue!?.eliminationAct!?.id
																	? file!?.id! === issue!?.eliminationAct!?.id!
																	: file!?.typeId! === 2
															)?.statusId! === 2 ||
																//  &&
																// 	ordinance?.attachments?.find(file =>
																// 		issue!?.eliminationAct!?.id
																// 			? file!?.id! === issue!?.eliminationAct!?.id!
																// 			: file!?.typeId! === 2
																// 	)?.contentType === 'application/zip'
																ordinance?.attachments?.find(file =>
																	issue!?.eliminationAct!?.id
																		? file!?.id! === issue!?.eliminationAct!?.id!
																		: file!?.typeId! === 2
																)?.statusId! === 3) && (
																<Col>
																	<Popconfirm
																		title="Удалить?"
																		placement="topRight"
																		onConfirm={async () =>
																			// onDeleteAttachment('issue', issue!?.eliminationAct!?.id!, issue?.id!)
																			await deleteAct(
																				ordinance!?.id,
																				issue!?.eliminationAct?.id
																			).then(() => {
																				onDeleteAct()
																			})
																		}
																		okButtonProps={{ danger: true }}
																		okText="Да"
																		cancelText="Отмена"
																	>
																		<Button icon={<DeleteOutlined />} danger />
																	</Popconfirm>
																</Col>
															)}
													</Row>
												)}
											</Descriptions.Item>
											{issue!?.nonEliminationActs.length > 0 &&
												issue!?.nonEliminationActs?.map((nonEliminationAct, index) => (
													<Descriptions.Item
														label={
															index === 0 ? 'Акт о неустранении:' : 'Повторный Акт о неустранении:'
														}
														span={12}
													>
														<Row align="middle" gutter={8} wrap={false}>
															<Col flex={1}>
																<Button
																	disabled={
																		!(
																			(ordinance.attachments.find(
																				file => file.id === nonEliminationAct.attachmentId
																			)?.statusId === 4 &&
																				ordinance.attachments.find(
																					file => file.id === nonEliminationAct.attachmentId
																				)?.contentType === 'application/pdf' ||
																				ordinance.attachments.find(
																					file => file.id === nonEliminationAct.attachmentId
																				)?.contentType === 'application/zip') ||
																			(ordinance.attachments.find(
																				file => file.id === nonEliminationAct.attachmentId
																			)?.statusId === 5 &&
																				// ordinance.attachments.find(
																				// 	file => file.id === nonEliminationAct.attachmentId
																				// )?.contentType === 'application/zip' &&
																				(currentUser?.id === ordinance.createdBy.id || canApprove))
																		)
																	}
																	className="at-btn-wrap"
																	style={{
																		padding: 0,
																		margin: 0,
																		whiteSpace: 'pre-wrap',
																		textAlign: 'left'
																	}}
																	type="link"
																	onClick={() =>
																		downloadAct(
																			issue,
																			'nonEliminationActs',
																			nonEliminationAct.attachmentId
																		)
																	}
																>
																	{nonEliminationAct!?.name!}
																</Button>
															</Col>
															{nonEliminationAct !== null &&
																((ordinance.attachments.find(
																	file => file.id === nonEliminationAct.attachmentId
																)?.statusId === 5 &&
																	// ordinance.attachments.find(
																	// 	file => file.id === nonEliminationAct.attachmentId
																	// )?.contentType === 'application/zip' &&
																	(currentUser?.id === ordinance.createdBy.id || canApprove)) ||
																	ordinance.attachments.find(
																		file => file.id === nonEliminationAct.attachmentId
																	)?.statusId === 3 ||
																	ordinance.attachments.find(
																		file => file.id === nonEliminationAct.attachmentId
																	)?.statusId === 4) && (
																	<Col>
																		<SignBlock
																			actId={nonEliminationAct!?.attachmentId!}
																			ordinance={ordinance}
																			typeId={3}
																			onClick={(type, actId) => onSigning(type, actId)}
																		/>
																	</Col>
																)}
															{canDeleteFiles &&
																(ordinance?.attachments?.find(file =>
																	nonEliminationAct!?.attachmentId
																		? file!?.id! === nonEliminationAct!?.attachmentId!
																		: file!?.typeId! === 2
																)?.statusId! === 3 ||
																	(ordinance?.attachments?.find(file =>
																		nonEliminationAct!?.attachmentId
																			? file!?.id! === nonEliminationAct!?.attachmentId!
																			: file!?.typeId! === 2
																	)?.statusId! === 5 &&
																		// ordinance.attachments.find(
																		// 	file => file.id === nonEliminationAct.attachmentId
																		// )?.contentType === 'application/zip' &&
																		(currentUser?.id === ordinance.createdBy.id ||
																			canApprove))) && (
																	<Col>
																		<Popconfirm
																			title="Удалить?"
																			placement="topRight"
																			onConfirm={async () =>
																				// onDeleteAttachment('issue', issue!?.nonEliminationAct!?.id!, issue?.id!)
																				await deleteAct(
																					ordinance!?.id,
																					nonEliminationAct?.attachmentId
																				).then(() => {
																					onDeleteAct()
																				})
																			}
																			okButtonProps={{ danger: true }}
																			okText="Да"
																			cancelText="Отмена"
																		>
																			<Button icon={<DeleteOutlined />} danger />
																		</Popconfirm>
																	</Col>
																)}
														</Row>
													</Descriptions.Item>
												))}
										</Descriptions>
										{issue!?.attachmentsCount! > 0 && (
											<Row justify="end" style={{ marginTop: '1rem' }}>
												<Button
													icon={<EyeOutlined rev={undefined} />}
													onClick={() => onPreviewOpen(issue!)}
												>
													Файлы
												</Button>
											</Row>
										)}
									</Badge.Ribbon>
								</Col>
							</Row>
						</Card>
					))
			) : (
				<Empty />
			)}
		</>
	)
}
