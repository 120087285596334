import { useQuery } from '@tanstack/react-query'
import { issueCardApi } from '..'
import { useIssueCardState } from './issue-card-state'

export const useIssueCard = () => {
	const { issueId } = useIssueCardState()
	return useQuery({
		queryKey: ['issue', 'card', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.get(issueId).then(data => data)
			return null
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useIssueAttachments = () => {
	const { issueId } = useIssueCardState()
	return useQuery({
		queryKey: ['issue', 'attachments', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.getAttachments(issueId).then(data => data)
			return null
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}
