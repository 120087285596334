import { Button, Col, Modal, Row, Select, Typography } from 'antd'
import { FormInstance } from 'antd/lib'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import useDebounce from 'hooks/useDebounce'
import { FC, useEffect, useState } from 'react'
import { wbsTaskCardApi } from '../api/wbs-task-card-api'
import { useWbsTaskCardContainers, useWbsTaskCardEstimates } from '../api/wbs-task-card-queries'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'

const { Title } = Typography

interface IProps {
	form: FormInstance
	formValues: TWbsTaskForm
	isEstimate: boolean
}

export const WbsTaskModal: FC<IProps> = props => {
	const { form, formValues, isEstimate } = props
	const { openModal, setCloseModal, currentPage } = useWbsTaskCardState()
	const [search, setSearch] = useState<string | null>(null)
	const { data: estimates, isFetching: estimatesFetching } = useWbsTaskCardEstimates(
		formValues?.contractId!,
		formValues?.constructionObjectId!
	)
	const { data: containers, isFetching: containersFetching } = useWbsTaskCardContainers(
		formValues?.typeSectionId!,
		formValues?.constructionObjectId!
	)
	const [estimatesSearch, setEstimatesSearch] = useState<any[]>()
	const [containersSearch, setContainersSearch] = useState<any[]>()
	const [containersData, setContainersData] = useState<any[]>(containers!)
	const [estimatesData, setEstimatesData] = useState<any[]>(estimates!)
	const [currentPageBasis, setCurrentPageBasis] = useState(currentPage)

	useEffect(() => {
		setContainersData(containers!)
		setEstimatesData(estimates!)
	}, [containers, estimates])

	const debounceFetch = (value: string, type: string) => {
		if (value.length >= 3) {
			setSearch(value)
			if (type === 'wbs') {
				wbsTaskCardApi
					.getWbsTaskContainer(formValues?.typeSectionId!, formValues?.constructionObjectId!, value)
					.then(data => {
						setContainersSearch(data.data!)
					})
			}
			if (type === 'estimate') {
				wbsTaskCardApi
					.getWbsTaskEstimate(formValues?.contractId!, formValues?.constructionObjectId!, value)
					.then(data => {
						setEstimatesSearch(data.data!)
					})
			}
		} else {
			setSearch(null)
			setContainersSearch(containers!)
			setEstimatesSearch(estimates!)
		}
	}
	const debouncer = useDebounce((deb: string, type: string) => debounceFetch(deb, type), 1000)

	const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>, type: 'wbs' | 'estimate') => {
		const target = event.currentTarget

		if (target.scrollHeight === Math.ceil(target.scrollTop) + target.offsetHeight) {
			if (type === 'wbs') {
				wbsTaskCardApi
					.getWbsTaskContainer(
						formValues?.typeSectionId!,
						formValues?.constructionObjectId!,
						search!,
						currentPageBasis + 1
					)
					.then(res => {
						setContainersData((prev: any[]) => [...prev, ...res.data])
						setCurrentPageBasis(prev => prev + 1)
					})
			}
			if (type === 'estimate') {
				wbsTaskCardApi
					.getWbsTaskEstimate(
						formValues?.contractId!,
						formValues?.constructionObjectId!,
						search!,
						currentPageBasis + 1
					)
					.then(res => {
						setEstimatesData((prev: any[]) => [...prev, ...res.data])
						setCurrentPageBasis(prev => prev + 1)
					})
			}
		}
	}

	const onClose = () => {
		setCloseModal()
		setCurrentPageBasis(1)
		setEstimatesData(estimates!)
		setContainersData(containers!)
		form.resetFields(['containerLabel', 'estimateLabel', 'estimateId', 'containerId'])
	}

	return (
		<Modal
			destroyOnClose
			onCancel={onClose}
			centered
			open={openModal}
			title="Выбрать основание"
			width="30vw"
			footer={
				<Row justify="end">
					<Col>
						<Button onClick={onClose} style={{ marginRight: 5 }}>
							Отмена
						</Button>
						<Button
							type="primary"
							onClick={() => {
								setCloseModal()
							}}
						>
							Ок
						</Button>
					</Col>
				</Row>
			}
		>
			<Row justify="center">
				<Col
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '.5rem',
						padding: '1rem',
						width: '100%'
					}}
				>
					<>
						{!isEstimate && (
							<Select
								showSearch={true}
								optionFilterProp={'children'}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onSearch={value => debouncer(value, 'wbs')}
								loading={containersFetching}
								disabled={containers?.length === 0}
								options={(containersSearch! ?? containersData!)?.map(c => ({
									key: c.id,
									label: c.name,
									value: c.id
								}))}
								allowClear
								onChange={(value, option: any) => {
									form.setFieldValue('containerId', option.value)
									form.setFieldValue('containerLabel', option.label)
								}}
								onPopupScroll={e => onScroll(e, 'wbs')}
							/>
						)}
						{isEstimate && (
							<Select
								showSearch={true}
								optionFilterProp={'children'}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								onSearch={value => debouncer(value, 'estimate')}
								loading={estimatesFetching}
								disabled={estimates?.length === 0}
								options={(estimatesSearch ?? estimatesData!)?.map(c => ({
									key: c.id,
									label: c.name,
									value: c.id
								}))}
								allowClear
								onChange={(value, option: any) => {
									form.setFieldValue('estimateId', option.value)
									form.setFieldValue('estimateLabel', option.label)
								}}
								onPopupScroll={e => onScroll(e, 'estimate')}
							/>
						)}
					</>
				</Col>
			</Row>
		</Modal>
	)
}
