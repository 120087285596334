import { useQuery } from '@tanstack/react-query'
import { notification } from 'antd'
import { API } from 'services/ApiService'
import { useAppState } from 'shared/state/app-state'

export const useDownloadQueue = () => {
	const { isFormed, download, unset } = useAppState()
	return useQuery({
		queryKey: ['queue', download],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			if (download) {
				return await API({
					url: '/v1/export/getFileForDownloading',
					method: 'get',
					params: download,
					responseType: 'blob'
				}).then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = href
					link.setAttribute('download', download.fileName)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
					unset()
					notification.success({
						message: 'Файл сформирован',
						description: download.fileName,
						placement: 'topLeft'
					})
				})
			}
			return null
		},
		retryDelay: 10000,
		retry: 18
	})
}
