import { Checkbox, Form, FormInstance, Popconfirm } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC, useState } from 'react'

interface IProps {
	isTypical?: boolean
	form: FormInstance<TIssueDetailForm>
}

export const IssueCardDetailsIsTypical: FC<IProps> = ({ isTypical = false, form }) => {
	const [show, setShow] = useState(false)
	const onToggleCheckbox = (e: CheckboxChangeEvent) => {
		if (e.target.checked === false) setShow(true)
		else {
			form.setFieldValue('isTypical', true)
			form.validateFields(['workPackageId'])
			form.setFieldsValue({
				actPoints: undefined,
				description: undefined
			})
		}
	}
	const onConfirmHandler = () => {
		form.validateFields(['workPackageId'])
		form.setFieldsValue({
			isTypical: false,
			workPackageId: undefined,
			issueTypeId: undefined,
			issueSubTypeId: undefined,
			violationTypeId: undefined,
			title: undefined,
			actPointId: undefined,
			actPoints: undefined,
			description: undefined,
			violationId: undefined
		})
		setShow(false)
	}
	const onCancelHandler = () => {
		setShow(false)
	}
	return (
		<Form.Item label="Типовое" name="isTypical">
			<Popconfirm
				title="Внимание!"
				description="Ранее выбранные элементы справочников будут очищены"
				okText="Подтвердить"
				open={show}
				onConfirm={onConfirmHandler}
				onCancel={onCancelHandler}
			>
				<Checkbox checked={isTypical} className="app-form-text" onChange={onToggleCheckbox} />
			</Popconfirm>
		</Form.Item>
	)
}
