import { MenuOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Row } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	ATIconAnalytics,
	ATIconCheckList,
	ATIconDocuments,
	ATIconGuide,
	ATIconSettings,
	ATIconUnmodel,
	ATTasks
} from 'UI/icons'
import { useAppSelector } from 'hooks/appReduxHook'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useWbsPermits } from 'widgets/wbs'
interface ATMenuItems {
	icon: ReactNode
	title: string
	route: string
	canView: boolean
}

const ATMenu: FC = () => {
	const checkPermissions = useCheckPermissions()
	const [visible, setVisible] = useState(false)
	const { user: currentUser, permissions } = useAppSelector(state => state?.environment)
	const navigate = useNavigate()
	const wbsPermits = useWbsPermits()
	const canView = {
		checklist:
			checkPermissions([PERMISSIONS.ChecklistsUksNdc, PERMISSIONS.ChecklistsUkKc]) ?? false,
		control:
			checkPermissions([
				PERMISSIONS.IssueView,
				PERMISSIONS.OrdinanceView,
				PERMISSIONS.OrdinanceFine
			]) ?? false,
		analytics:
			(currentUser?.isAdmin ||
				permissions?.some(p => p.name.toLowerCase().includes('report')) ||
				permissions?.some(p => p.name.includes('Report'))) ??
			false,
		unmodel: currentUser?.isAdmin || Object.values(wbsPermits).some(p => p === true),
		admin: currentUser?.isAdmin || false,
		guides: currentUser?.isAdmin || false
	}

	const menuItems: ATMenuItems[] = [
		{
			icon: <ATIconCheckList />,
			title: 'Чек-листы',
			route: '/checklist',
			canView: canView.checklist
		},
		{
			icon: <ATIconDocuments />,
			title: 'Замечания / Предписания / Штрафы',
			route: '/control',
			canView: canView.control
		},
		{
			icon: <ATIconAnalytics />,
			title: 'Отчёты',
			route: '/analytics',
			canView: canView.analytics
		},
		{
			icon: <ATIconUnmodel />,
			title: 'ВОР',
			route: '/unmodel',
			canView: canView.unmodel
		},
		{
			icon: <ATTasks />,
			title: 'Заявки ВОР',
			route: '/wbs-tasks',
			canView: canView.admin
		},
		{
			icon: <ATIconSettings />,
			title: 'Настройки приложения',
			route: '/admin',
			canView: canView.admin
		},
		{
			icon: <ATIconGuide />,
			title: 'Справочники',
			route: '/guides',
			canView: canView.guides
		}
	]

	const CategoryMenu = () => {
		return (
			<div
				className="at-menu"
				style={{
					width:
						menuItems.filter(fi => fi.canView).length === 1
							? '300px'
							: menuItems.filter(fi => fi.canView).length === 2
							? '325px'
							: '525px'
				}}
			>
				<div className="at-menu__title">Разделы</div>
				<Row
					gutter={[12, 12]}
					className="at-menu__grid"
					style={{
						width:
							menuItems.filter(fi => fi.canView).length === 1
								? '300px'
								: menuItems.filter(fi => fi.canView).length === 2
								? '300px'
								: '500px'
					}}
				>
					{menuItems
						.filter(fi => fi.canView)
						.map((item, index) => (
							<Col
								span={
									menuItems.filter(fi => fi.canView).length === 1 ||
									menuItems.filter(fi => fi.canView).length === 2
										? 12
										: 8
								}
								key={index}
							>
								<Button
									className="at-menu__item"
									// disabled={true}
									onClick={() => {
										navigate(item.route)
										setVisible(false)
									}}
								>
									<div
										className="at-menu__item-icon"
										style={{
											paddingBottom:
												item.title !== 'Замечания / Предписания / Штрафы' &&
												item.title !== 'Настройки приложения'
													? 25
													: item.title === 'Настройки приложения'
													? 17
													: 5
										}}
									>
										{item.icon}
									</div>
									<div
										className="at-menu__item-title"
										style={{
											paddingBottom:
												item.title !== 'Замечания / Предписания / Штрафы' &&
												item.title !== 'Настройки приложения'
													? 25
													: item.title === 'Настройки приложения'
													? 17
													: 5
										}}
									>
										{item.title}
									</div>
								</Button>
							</Col>
						))}
				</Row>
				<Row className="at-menu__footer" justify="space-between" align="middle">
					<Col flex={2} className={'at-flex at-col-vertical'}>
						<a
							target="_blank"
							rel="noreferrer"
							download="Политика_ПД_ИГ АБСОЛЮТ.pdf"
							href="/files/policy.pdf"
						>
							Политика конфиденциальности
						</a>
						© Абсолют.Технологии, {new Date().getFullYear()}
					</Col>
					<Col></Col>
				</Row>
			</div>
		)
	}

	return (
		<Dropdown
			dropdownRender={CategoryMenu}
			placement="bottomLeft"
			arrow={{ pointAtCenter: true }}
			trigger={['click']}
			open={visible}
			onOpenChange={open => setVisible(open)}
		>
			<Button className="at-btn-menu" icon={<MenuOutlined />} />
		</Dropdown>
	)
}

export default ATMenu
