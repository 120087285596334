import { create } from "zustand"

interface IWbsTaskCardState {
    currentPage: number
    totalItems?: number
	openModal: boolean
	openModalAssign: boolean
	setOpenModal: () => void
	setCloseModal: () => void
	setOpenModalAssign: () => void
	setCloseModalAssign: () => void
    onPagination: (currentPage: number) => void
	setSearch: (search: string) => void
	setTotalItems: (totalItems: number) => void
}

export const useWbsTaskCardState = create<IWbsTaskCardState>((set, get) => ({
    currentPage: 1,
    totalItems: undefined,
	openModal: false, 
	openModalAssign: false,
	setOpenModal: () =>  set(() => ({ openModal: true })),
	setCloseModal: () =>  set(() => ({ openModal: false })),
	setOpenModalAssign: () =>  set(() => ({ openModalAssign: true })),
	setCloseModalAssign: () =>  set(() => ({ openModalAssign: false })),
    onPagination: currentPage => set(() => ({ currentPage })),
	setSearch: search =>
		set(() => ({
			search: search.length ? search : undefined ,
			currentPage: 1
		})),
	setTotalItems: totalItems => set(() => ({ totalItems })),

}))