import { Form, Input, Modal } from 'antd'
import { ICurrentChecklistItem } from 'interfaces/IChecklist'
import { FC, useEffect } from 'react'
import { addDocumentation } from 'services/ChecklistsService'

interface IProps {
	openModal: boolean
	item?: ICurrentChecklistItem | null
	onCancel: () => void
	onReloadChecklist: () => void
}

export const SectionDocumentationModal: FC<IProps> = props => {
	const { openModal, item, onCancel, onReloadChecklist } = props
	const [documentationForm] = Form.useForm()

	useEffect(() => {
		documentationForm.setFieldValue(
			'documentation',
			item?.documentation ? item?.documentation : 'https://'
		)
	}, [openModal])

	const addLink = async () => {
		await addDocumentation(item?.id!, documentationForm.getFieldValue('documentation').trim()).then(
			() => {
				onCancel()
				onReloadChecklist()
			}
		)
	}

	return (
		<Modal
			title="Ссылка на документацию"
			open={openModal}
			destroyOnClose={true}
			onOk={() => documentationForm.submit()}
			okText="Добавить"
			onCancel={() => onCancel()}
			cancelText="Закрыть"
		>
			<p>
				Введите ссылку на документацию (должна содержать workspace.google.com, sarex.io или
				docs.google.com)
			</p>
			<Form name="documentationForm" form={documentationForm} onFinish={addLink}>
				<Form.Item
					name="documentation"
					rules={[
						() => ({
							validator(_, value) {
								if (value?.length > 200) {
									return Promise.reject(new Error('Максимальная длина 200 символов'))
								}
								if (
									!value.trim()?.includes('workspace.google.com') &&
									!value.trim()?.includes('sarex.io') &&
									!value.trim()?.includes('docs.google.com') &&
									value.length > 0
								) {
									return Promise.reject(new Error('Некорректная ссылка'))
								}
								return Promise.resolve()
							}
						})
					]}
				>
					<Input.TextArea
						placeholder={
							'Введите ссылку на документацию (должна содержать workspace.google.com, sarex.io или docs.google.com)'
						}
						showCount
						maxLength={200}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
