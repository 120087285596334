import { useQuery } from '@tanstack/react-query'
import { useHistoryState } from 'UI/history/model/history-state'
import { historyService } from 'services/history.service'

interface IUseHistoryProps {
	target: 'issue' | 'ordinance' | 'checklist' | 'template' | 'ordinanceFine' | 'wbs' | 'local' | 'wbsTask'
	id?: string
}

export const useHistory = (props: IUseHistoryProps) => {
	const { currentPage, setTotalItems, setTotalPages } = useHistoryState()
	return useQuery({
		queryKey: [`${props.target}History`, props.id, currentPage],
		queryFn: async () => {
			const history = await historyService[props.target]({
				id: props.id!,
				offset: currentPage === 1 ? 0 : currentPage * 20 - 20
			})
			setTotalItems(history!.totalItems)
			setTotalPages(history!.totalPages)
			return props.id ? history.data : null
		}
	})
}
