import { create } from "zustand"

interface IWbsTaskVisasState {
    showModal: boolean
	openModal: () => void
    closeModal: () => void
}

export const useWbsTaskVisasState = create<IWbsTaskVisasState>((set, get) => ({
    showModal: false,
	openModal: () => set(() => ({ showModal: true })),
    closeModal: () => set(() => ({ showModal: false }))
}))