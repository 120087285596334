import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import { IContactPerson } from 'interfaces/IIssue'
import { API } from 'services/ApiService'
import { IWorkPackage } from 'services/workPackageService'
import { TBase } from 'shared/schemas'
import {
	TBuildingPermit,
	TIssueActPoint,
	TIssueStatus,
	TIssueSubType,
	TIssueType,
	TIssueViolation,
	TIssueViolationType
} from '../model/issue-schema'

export const issueApi = {
	async getStatuses() {
		return await API<TIssueStatus[]>({
			url: 'v1/issueStatuses',
			method: 'get'
		}).then(response => response.data)
	},
	async getWorkPackages() {
		return await API<IWorkPackage[]>({
			url: `/v1/workPackage`,
			method: 'get'
		}).then(response => response.data)
	},
	async getTypes() {
		return await API<TIssueType[]>({
			url: 'v1/issueTypes',
			method: 'get'
		}).then(response => response.data)
	},
	async getSubTypes(issueType: string) {
		return await API<TIssueSubType[]>({
			url: 'v1/issueSubTypes',
			method: 'get',
			params: { IssueTypeId: issueType }
		}).then(response => response.data)
	},
	async getViolationTypes(issueSubType: string) {
		return await API<TIssueViolationType[]>({
			url: 'v1/violationTypes',
			method: 'get',
			params: { IssueSubTypeId: issueSubType }
		}).then(response => response.data)
	},
	async getViolations({
		violationTypeId,
		workPackageId,
		actPointId,
		signal
	}: {
		violationTypeId?: string
		workPackageId?: string
		actPointId?: string
		signal?: AbortSignal
	}) {
		return await API<TIssueViolation[]>({
			url: 'v1/violation',
			method: 'get',
			params: { violationTypeId, workPackageId, actPointId },
			signal
		}).then(response => response.data)
	},
	async getActPoints({
		violationTypeId,
		workPackageId,
		violationId
	}: {
		violationTypeId?: string
		workPackageId?: string
		violationId?: string
	}) {
		return await API<TIssueActPoint[]>({
			url: 'v1/actPoints',
			method: 'get',
			params: { violationTypeId, workPackageId, violationId }
		}).then(response => response.data)
	},
	async getSystems() {
		return await API<TBase[]>({
			url: 'v1/checklistTemplatesActiveUkKS',
			method: 'get'
		}).then(response => response.data)
	},
	async getBuildingPermit() {
		return await API<TBuildingPermit[]>({
			url: 'v1/buildingPermits',
			method: 'get'
		}).then(response => response.data)
	},
	async getContractors() {
		return await API<IContractor[]>({
			url: `/v1/contractors`,
			method: 'get'
		}).then(response => response.data)
	},
	async getContracts(contractorId?: string, projectId?: string) {
		return await API<IContract[]>({
			url: `/v1/contracts`,
			method: 'get',
			params: { contractorId, projectId }
		}).then(response => response.data)
	},
	async getContactPersons(contractorId?: string) {
		return await API<IContactPerson[]>({
			url: `/v1/contactPersons`,
			method: 'get',
			params: { contractorId }
		}).then(response => response.data)
	},
	async addComment(commentDto: FormData) {
		return await API({
			headers: { 'content-type': 'multipart/form-data' },
			url: '/v1/issueComment',
			method: 'post',
			data: commentDto
		}).then(async response => response.data)
	},
	async getPotentialAuthors(projectId: string) {
		return await API({
			url: `/v1/issues/potentialAuthors/${projectId}`,
			method: 'get'
		}).then(async response => response.data)
	}
}
