import { Form, Input } from 'antd'
import { Rule } from 'antd/es/form'
import { FC } from 'react'

interface IProps {
	isArea?: boolean
	canEdit: boolean
	disabled?: boolean
	dataSourceValue?: string | null
	name: string
	label: string
	rules?: Rule[]
}

export const AbsFormInput: FC<IProps> = props => {
	const { canEdit, disabled, name, label, dataSourceValue, rules, isArea } = props
	return canEdit ? (
		<Form.Item label={label} name={name} rules={rules} className="app-form-item">
			{isArea ? (
				<Input.TextArea
					allowClear
					variant="borderless"
					disabled={disabled}
					autoSize={{
						minRows: 4,
						maxRows: 4
					}}
				/>
			) : (
				<Input allowClear variant="borderless" disabled={disabled} />
			)}
		</Form.Item>
	) : (
		<Form.Item label={label} className="app-form-item">
			<span className="app-form-text">{dataSourceValue ?? '-'}</span>
		</Form.Item>
	)
}
