import { Button, Divider, Form, Input, Select, Skeleton } from 'antd'
import { FormInstance, Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC, useCallback, useEffect, useState } from 'react'

interface IProps {
	canEdit: boolean
	disabled?: boolean
	isLoading: boolean
	isManualInit: boolean
	label: string
	manualDataSourceValue?: string | null
	manualField: 'actPoints' | 'description'
	options?: DefaultOptionType[]
	violations?: DefaultOptionType[]
	actPoints?: DefaultOptionType[]
	rules?: Rule[]
	selectorDataSourceValue?: string | null
	selectorField: string
	form: FormInstance<TIssueDetailForm>
	formValues: TIssueDetailForm
}

export const IssueFormBranchItem: FC<IProps> = props => {
	const {
		canEdit,
		disabled,
		isLoading,
		isManualInit,
		label,
		manualDataSourceValue,
		manualField,
		options,
		violations,
		actPoints,
		rules,
		selectorDataSourceValue,
		selectorField,
		form,
		formValues
	} = props

	const [isManual, setIsManual] = useState({ actPoints: isManualInit, description: isManualInit })
	const onSetManual = useCallback((manualField?: string) => {
		setIsManual(
			manualField === 'actPoints'
				? { ...isManual, actPoints: true }
				: manualField === 'description'
				? { ...isManual, description: true }
				: { actPoints: true, description: true }
		)
		form.validateFields(['workPackageId'])
		form.setFieldsValue({
			isTypical: false,
			[selectorField]: undefined
		})
	}, [])

	useEffect(() => {
		if (formValues?.isTypical === true) {
			setIsManual({ actPoints: false, description: false })
		}
	}, [formValues?.isTypical])

	useEffect(() => {
		if (typeof formValues?.workPackageId !== 'undefined' && formValues.isTypical === true)
			setIsManual({ actPoints: false, description: false })
	}, [formValues?.workPackageId])

	useEffect(() => {
		// if (formValues?.workPackageId && !options?.length)
		// onSetManual()
		if (
			actPoints?.length &&
			violations?.length &&
			isManual.actPoints === false &&
			isManual.description === false
		) {
			setIsManual({ actPoints: false, description: false })
			form.setFieldsValue({
				isTypical: true,
				[manualField]: undefined
			})
		} else if (
			actPoints?.length &&
			!violations?.length &&
			isManual.actPoints === false &&
			isManual.description === true
		) {
			setIsManual({ actPoints: false, description: true })
			form.setFieldsValue({
				isTypical: false,
				actPoints: undefined
			})
		} else if (
			!actPoints?.length &&
			violations?.length &&
			isManual.actPoints === true &&
			isManual.description === false
		) {
			setIsManual({ actPoints: true, description: false })
			form.setFieldsValue({
				isTypical: false,
				description: undefined
			})
		}
		if (actPoints?.length === 1) {
			form.setFieldValue('actPointId', actPoints[0].id)
		}
		if (violations?.length === 1) {
			form.setFieldValue('violationId', violations[0].id)
		}
		if (
			actPoints?.length! > 1 &&
			violations?.length! > 1 &&
			isManual.actPoints === false &&
			isManual.description === false
		) {
			if (typeof formValues?.issueTypeId !== 'undefined')
				form.setFieldsValue({
					isTypical: true
				})
		}
	}, [actPoints, violations])

	return canEdit ? (
		<Form.Item
			label={label}
			name={isManual[manualField] === true ? manualField : selectorField}
			rules={rules}
		>
			{isLoading ? (
				<Skeleton.Button block active />
			) : isManual[manualField] === false ? (
				<Select
					showSearch={true}
					optionFilterProp={'children'}
					filterOption={(input, option) =>
						(option?.name ?? '').toLowerCase().includes(input.toLowerCase())
					}
					filterSort={(optionA, optionB) =>
						(optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
					}
					loading={isLoading}
					disabled={disabled}
					options={options}
					fieldNames={{ label: 'name', value: 'id' }}
					allowClear={options?.length! > 1}
					variant="borderless"
					dropdownRender={menu => (
						<>
							{menu}
							<Divider style={{ margin: '8px 0' }} />
							<Button block onClick={() => onSetManual(manualField)}>
								Ручной ввод
							</Button>
						</>
					)}
				/>
			) : manualField === 'description' ? (
				<Input.TextArea
					allowClear
					variant="borderless"
					disabled={disabled}
					autoSize={{
						minRows: 4
					}}
				/>
			) : (
				<Input allowClear variant="borderless" disabled={disabled} />
			)}
		</Form.Item>
	) : (
		<Form.Item label={label}>
			<span className="app-form-text">
				{isManual[manualField] === true
					? manualDataSourceValue ?? '-'
					: selectorDataSourceValue ?? '-'}
			</span>
		</Form.Item>
	)
}
