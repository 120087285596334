import { CheckCircleTwoTone, SendOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Col,
	Drawer,
	Flex,
	Form,
	Input,
	Radio,
	Row,
	Skeleton,
	Space,
	Tag,
	Tooltip,
	Typography
} from 'antd'
import modal from 'antd/es/modal'
import { useAppSelector } from 'hooks/appReduxHook'
import { AsmRequestNci } from 'pages/unmodelPage/asmRequestNci'
import { ILocalEstimateContainer } from 'pages/unmodelPage/localEstimate/model/localEstimateInterfaces'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { IWbsContainer, useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { useWbsAuthorCommentStatus } from 'pages/unmodelPage/wbsContainerComments/model/wbsContainerComments-query'
import { FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CONTAINER_STATUS } from 'shared/constants'
import { useEstimateState } from 'widgets/estimate/model/estimate-state'
import { useEstimatePositionCommentsState } from '..'
import { EstimatePositionCommentsApi } from '../api/asmComments.api'
import {
	useEstimatePositionComments,
	useEstimatePositionCommentsUsers
} from '../model/estimate-position-comments-queries'
import { IWbsCommentDto } from '../model/estimate-position-comments-state'
import { EstimatePositionComments } from './EstimatePositionComments'

interface IProps {
	source?: 'position' | 'all'
	type?: 'wbs' | 'local'
}

const { Text } = Typography

export const EstimatePositionCommentsView: FC<IProps> = ({ source = 'position', type }) => {
	const queryClient = useQueryClient()
	const { show, currentId, sectionId } = useEstimatePositionCommentsState()
	const { setEstimatePositions } = useEstimateState()
	const {
		container: wbsContainer,
		expandedSectionsWithEstimatePositions,
		showContainerCommentsDrawer,
		updateSection
	} = useWbsContainerState()
	const {
		container: containerLocalEstimate,
		expandedSectionsWithEstimatePositions: expandedSectionsWithEstimatePositionsLocalEstimate,
		updateSection: updateSectionLocalEstimate
	} = useLocalEstimateState()
	const container: IWbsContainer | ILocalEstimateContainer | null =
		type === 'wbs' ? wbsContainer : containerLocalEstimate
	const { user } = useAppSelector(state => state.environment)
	const { pathname } = useLocation()
	const onClose = () => {
		useEstimatePositionCommentsState.setState({ show: false, currentId: null })
		asmCommentForm.resetFields()
	}
	const [showModal, setShowModal] = useState(false)
	const [asmCommentForm] = Form.useForm<IWbsCommentDto>()
	const appealTo = Form.useWatch('appealTo', asmCommentForm)
	const { data, isFetching } = useEstimatePositionComments()
	const { data: responsibleUsers, isFetching: isFetchingUsers } = useEstimatePositionCommentsUsers()
	const { data: wbsAuthorCommentStatus } = useWbsAuthorCommentStatus(currentId!)
	const [loading, setLoading] = useState(false)
	const sendComment = async (dto: IWbsCommentDto) => {
		setLoading(true)
		if (currentId && dto && dto.comment.trim()) {
			await EstimatePositionCommentsApi.addEPComments(currentId, dto).then(() => {
				queryClient.invalidateQueries({ queryKey: ['estimatePosition'] })
				queryClient.invalidateQueries({ queryKey: ['estimatePositionUsers'] })
				queryClient.invalidateQueries({ queryKey: ['wbsAuthorCommentStatus'] })
				setEstimatePositions(sectionId!)
				if (showContainerCommentsDrawer) {
					queryClient.invalidateQueries({ queryKey: ['wbsContainerComments'] })
				}
				if (source === 'all') {
					type === 'wbs'
						? expandedSectionsWithEstimatePositions.map(sectionId => updateSection(sectionId!))
						: expandedSectionsWithEstimatePositionsLocalEstimate.map(sectionId =>
								updateSectionLocalEstimate(sectionId!)
						  )
				} else {
					type === 'wbs' ? updateSection(sectionId!) : updateSectionLocalEstimate(sectionId!)
				}
			})
		}
		asmCommentForm.resetFields()
		setLoading(false)
	}

	const toggleChatState = async () => {
		if (currentId) {
			await EstimatePositionCommentsApi.toggleEPChatState(currentId).then(() => {
				queryClient.invalidateQueries({ queryKey: ['asmComments'] })
				queryClient.invalidateQueries({ queryKey: ['wbsAuthorCommentStatus'] })
				queryClient.invalidateQueries({ queryKey: ['wbsCommentsUsers'] })
				queryClient.invalidateQueries({ queryKey: ['estimatePositionUsers'] })
				queryClient.invalidateQueries({ queryKey: ['estimate-comments'] })
				setEstimatePositions(sectionId!)
				if (source === 'all') {
					type === 'wbs'
						? expandedSectionsWithEstimatePositions.map(sectionId => updateSection(sectionId!))
						: expandedSectionsWithEstimatePositionsLocalEstimate.map(sectionId =>
								updateSectionLocalEstimate(sectionId!)
						  )
				} else {
					type === 'wbs' ? updateSection(sectionId!) : updateSectionLocalEstimate(sectionId!)
				}
			})
			setShowModal(false)
		}
	}

	//TODO: Сделать независимый сколл для списка участников и блока сообщений
	//TODO: Вынести список участников в отдельный компонент

	return (
		<Form form={asmCommentForm} name="asmCommentForm" onFinish={values => sendComment(values)}>
			<Drawer
				destroyOnClose
				styles={{ body: { background: '#f9f9f9' } }}
				title={`Чат${data?.estimatePositionTitle ? ': ' + data?.estimatePositionTitle : ''}`}
				extra={
					<Row gutter={4}>
						<Col>
							{(pathname.includes('unmodel/') || pathname.includes('local/')) && (
								<Col>
									<AsmRequestNci id={currentId!} estimatePosition={data!?.estimatePositionTitle} />
								</Col>
							)}
						</Col>
						<Col>
							{wbsAuthorCommentStatus === 'withoutButton' ||
							(container?.status.name === 'Approved' && wbsAuthorCommentStatus) ? null : (
								<Button
									danger={!wbsAuthorCommentStatus}
									onClick={() => {
										if (showModal) return
										const state = wbsAuthorCommentStatus === true ? 'Открыть' : 'Закрыть'
										setShowModal(true)
										modal.confirm({
											title: `${state} чат`,
											content:
												state === 'Закрыть'
													? `Участники будут уведомлены, что надо закрыть чат. Если чат будет не закрыт, то Согласование ${
															type === 'wbs' ? 'ВОР' : 'Локальной сметы'
													  } будет невозможно`
													: `Вы точно хотите ${state.toLowerCase()} чат "${
															data?.estimatePositionTitle
													  }"?`,
											okText: state,
											onOk: toggleChatState,
											onCancel: () => {
												setShowModal(false)
											}
										})
									}}
								>
									{`${wbsAuthorCommentStatus ? 'Открыть' : 'Закрыть'} чат`}
								</Button>
							)}
						</Col>
					</Row>
				}
				width="60vw"
				open={show}
				onClose={onClose}
				footer={
					// container?.ready === true ? (
					// 	<Row style={{ padding: 20 }}>
					// 		<Text type="danger" style={{ fontSize: 16 }}>
					// 			{`${
					// 				type === 'wbs' ? 'ВОР закрыт' : 'Локальная смета закрыта'
					// 			} на редактирование, сообщения в чат писать нельзя`}
					// 		</Text>
					// 	</Row>
					// ) :
					!(
						container?.status.name === CONTAINER_STATUS.structureApproval ||
						container?.status.name === CONTAINER_STATUS.priceApproval ||
						container?.status.name === CONTAINER_STATUS.priceApprovalOuter
					) ? (
						<Row style={{ padding: 20 }}>
							<Text type="danger" style={{ fontSize: 16 }}>
								{container?.status.name === 'Approved'
									? container?.adjustmentId
										? 'Документ "Было/Стало" закрыт на редактирование, сообщения в чат писать нельзя'
										: `${
												type === 'wbs' ? 'ВОР закрыт' : 'Локальная смета закрыта'
										  } на редактирование, сообщения в чат писать нельзя`
									: container?.adjustmentId
									? `Чат откроется, когда документ "Было/Стало" перейдет в статус ${
											type === 'wbs' ? 'Оценка НДК' : 'Оценка подрядчика'
									  }`
									: `Чат откроется, когда ${
											type === 'wbs' ? 'ВОР' : 'Локальная смета'
									  } перейдет в статус "Согласование структуры"`}
							</Text>
						</Row>
					) : (
						<Row gutter={8} style={{ width: '100%' }}>
							<Col flex="1">
								<Form.Item
									name="comment"
									style={{ margin: 0 }}
									rules={[{ required: true, message: 'Введите текст сообщения' }]}
								>
									<Input.TextArea
										disabled={loading}
										placeholder="Введите текст комментария"
										autoSize={true}
										style={{ width: '100%', maxHeight: '10rem' }}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Tooltip title={typeof appealTo === 'undefined' && 'Укажите адресата сообщения'}>
									<Button
										loading={loading}
										disabled={typeof appealTo === 'undefined'}
										onClick={() => asmCommentForm.submit()}
										icon={<SendOutlined />}
									/>
								</Tooltip>
							</Col>
						</Row>
					)
				}
			>
				<Row gutter={8} wrap={false} style={{ height: '100%' }}>
					{
						<Col span={7} style={{ borderRight: '1px solid #ddd', padding: '0 .5rem' }}>
							<Form.Item name="appealTo">
								{isFetchingUsers ? (
									<Space direction="vertical" style={{ width: '100%' }}>
										<Skeleton.Button block size="large" active />
										<Skeleton.Button block size="large" active />
									</Space>
								) : (
									<Radio.Group optionType="button" buttonStyle="solid" style={{ width: '100%' }}>
										<Flex vertical gap={8} style={{ width: '100%' }}>
											{responsibleUsers
												// ?.filter(x => x.id !== user?.id)
												?.map(ru => (
													<Radio
														value={ru.id}
														disabled={ru.id === user?.id}
														style={{
															width: '100%',
															height: 'auto',
															padding: '.5rem',
															borderRadius: '4px'
														}}
													>
														<Row gutter={8} align="middle" wrap={false}>
															<Col style={{ flexGrow: 0, maxWidth: '32%' }}>
																<Row gutter={[4, 4]}>
																	{ru.roles
																		?.filter(r => r.prefix)
																		?.map(rp =>
																			rp !== undefined ? (
																				<Col>
																					<Tooltip title={rp.name} placement="top">
																						<Tag
																							style={{
																								margin: 0,
																								color: '#222',
																								border: `2px solid ${rp.color}`
																							}}
																						>
																							{rp.prefix}
																						</Tag>
																					</Tooltip>
																				</Col>
																			) : null
																		)}
																</Row>
															</Col>
															<Col flex={1}>{ru.fullName}</Col>
															{ru.closeComment && (
																<Col span={2}>
																	<CheckCircleTwoTone />
																</Col>
															)}
														</Row>
													</Radio>
												))}
										</Flex>
									</Radio.Group>
								)}
							</Form.Item>
						</Col>
					}
					<Col flex={1} style={{ paddingLeft: '1rem' }}>
						<EstimatePositionComments
							isLoading={isFetching}
							comments={data?.estimatePositionsComments! ?? []}
						/>
					</Col>
				</Row>
			</Drawer>
		</Form>
	)
}
