import { useMutation, useQuery } from "@tanstack/react-query"
import { wbsTaskVisasApi } from "./wbs-task-visas-api"

export const useWbsTaskVisasApprove = () => {
    return useMutation({
    mutationKey: ['wbsTaskVisas', 'approve'],
    mutationFn: async ({ wbsTaskId, visaId }: { wbsTaskId: string, visaId?: string }) =>
        await wbsTaskVisasApi.post(wbsTaskId, visaId)
})}

export const useWbsTaskVisas = (id?: string) => {
	return useQuery({
		queryKey: ['wbsTaskVisas', id],
		queryFn: async () => {
			if (id) {
				return await wbsTaskVisasApi.get(id).then(data => data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useCanVisaApprove = (id?: string) => {
	return useQuery({
		queryKey: ['canVisaApprove', id],
		queryFn: async () => {
			if (id) {
				return await wbsTaskVisasApi.getCanVisaApprove(id)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}