import {
	DeleteOutlined,
	DownloadOutlined,
	FileImageOutlined,
	FileJpgOutlined,
	FilePdfOutlined
} from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, message, notification, Row, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IIssueCardAttachment } from 'entities/issues/model/issue-card-schema'
import { ISSUE_STATUSES } from 'enums'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, Key, ReactNode, useMemo, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { formatBytes } from 'services/helperService'
import { deleteIssueAttachments } from 'services/IssuesService'
import { DATE_FORMAT } from 'shared/constants'
import { useIssueCardState } from '../model/issue-card-state'
import { ImageCarousel } from './ImageCarousel'
interface IProps {
	loading?: boolean
	issueId?: string
	statusId?: string
	ownerId?: string
	attachments: IIssueCardAttachment[]
	canEdit: boolean
}

const ImageIcons: Record<string, ReactNode> = {
	pdf: <FilePdfOutlined style={{ color: 'darkOrange', fontSize: 24 }} />,
	jpg: (
		<div className="issue-file-cursor">
			<FileJpgOutlined style={{ color: 'red', fontSize: 24 }} />
		</div>
	),
	png: (
		<div className="issue-file-cursor">
			<FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
		</div>
	)
}

export const IssueCardAttachmentList: FC<IProps> = ({
	// loading,
	statusId,
	ownerId,
	attachments,
	canEdit
}) => {
	const { issueId } = useIssueCardState()
	const queryClient = useQueryClient()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
	const [selectedAttachment, setSelectedAttachment] = useState<string | null>(null)
	const canDelete = (attachment: IIssueCardAttachment) => {
		return (
			currentUser!?.isAdmin! ||
			((statusId === ISSUE_STATUSES.open || statusId === ISSUE_STATUSES.ready_to_inspect) &&
				(currentUser?.id === attachment?.createdBy?.id || currentUser?.id === ownerId))
		)
	}

	const { mutate } = useMutation({
		mutationKey: ['issue', 'attachments'],
		mutationFn: async ({ issueId, items }: { issueId: string; items: Key[] }) =>
			await deleteIssueAttachments(issueId, items),
		onSuccess: () => {
			notification.success({
				message: selectedRowKeys.length > 1 ? 'Файлы успешно удалены' : 'Файл успешно удалён',
				placement: 'topLeft'
			})
			setSelectedRowKeys([])
			queryClient.invalidateQueries({ queryKey: ['issue', 'attachments'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка во время сохранения',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const onAttachmentsDelete = async (items: Key[]) => {
		if (issueId) {
			mutate({ issueId, items })
		}
	}

	const onDownload = (record: IIssueCardAttachment) => {
		downloadFile(record.id)
			.then(response => {
				const href = URL.createObjectURL(response.data)
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', record.name + record.extension!)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
			})
			.catch(() => message.error('Во время загрузки файла произошла ошибка'))
	}

	const attachmentColumns = useMemo<ColumnsType<IIssueCardAttachment>>(
		() => [
			{
				width: '5%',
				align: 'center',
				render: (_, record) => ImageIcons[record.extension.replace('.', '').toLowerCase()],
				onCell: record => ({
					onClick: event => {
						event.preventDefault()
						if (!record.extension.includes('pdf')) {
							setSelectedAttachment(record.id)
						}
					}
				})
			},
			{
				dataIndex: 'name',
				title: 'Файл'
			},
			{
				dataIndex: ['createdBy', 'name'],
				title: 'Автор',
				width: '16%'
			},
			{
				title: 'Дата загрузки',
				dataIndex: 'uploadDate',
				width: '12%',
				render: value => dayjs(value).format(DATE_FORMAT.viewWithHours)
			},
			{
				title: 'Размер файла',
				dataIndex: 'fileSize',
				width: '12%',
				render: value => formatBytes(value)
			},
			{
				title: selectedRowKeys.length > 0 && (
					<center>
						<Tooltip title="Удалить выбранное">
							<Button
								danger
								block
								type="primary"
								icon={<DeleteOutlined />}
								onClick={() => onAttachmentsDelete(selectedRowKeys)}
								disabled={!canEdit}
							/>
						</Tooltip>
					</center>
				),
				render: (_, record) => (
					<Row gutter={8} wrap={false} justify="center">
						<Col>
							<Tooltip placement="topLeft" title="Скачать">
								<Button
									shape="circle"
									size="middle"
									onClick={() => onDownload(record)}
									icon={<DownloadOutlined />}
								/>
							</Tooltip>
						</Col>
						{canDelete(record) && (
							<Col>
								<Tooltip placement="topLeft" title="Удалить">
									<Button
										shape="circle"
										size="middle"
										danger
										disabled={selectedRowKeys.length > 0 || !canEdit}
										onClick={() => onAttachmentsDelete([record.id])}
										icon={<DeleteOutlined />}
									/>
								</Tooltip>
							</Col>
						)}
					</Row>
				)
			}
		],
		[attachments, selectedRowKeys]
	)

	return (
		<>
			{attachments && (
				<ImageCarousel
					attachments={attachments.filter(a => !a.extension.includes('pdf'))}
					initialSlide={selectedAttachment}
					show={selectedAttachment !== null}
					onClose={() => setSelectedAttachment(null)}
				/>
			)}
			<Table
				// loading={{
				// 	indicator: <LoadingOutlined style={{ fontSize: 36 }} spin />,
				// 	spinning: loading
				// }}
				size="small"
				id="ant-table-cell-scroll"
				rowKey="id"
				pagination={false}
				dataSource={attachments!}
				columns={attachmentColumns}
				rowSelection={{
					selectedRowKeys,
					onChange: setSelectedRowKeys
				}}
			/>
		</>
	)
}
