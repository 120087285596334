import { EllipsisOutlined, SaveOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Col,
	Dropdown,
	Form,
	Input,
	Modal,
	notification,
	Radio,
	Row,
	Select,
	Space
} from 'antd'
import { MenuProps } from 'antd/lib'
import { uniq } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { mainSelectProps } from 'shared/helpers'
import { WbsTaskStatusApi } from '../api/wbs-task-status-api'
import { useWbsTaskRoles, useWbsTaskVisas } from '../model/wbs-task-status-queries'
import { useWbsTaskStatusState } from '../model/wbs-task-status-state'

interface IWbsAddForm {
	name?: string
	roles?: string[]
	visas?: string[]
}

export const WbsTaskVisaAdd: FC = () => {
	const { data: visas } = useWbsTaskVisas()
	const { data: roles } = useWbsTaskRoles()
	const { showAddVisaModal, addVisa, unsetVisaAdd } = useWbsTaskStatusState()
	const [wbsVisaAddForm] = Form.useForm<IWbsAddForm>()
	const queryClient = useQueryClient()
	const [addType, setAddType] = useState<'choose' | 'create'>('create')

	useEffect(() => {
		if (showAddVisaModal) {
			setAddType(!!visas?.length ? 'choose' : 'create')
		}
	}, [showAddVisaModal, visas])

	const onFormClose = (update: boolean = false) => {
		wbsVisaAddForm.resetFields()
		unsetVisaAdd()
		if (update) {
			queryClient.invalidateQueries({ queryKey: ['wbsTaskStatusGuide'] })
		}
		setAddType('choose')
	}

	const onWbsVisaAdd = async (close: boolean = false) => {
		const values = wbsVisaAddForm.getFieldsValue()
		const validate = await wbsVisaAddForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (validate) {
			if (addType === 'create') {
				const dto = {
					name: values.name!,
					rolesId: values.roles!
				}
				await WbsTaskStatusApi.addVisa(dto).then(async visa => {
					const currentVisas = addVisa?.children.flatMap(v => v.id)
					const dto = {
						name: addVisa?.name!,
						description: addVisa?.description!,
						color: addVisa?.color!,
						order: addVisa?.order!,
						visasIds: [...currentVisas!, visa] as any
					}
					await WbsTaskStatusApi.updateStatus(addVisa?.id!, dto).then(() => {
						queryClient.invalidateQueries({
							queryKey: ['wbsTaskStatusGuide']
						})
						queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
						wbsVisaAddForm.resetFields()
						notification.success({
							message: 'Виза создана и добавлена',
							placement: 'topLeft'
						})
						if (close) onFormClose()
					})
				})
			} else if (addType === 'choose') {
				const currentVisas = addVisa?.children.flatMap(v => v.id)
				const dto = {
					name: addVisa?.name!,
					description: addVisa?.description!,
					color: addVisa?.color!,
					order: addVisa?.order!,
					visasIds: uniq([...currentVisas!, ...values.visas!]) as any
				}
				await WbsTaskStatusApi.updateStatus(addVisa?.id!, dto).then(() => {
					queryClient.invalidateQueries({
						queryKey: ['wbsTaskStatusGuide']
					})
					queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
					wbsVisaAddForm.resetFields()
					notification.success({ message: 'Список виз успешно обновлён', placement: 'topLeft' })
					if (close) onFormClose()
				})
			}
		}
	}

	const items: MenuProps['items'] = [
		{
			label: 'Сохранить',
			key: 'save',
			icon: <SaveOutlined />,
			onClick: () => onWbsVisaAdd()
		},
		{
			label: 'Сохранить и закрыть',
			key: 'saveAndClose',
			icon: <SaveOutlined />,
			onClick: () => onWbsVisaAdd(true)
		}
	]

	return (
		<Modal
			centered={true}
			destroyOnClose={true}
			title="Добавить визу"
			open={showAddVisaModal}
			footer={
				<Row justify="end" gutter={8} wrap={false}>
					<Col>
						<Button onClick={() => onFormClose()}>Отмена</Button>
					</Col>
					<Col>
						<Dropdown.Button
							trigger={['click']}
							type="primary"
							icon={<EllipsisOutlined />}
							menu={{ items }}
							onClick={() => onWbsVisaAdd()}
						>
							Сохранить
						</Dropdown.Button>
					</Col>
				</Row>
			}
			onCancel={() => onFormClose()}
		>
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Radio.Group
					value={addType}
					onChange={e => setAddType(e.target.value)}
					options={[
						{ label: 'Выбрать', value: 'choose', disabled: visas?.length === 0 },
						{ label: 'Создать новую', value: 'create' }
					]}
					optionType="button"
					buttonStyle="solid"
				/>
				<Form name="wbsVisaAddForm" form={wbsVisaAddForm} labelCol={{ span: 6 }} labelAlign="left">
					{addType === 'create' ? (
						<>
							<Form.Item
								name="name"
								label="Наименование"
								rules={[
									{
										required: true,
										message: 'Укажите название'
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="roles"
								label="Роли"
								rules={[
									{
										required: true,
										message: 'Укажите хотя бы одну роль'
									}
								]}
							>
								<Select
									{...mainSelectProps}
									mode="multiple"
									options={roles?.map(r => ({
										key: r.id,
										value: r.id,
										label: r.name
									}))}
								/>
							</Form.Item>
						</>
					) : (
						<Form.Item
							name="visas"
							label="Визы"
							rules={[
								{
									required: true,
									message: 'Укажите хотя бы одну визу'
								}
							]}
						>
							<Select
								{...mainSelectProps}
								mode="multiple"
								options={visas?.map(v => ({
									key: v.id,
									value: v.id,
									label: v.name
								}))}
							/>
						</Form.Item>
					)}
				</Form>
			</Space>
		</Modal>
	)
}
