import { Col, Flex, FormInstance, Row, Tag } from 'antd'
import { IIssueCard } from 'entities/issues'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC } from 'react'
import { YandexTrackerButton } from 'widgets/yandex-tracker/ui/yandex-tracker-button'
import { IssueCardDetailsContractor } from './IssueCardDetailsContractor'
import { IssueCardDetailsMain } from './IssueCardDetailsMain'
import { IssueCardDetailsRelated } from './IssueCardDetailsRelated'
import { IssueCardDetailsViolation } from './IssueCardDetailsViolation'
import { IssueComments } from './IssueComments'

interface IProps {
	dataSource?: IIssueCard | null
	form: FormInstance<TIssueDetailForm>
	canEdit: boolean
	formValues: TIssueDetailForm
}

export const IssueCardDetails: FC<IProps> = ({ dataSource, form, canEdit, formValues }) => {
	return (
		<>
			<Row justify="space-between" style={{ marginBottom: 5 }}>
				<Col>
					{dataSource?.isMobile && (
						<Tag style={{ fontSize: '16px' }} color="blue">
							mobile
						</Tag>
					)}
				</Col>
				<Col>
					<YandexTrackerButton text={'Сообщить об ошибке'} />
				</Col>
			</Row>
			<Flex vertical gap={24}>
				<IssueCardDetailsMain dataSource={dataSource} canEdit={canEdit} form={form} />
				<IssueCardDetailsViolation
					dataSource={dataSource}
					canEdit={canEdit}
					formValues={formValues}
					form={form}
				/>
				<IssueCardDetailsContractor
					dataSource={dataSource}
					canEdit={canEdit}
					formValues={formValues}
					form={form}
				/>
				{dataSource?.id && <IssueCardDetailsRelated dataSource={dataSource} />}
				{dataSource?.comments && (
					<IssueComments dataSource={dataSource.comments} attachments={dataSource?.attachments!} />
				)}
			</Flex>
		</>
	)
}
