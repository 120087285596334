import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Table } from 'antd'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { ISectionIssue } from 'interfaces/IChecklist'
import { FC } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useIssueCardState } from 'widgets/issue-card'

interface IProps {
	issuesList?: ISectionIssue[]
	openIssueHandler: (issueId?: string) => void
	allDisabled: boolean
	// checkPermissions: (currentPermits: string[]) => boolean
}

const SectionIssues: FC<IProps> = ({
	issuesList,
	openIssueHandler,
	allDisabled
	// checkPermissions
}) => {
	const checkPermissions = useCheckPermissions()
	const { currentChecklist } = useAppSelector(state => state.checklists.init)
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const issuePerm = checkPermissions([PERMISSIONS.IssueView])

	const columns = [
		{
			key: 'number',
			dataIndex: 'number',
			title: '№',
			width: 70
		},
		{
			key: 'title',
			dataIndex: 'title',
			title: 'Название',
			width: 200,
			ellipsis: true,
			render: (_: any, record: any) =>
				record?.title ? (
					<Button
						type="link"
						data-attr="issueLink"
						onClick={() => {
							if (issuePerm) {
								openIssueHandler(record.id)
								useIssueCardState.setState({ showCard: true })
								useIssueCardState.setState({ issueId: record.id })
							} else {
								message.error(`У вас нет прав на просмотр замечания`)
							}
						}}
					>
						{record.title}
					</Button>
				) : (
					'Нет данных'
				)
		},
		{
			key: 'status',
			dataIndex: 'issueStatus',
			title: 'Статус',
			width: 100,
			render: (_: any, record: ISectionIssue) => <Row>{record.issueStatus.name}</Row>
		},
		{
			key: 'dueDate',
			dataIndex: 'dueDate',
			title: 'Срок',
			width: 100,
			render: (_: any, record: any) => (
				<Row>
					<Col>{record.dueDate ? dayjs(record.dueDate).format('DD.MM.YYYY') : 'Нет данных'}</Col>
				</Row>
			)
		}
	]

	return (
		<>
			<Table
				columns={columns}
				dataSource={issuesList}
				pagination={false}
				bordered
				className="section-issues-table"
			/>
			<Button
				type="primary"
				onClick={() => {
					openIssueHandler()
					useIssueCardState.setState({ showCard: true })
				}}
				style={{ width: '100%', marginTop: 16 }}
				icon={<PlusOutlined />}
				disabled={
					allDisabled || !currentChecklist?.assignedTo?.find(elem => elem.id === currentUser?.id)
				}
			>
				Добавить замечание
			</Button>
		</>
	)
}

export default SectionIssues
