import { Flex, FormInstance } from 'antd'
import { UploadFile } from 'antd/lib'
import { TIssueDetailForm } from 'entities/issues/model/issue-card-schema'
import { FC } from 'react'
import { ISSUE_STATUS } from 'shared/constants/issue-types'
import { useIssueAttachments } from '../model/issue-card-query'
import { IssueCardAttachmentList } from './IssueCardAttachmentList'
import { IssueCardAttachmentsUploader } from './IssueCardAttachmentsUploader'

interface IProps {
	form: FormInstance<TIssueDetailForm>
	formValues: TIssueDetailForm
	setFilesToUpload: (x: UploadFile[]) => void
	filesToUpload: UploadFile[]
	canEdit: boolean
}

export const IssueCardAttachments: FC<IProps> = ({
	form,
	formValues,
	filesToUpload,
	canEdit,
	setFilesToUpload
}) => {
	const { data: attachments, isFetching } = useIssueAttachments()

	return (
		<Flex vertical>
			<IssueCardAttachmentsUploader
				attachments={attachments ?? []}
				form={form}
				setFilesToUpload={setFilesToUpload}
				filesToUpload={filesToUpload}
				canEdit={canEdit}
			/>
			{!!attachments?.length && (
				<IssueCardAttachmentList
					// loading={isFetching}
					attachments={attachments}
					statusId={formValues.issueStatusId ?? ISSUE_STATUS.open}
					ownerId={formValues.ownerId}
					canEdit={canEdit}
				/>
			)}
		</Flex>
	)
}
