import { CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons'
import { Button, ButtonProps, message } from 'antd'
import { ICurrentChecklist } from 'interfaces/IChecklist'
import { FC } from 'react'
import { createAct } from 'services/ChecklistsService'
import { CHECKLIST_STATUS } from '../ChecklistDrawer/ChecklistForm'

interface ISignBlockProps {
	currentChecklist: ICurrentChecklist | null
	typeId: number
	onClick: (show: boolean) => void
	setOpenPreview: (openPreview: boolean) => void
	onReloadChecklist: () => void
}

export const SignBlockCheckList: FC<ISignBlockProps> = ({
	currentChecklist,
	typeId,
	onClick,
	setOpenPreview,
	onReloadChecklist
}) => {
	const statusId = currentChecklist
		? [...currentChecklist!?.attachments!]!.find(file => file!?.typeId! === typeId!)?.statusId! ||
		  null
		: null

	const signProps: ButtonProps = {
		type: 'default',
		style: { cursor: 'pointer', width: 120 },
		icon: <EditOutlined />,
		onClick: () => onClick(true),
		disabled:
			currentChecklist?.status !== CHECKLIST_STATUS.completed &&
			currentChecklist?.status !== CHECKLIST_STATUS.accepted,
		children: 'Подписать',
		ghost: false
	}

	switch (statusId) {
		case null:
			signProps.ghost = false
			signProps.style = {
				cursor: !(currentChecklist?.status === 2 || currentChecklist?.status === 4)
					? 'default'
					: 'pointer',
				width: 170
			}
			signProps.icon = null
			signProps.children = 'Сформировать акт'
			signProps.disabled = !(currentChecklist?.status === 2 || currentChecklist?.status === 4)
			signProps.onClick = () =>
				// setOpenPreview(true)
				createAct(currentChecklist?.id!)
					.then(() => onReloadChecklist())
					.catch(() => message.error('Во время формирования акта произошла ошибка'))
			break
		case 3:
			signProps.ghost = true
			signProps.style = { cursor: 'default', width: 170 }
			signProps.icon = <ClockCircleOutlined style={{ color: 'brown' }} />
			signProps.children = 'Проверка подписи'
			signProps.disabled = true
			break
		case 4:
			signProps.ghost = true
			signProps.style = {
				cursor: 'default',
				width: 120
			}
			signProps.icon = <CheckOutlined style={{ color: 'green' }} />
			signProps.children = 'Подписано'
			signProps.disabled = true
			break
		default:
			break
	}

	return <Button {...signProps} />
}
