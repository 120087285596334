import { LinkOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Descriptions, Divider, Form, Radio, Row, Tooltip, message } from 'antd'
import { FormInstance } from 'antd/lib'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IChecklistSection, ICurrentChecklistItem } from 'interfaces/IChecklist'
import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getChecklist, updateChecklistItem } from 'services/ChecklistsService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setCurrentChecklist, setCurrentItem } from 'store/checklistsSlice'
import SectionAttachments from './SectionAttachments'
import { SectionDocumentationModal } from './SectionDocumentationModal'

const pointPermissionList = ['ChecklistsProcessingUksNdc', 'ChecklistsProcessingUkKc']
interface IProps {
	section: IChecklistSection
	index: number
	onUploadClickHandler: (e: React.MouseEvent<HTMLElement>, id: string | null | undefined) => void
	setIssueModalVisible: (value: boolean) => void
	onReloadChecklist: () => void
	// checkPermissions: (currentPermits: string[]) => boolean
	form: FormInstance<any>
}

const Section: FC<IProps> = ({
	section,
	index,
	onUploadClickHandler,
	setIssueModalVisible,
	onReloadChecklist,
	// checkPermissions,
	form
}) => {
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const { currentChecklist, currentItem, currentIssueId } = useAppSelector(
		state => state.checklists.init
	)
	const [allDisabled, setAllDisabled] = useState<boolean>(false)
	const [fileDisabled, setFileDisabled] = useState<boolean>(false)
	const [issueDisabled, setIssueDisabled] = useState<boolean>(false)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [item, setItem] = useState<ICurrentChecklistItem | null>(null)
	const currentItems = currentChecklist!?.sections[index].items
	const isCurrentUserAuthor = currentUser?.id! === currentChecklist!?.owner!?.id!
	const isAdmin = permissions!?.some(perm => perm.name === 'ProjectAdmin') || false
	const isCreated = currentChecklist?.id === '00000000-0000-0000-0000-000000000000'

	const onCheckAccessToAdd = (type: string) => {
		if (isAdmin) return true
		if (type === 'file') {
			if (currentChecklist?.assignedTo === null) {
				message.error('Ошибка! Ответственный не найден!')
			}
			if (
				currentChecklist?.assignedTo.some(user => user.id === currentUser?.id) ||
				currentUser?.id === currentChecklist?.owner?.id
			) {
				if (
					(currentChecklist!?.templateType === 0 &&
						checkPermissions([PERMISSIONS.AttachFilesForChecklistUksNdc])) ||
					(currentChecklist!?.templateType === 1 &&
						checkPermissions([PERMISSIONS.AttachFilesForChecklistUkKc]))
				) {
					return true
				}
			}
		} else if (type === 'issue') {
			// if (currentChecklist?.assignedTo === null) {
			// 	message.error('Ошибка! Ответственный не найден!')
			// }
			return true
		}
		return false
	}
	const onCheckAccessToPoints = (item: ICurrentChecklistItem, answer: null | number) => {
		if (currentChecklist?.assignedTo === null) {
			message.error('Ошибка! Ответственный не найден!')
		}
		if (
			currentUser?.isAdmin === false &&
			!currentChecklist?.assignedTo.some(user => user.id === currentUser?.id)
		) {
			message.error('Ошибка! Вы не являетесь ответственным по этому чеклисту!')
			return false
		}
		if (answer === 0) {
			return true
		}
		if (answer === 1) {
			if (item?.checkboxFile && item.attachments.length === 0) {
				message.error(`Прикрепите файл!`)
				return false
			} else return true
		}
		if (answer === 2) {
			if (item?.checkboxIssue && item.issues.length === 0) {
				message.error(`Создайте замечание!`)
				return false
			} else return true
		}
	}
	const onItemUpdate = async (
		item: ICurrentChecklistItem,
		answer: number | null,
		issueIndex?: number
	) => {
		if (!currentItem) {
			dispatch(setCurrentItem(section.items[index]))
		}
		const issueId = issueIndex ? item.issues[issueIndex].id : null
		await updateChecklistItem(item.id, answer, issueId)
			.then(() => queryClient.invalidateQueries(['checkListList']))
			.catch((error: AxiosError<IErrorDetail>) => message.error(error.response?.data?.detail!))
		if (currentChecklist!?.id) {
			await getChecklist(currentChecklist!?.id).then(res => {
				dispatch(
					setCurrentChecklist({
						...res,
						period: dayjs(res.period)
					})
				)
				form.setFieldValue(
					'factDate',
					dayjs(res?.factDate!).isValid() ? dayjs(res?.factDate!) : undefined
				)
			})
		}
	}
	const getItemColor = (item: ICurrentChecklistItem, type: string) => {
		if (type === 'file') {
			if (!item.attachments.length && item.checkboxFile) {
				return '#ff4d4f'
			} else {
				return '#52c41a'
			}
		} else if (type === 'issue') {
			if (item.issues.length === 0 && item.checkboxIssue) {
				return '#ff4d4f'
			} else {
				return '#52c41a'
			}
		}
		return '#52c41a'
	}
	const onAddIssue = (item?: ICurrentChecklistItem, action?: 'add' | 'show') => {
		if (currentUser?.isAdmin || onCheckAccessToAdd('issue')) {
			item && dispatch(setCurrentItem(item))
			setIssueModalVisible(true)
			// getIssueById(currentIssueId)
			// 	.then(() => {
			// 		item && dispatch(setCurrentItem(item))
			// 		setIssueModalVisible(true)
			// 	})
			// 	.catch(() => {
			// 		message.error('Замечание не принадлежит вашей компании')
			// 		setIssueModalVisible(false)
			// 	})
		} else {
			message.error('У вас нет прав на просмотр замечания')
		}
	}
	const onAddFile = (e: any, itemId: string) => {
		const canAdd =
			currentUser?.isAdmin ||
			(allDisabled && currentUser?.id === currentChecklist?.owner?.id) ||
			(!allDisabled && onCheckAccessToAdd('file'))
		if (canAdd) {
			onUploadClickHandler(e, itemId)
		} else {
			message.error('У вас нет прав на добавление файла')
		}
	}

	useEffect(() => {
		const type = currentChecklist?.templateType
		const responsible = currentChecklist?.assignedTo?.find(r => r.id === currentUser?.id)
		const author = currentChecklist?.owner?.id === currentUser?.id
		const isProjectCorrect =
			(type === 0 && checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc]) && responsible) ||
			(type === 1 && checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc]) && responsible)

		const canAddFile =
			(type === 0 &&
				((checkPermissions([PERMISSIONS.AttachFilesForChecklistUksNdc]) && responsible) ||
					(checkPermissions([PERMISSIONS.AttachFilesForChecklistUksNdc]) && author))) ||
			(type === 1 &&
				((checkPermissions([PERMISSIONS.AttachFilesForChecklistUkKc]) && responsible) ||
					(checkPermissions([PERMISSIONS.AttachFilesForChecklistUkKc]) && author)))

		const canAddIssue =
			(type === 0 &&
				checkPermissions([PERMISSIONS.ChecklistsProcessingUksNdc, PERMISSIONS.IssueCreate], true) &&
				responsible) ||
			(type === 1 &&
				checkPermissions([PERMISSIONS.ChecklistsProcessingUkKc, PERMISSIONS.IssueCreate], true) &&
				responsible)

		if (
			currentChecklist!?.status === 2 ||
			currentChecklist!?.status === 3 ||
			currentChecklist!?.status === 4 ||
			!isProjectCorrect ||
			isCreated
		) {
			setAllDisabled(true)
		} else {
			setAllDisabled(false)
		}

		if (
			currentChecklist!?.status === 2 ||
			currentChecklist!?.status === 3 ||
			currentChecklist!?.status === 4 ||
			!canAddFile ||
			isCreated
		) {
			setFileDisabled(true)
		} else {
			setFileDisabled(false)
		}

		if (
			currentChecklist!?.status === 2 ||
			currentChecklist!?.status === 3 ||
			currentChecklist!?.status === 4 ||
			!canAddIssue ||
			isCreated
		) {
			setIssueDisabled(true)
		} else {
			setIssueDisabled(false)
		}
	}, [currentChecklist!?.status])

	return (
		<>
			<SectionDocumentationModal
				openModal={openModal}
				onCancel={() => setOpenModal(false)}
				item={item}
				onReloadChecklist={onReloadChecklist}
			/>
			{section?.items.map((item: ICurrentChecklistItem, index: number) => {
				return (
					<Card
						className="at-workcard"
						style={{ marginBottom: '8px' }}
						key={item.id}
						data-attr={item.id}
						data-checklistItem={index + 1}
					>
						<Form.Item style={{ width: '100%', marginBottom: 0 }}>
							<Descriptions
								className="at-descriptions"
								column={12}
								bordered
								style={{ marginBottom: '16px' }}
								labelStyle={{
									fontWeight: 'bold',
									width: '20%',
									alignItems: 'center',
									padding: '2px 8px'
								}}
								contentStyle={{
									width: '80%',
									padding: '0px 8px'
								}}
							>
								<Descriptions.Item
									label={
										<label>
											{'Пункт № ' + (index + 1)}
											<label style={{ color: 'red' }}>*</label>
										</label>
									}
									span={12}
								>
									{item.name}
								</Descriptions.Item>

								<Descriptions.Item label="Описание пункта" span={12}>
									{item.description}
								</Descriptions.Item>
								<Descriptions.Item label="Ссылка на документацию" span={12}>
									{!isCurrentUserAuthor && !currentUser?.isAdmin ? (
										<p style={{ padding: '4px', margin: 0 }}>{item.documentation}</p>
									) : (
										<Row justify="space-between" align="middle" wrap={false}>
											<Col
												style={{
													whiteSpace: 'nowrap',
													maxWidth: '500px',
													overflow: 'hidden',
													textOverflow: 'ellipsis'
												}}
											>
												<NavLink to={`${item.documentation}`} target="_blank">
													{item.documentation ? item.documentation : ''}
												</NavLink>
											</Col>
											{!isCreated && (
												<Col>
													<Tooltip title="Редактировать">
														<Button
															icon={<LinkOutlined />}
															onClick={() => {
																setOpenModal(true)!
																setItem(item)
															}}
														/>
													</Tooltip>
												</Col>
											)}
										</Row>
									)}
								</Descriptions.Item>
							</Descriptions>

							<Descriptions column={24}>
								<Descriptions.Item span={24} style={{ verticalAlign: 'middle' }}>
									<Radio.Group
										onChange={e => {
											if (onCheckAccessToPoints(item, e.target.value)) {
												onItemUpdate(item, e.target.value)
											}
										}}
										value={currentItems && currentItems[index].answer}
										disabled={isCurrentUserAuthor}
									>
										<Radio
											data-answer="YES"
											value={1}
											disabled={allDisabled}
											onClick={(e: any) => {
												if (+e.target.value === item.answer) onItemUpdate(item, null)
											}}
										>
											Принято
										</Radio>
										<Radio
											data-answer="NO"
											value={2}
											disabled={allDisabled}
											onClick={(e: any) => {
												if (+e.target.value === item.answer) onItemUpdate(item, null)
											}}
										>
											Не принято
										</Radio>
										<Radio
											data-answer="NA"
											value={0}
											disabled={allDisabled}
											onClick={(e: any) => {
												if (+e.target.value === item.answer) onItemUpdate(item, null)
											}}
										>
											Не требуется
										</Radio>
									</Radio.Group>
								</Descriptions.Item>
							</Descriptions>
						</Form.Item>

						<Divider style={{ margin: 0 }} />
						<Col className="checklist-attachments-area-wrapper">
							<SectionAttachments
								item={item}
								onItemUpdate={onItemUpdate}
								onAddIssue={onAddIssue}
								onAddFile={onAddFile}
								allDisabled={allDisabled}
								issueDisabled={issueDisabled}
								fileDisabled={fileDisabled}
								getItemColor={getItemColor}
								// checkPermissions={checkPermissions}
							/>
						</Col>
						<Divider style={{ margin: '16px 0' }} />
					</Card>
				)
			})}
		</>
	)
}

export default Section
