import { create } from "zustand"
import { IWbsTaskStatusRegistry } from "./wbs-task-status-interface"

interface IWbsTaskStatusState {
	showAddVisaModal: boolean
	addVisa: IWbsTaskStatusRegistry | null
	currentPage: number
	totalItems?: number
    editing: string | null
    setEditing: (editing: string | null) => void
	setVisaAdd: (status: IWbsTaskStatusRegistry) => void
	unsetVisaAdd: () => void
}
export const useWbsTaskStatusState = create<IWbsTaskStatusState>((set, get) => ({
	showAddVisaModal: false,
	addVisa: null,
	currentPage: 1,
    editing: null,
    setEditing: editing => set(() => ({ editing })),
	setVisaAdd: status =>
		set(() => ({
			showAddVisaModal: true,
			addVisa: status
		})),
	unsetVisaAdd: () =>
		set(() => ({
			showAddVisaModal: false,
			addVisa: null
		}))
}))