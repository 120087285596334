import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Drawer, Form, message, Modal, notification, Tabs, TabsProps } from 'antd'
import modal from 'antd/es/modal'
import { RcFile } from 'antd/es/upload'
import { UploadFile } from 'antd/lib'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { TIssueDetailForm, TIssueDto } from 'entities/issues/model/issue-card-schema'
import { IssueCardDetails } from 'features/issues'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { isEqual, omit } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAppUserPermissions } from 'services/AuthService'
import {
	addIssueAttachments,
	getContractors,
	getContractsByProject1CId,
	getIssueById,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { DATE_FORMAT, PERMISSIONS } from 'shared/constants'
import { ISSUE_STATUS } from 'shared/constants/issue-types'
import { removeEmpty } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import History from 'UI/history'
import { useShallow } from 'zustand/react/shallow'
import { issueCardApi, useIssueCard, useIssueCardState } from '..'
import { IssueCardAttachments } from './IssueCardAttachments'
import { IssueCardChangeStatusModal } from './IssueCardChangeStatusModal'
import { IssueCardHeader } from './IssueCardHeader'
export enum ISSUE_CARD_SOURCE {
	checklist,
	main,
	ordinance,
	light
}

interface IProps {
	source: ISSUE_CARD_SOURCE
	onClose?: (x?: boolean, mustUpdate?: boolean, issueId?: string) => void
	updateList?: () => void
	checklistData?: IReception
}

interface IReception {
	contractorId?: string
	subContractorId?: string
	templateTitle?: string
	workPackageId?: string
	locationDescription?: string
	issueTypeId?: string
	issueSubTypeId?: string
	violationTypeId?: string
}

export const IssueCard: FC<IProps> = ({
	source = ISSUE_CARD_SOURCE.main,
	onClose,
	updateList,
	checklistData
}) => {
	const { project: currentProject, user } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()

	const { id: issueIdParams } = useParams()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([])
	const { data, isSuccess, isFetching } = useIssueCard()
	const [issueCardForm] = Form.useForm<TIssueDetailForm>()
	const formValues = Form.useWatch(values => values, issueCardForm)
	const { showCard, issueId, initForm, openCard } = useIssueCardState(
		useShallow(state => ({
			showCard: state.showCard,
			issueId: state.issueId,
			initForm: state.initForm,
			openCard: state.openCard
		}))
	)
	const { setInitForm, unsetIssue } = useIssueCardState()
	const checkPermissions = useCheckPermissions()
	const canEdit =
		checkPermissions([PERMISSIONS.IssueCreate, PERMISSIONS.IssueEdit]) &&
		(data?.issueStatus?.id === ISSUE_STATUS.open ||
			data?.issueStatus?.id === ISSUE_STATUS.ready ||
			issueId === null ||
			user?.isAdmin)

	// TODO Начало - Пересмотреть проверки по переходу по ссылке (везде, где используется!)

	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])

	useEffect(() => {
		const initIssue = async () => {
			if (issueId && pathname.includes('issues/')) {
				await getIssueById(issueId)
					.then(dto => {
						if (
							(!allCompanies &&
								(dto ? dto.contractor.id : initForm?.contractorId) !== user?.contractorId &&
								(dto ? dto.subcontractor.id : initForm?.subcontractorId) !== user?.contractorId) ||
							!user?.projects?.find(p => p.id === dto.projectId)
						) {
							unsetIssue()
							Modal.warning({
								title: 'Внимание',
								content:
									'Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
								onOk: () => {
									navigate('/')
									unsetIssue()
								}
							})
						} else {
							openCard()
						}
					})
					.catch(() => {
						unsetIssue()
						Modal.warning({
							title: 'Внимание',
							content:
								'Замечание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
							onOk: () => navigate('/')
						})
					})
			}
		}
		initIssue()
	}, [issueId])

	useEffect(() => {
		if(!data) {
			issueCardForm.setFieldValue('ownerId', user?.id)
		}
	}, [showCard])

	useEffect(() => {
		const initIssue = async (id?: string) => {
			if (id) {
				const dto = await getIssueById(id)
				const userProject = user?.projects?.find(p => p.id === dto.projectId)
				if (currentProject.id !== dto.projectId && userProject) {
					await getAppUserPermissions(userProject.id).then(data => {
						dispatch(setEnvironmentPermissions(data))
					})
					dispatch(
						setEnvironmentProject({
							option: 'project',
							data: userProject!
						})
					)
					await getContractors().then(data => {
						dispatch(setEnvironment({ state: 'contractors', data }))
					})
					await getIssueTypes()
						.then(data => {
							dispatch(setEnvironment({ state: 'issueTypes', data }))
						})
						.catch((error: AxiosError) => {
							const { detail } = error!?.response!?.data! as IErrorData
							const { url } = error!?.config!
							message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
							return Promise.reject
						})
					await getIssueSubTypes()
						.then(data => {
							dispatch(setEnvironment({ state: 'issueSubTypes', data }))
						})
						.catch((error: AxiosError) => {
							const { detail } = error!?.response!?.data! as IErrorData
							const { url } = error!?.config!
							message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
							return Promise.reject
						})
					await getContractsByProject1CId(userProject!?.project1C?.id!).then(data => {
						dispatch(setEnvironment({ state: 'contracts', data }))
					})
					await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
						dispatch(setEnvironment({ state: 'buildingPermits', data }))
					})
				}
				// if (dto && user?.projects?.find(p => p.id === dto.projectId)) {
				// 	// setIssueOrdinance(dto.ordinance ?? null)
				// 	// setDto(dto)
				// 	dispatch(setDto(dto))
				// }
			}
		}
		if (((issueIdParams || issueId) && pathname.includes('issues/')) || issueId) {
			const currentId = issueId ?? issueIdParams
			initIssue(currentId)
		}
		// else {
		// 	dispatch(resetDto({ statusId: ISSUE_STATUS!?.open!, projectId: currentProject!?.id! }))
		// }
	}, [issueIdParams, issueId])

	// TODO Конец

	useEffect(() => {
		if (isSuccess && data) {
			const init: TIssueDetailForm = {
				actPointId: data?.actPoint?.id,
				actPoints: data?.actPoints ?? undefined,
				building: data?.building ?? currentProject.building ?? '',
				buildingPermitId: data?.buildingPermit?.id ?? undefined,
				constructionObjectId: data?.constructionObject?.id ?? undefined,
				contactPersonId: data?.contactPerson?.id ?? undefined,
				contractId: data?.contract?.id ?? undefined,
				contractorId: data?.contractor?.id ?? checklistData?.contractorId ?? undefined,
				createdAt:
					data?.createdAt && dayjs(data?.createdAt).isValid() ? dayjs(data.createdAt) : null,
				description: data?.description ?? undefined,
				dueDate: data?.dueDate && dayjs(data?.dueDate).isValid() ? dayjs(data.dueDate) : null,
				issueStatusId: data?.issueStatus?.id ?? ISSUE_STATUS.open,
				issueSubTypeId: data?.issueSubType?.id ?? checklistData?.issueSubTypeId ?? undefined,
				issueTypeId: data?.issueType?.id ?? checklistData?.issueTypeId ?? undefined,
				isTypical: data?.isTypical ?? undefined,
				locationDescription:
					data?.locationDescription ?? checklistData?.locationDescription ?? undefined,
				ownerId: data?.owner.id ?? user?.id ?? '',
				phase: data?.phase ?? currentProject.phase ?? '',
				responsibleManagerId: data?.responsibleManager?.id ?? currentProject.responsibleManager?.id,
				subcontractorId: data?.subcontractor?.id ?? checklistData?.subContractorId ?? undefined,
				systemId: data?.system?.id ?? undefined,
				title: data?.title ?? undefined,
				violationId: data?.violation?.id ?? undefined,
				violationTypeId: data?.violationType?.id ?? checklistData?.violationTypeId ?? undefined,
				workPackageId: data?.workPackage?.id ?? checklistData?.workPackageId ?? undefined,
				reasonDescription: data?.cancellationReason?.reasonDescription ?? undefined
			}
			setInitForm(init)
			issueCardForm.setFieldsValue(init)
		}
	}, [data])



	useEffect(() => {
		if (showCard && source === ISSUE_CARD_SOURCE.checklist && !issueId) {
			const init: TIssueDetailForm = {
				actPointId: undefined,
				actPoints: undefined,
				building: currentProject.building ?? '',
				buildingPermitId: undefined,
				constructionObjectId: undefined,
				contactPersonId: undefined,
				contractId: undefined,
				contractorId: checklistData?.contractorId ?? undefined,
				createdAt: null,
				description: undefined,
				dueDate: null,
				issueStatusId: ISSUE_STATUS.open,
				issueSubTypeId: checklistData?.issueSubTypeId ?? undefined,
				issueTypeId: checklistData?.issueTypeId ?? undefined,
				isTypical: checklistData?.workPackageId ? true : undefined,
				locationDescription: checklistData?.locationDescription ?? undefined,
				ownerId: user?.id ?? '',
				phase: currentProject.phase ?? '',
				responsibleManagerId: currentProject.responsibleManager?.id,
				subcontractorId: checklistData?.subContractorId ?? undefined,
				systemId: undefined,
				title: undefined,
				violationId: undefined,
				violationTypeId: checklistData?.violationTypeId ?? undefined,
				workPackageId: checklistData?.workPackageId ?? undefined,
				reasonDescription: undefined
			}
			setInitForm(init)
			issueCardForm.setFieldsValue(init)
		}
	}, [showCard])

	const onChangeHandler = (value: object) => {
		switch (Object.keys(value)[0]) {
			case 'workPackageId':
				issueCardForm.setFieldsValue({
					isTypical: true,
					issueTypeId: undefined,
					issueSubTypeId: undefined,
					violationTypeId: undefined,
					title: undefined,
					actPointId: undefined,
					actPoints: undefined,
					violationId: undefined,
					description: undefined
				})
				break

			case 'issueTypeId':
				issueCardForm.setFieldsValue({
					issueSubTypeId: undefined,
					violationTypeId: undefined,
					title: undefined,
					actPointId: undefined
				})
				break

			case 'issueSubTypeId':
				issueCardForm.setFieldsValue({
					violationTypeId: undefined,
					title: undefined,
					actPointId: undefined
				})
				break

			case 'violationTypeId':
				issueCardForm.setFieldsValue({
					violationId: undefined,
					description: undefined,
					actPointId: undefined,
					actPoints: undefined
				})
				break

			case 'actPointId':
				issueCardForm.setFieldsValue({
					actPoints: undefined,
					// description: undefined,
					violationId: undefined
				})
				break

			case 'contractorId':
				issueCardForm.setFieldsValue({
					subcontractorId: undefined,
					contractId: undefined,
					contactPersonId: undefined
				})
				break

			case 'contractId':
				issueCardForm.setFieldsValue({
					contactPersonId: undefined
				})
				break
		}
	}

	const tabItems: TabsProps['items'] = [
		{
			key: 'details',
			label: 'Детали',
			children: (
				<IssueCardDetails
					dataSource={data}
					form={issueCardForm}
					canEdit={canEdit!}
					formValues={formValues}
				/>
			)
		},
		{
			key: 'attachments',
			label: 'Файлы',
			children: (
				<IssueCardAttachments
					formValues={formValues}
					form={issueCardForm}
					setFilesToUpload={setFilesToUpload}
					filesToUpload={filesToUpload}
					canEdit={canEdit!}
				/>
			)
		},
		{
			label: 'История',
			key: 'history',
			disabled: !checkPermissions([PERMISSIONS.IssueView]) || issueId === null,
			children: issueId && <History id={issueId} target={'issue'} />
		}
	]

	const onCloseHandler = () => {
		const testForm = removeEmpty(initForm)
		const valuesForm = removeEmpty(formValues)
		if (!isEqual(valuesForm, testForm)) {
			modal.confirm({
				title: 'Внимание',
				content: 'Есть не сохранённые данные',
				onOk: () => onCardClose(issueId!)
			})
		} else onCardClose()
	}

	const onCardClose = (id?: string) => {
		issueCardForm.resetFields()
		unsetIssue()
		setFilesToUpload([])
		onClose && onClose(true, false, id!)
	}

	const { mutate, isLoading } = useMutation({
		mutationKey: ['issue', 'save'],
		mutationFn: async (dto: TIssueDto) => await issueCardApi.save(dto, issueId ? 'patch' : 'post'),
		onSuccess: close => {
			notification.success({
				message: 'Замечание успешно сохранено',
				placement: 'topLeft'
			})
			addAttachments(close?.id ?? issueId)
			queryClient.invalidateQueries(['issuesList'])
			onCardClose(issueId ?? close.id)
			if (source === ISSUE_CARD_SOURCE.ordinance || source === ISSUE_CARD_SOURCE.checklist) {
				updateList!()
			}
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка во время сохранения',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const { mutate: mutateAttachments, isLoading: isLoadingAttachments } = useMutation({
		mutationKey: ['issue', 'attachments'],
		mutationFn: async ({
			issueId,
			fmData,
			config
		}: {
			issueId: string
			fmData: FormData
			config: any
		}) => await addIssueAttachments(issueId!, fmData, config),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['issue', 'attachments'] })
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const addAttachments = (issueId: string) => {
		if (
			filesToUpload.length > 0 &&
			issueId &&
			checkPermissions([PERMISSIONS.AttachFilesForIssue])
		) {
			const config = {
				headers: { 'content-type': 'multipart/form-data' }
			}
			const fmData = new FormData()
			filesToUpload?.map(file => fmData.append('files', file.originFileObj as RcFile))
			mutateAttachments({ issueId, fmData, config })
		}
	}

	const onSave = (values: TIssueDetailForm, close?: boolean) => {
		const { createdAt, ...prepDto } = values
		const dto: TIssueDto = {
			...prepDto,
			id: issueId,
			dueDate: dayjs(values.dueDate).isValid()
				? dayjs(values.dueDate).format(DATE_FORMAT.dto)
				: null,
			projectId: data?.projectId ?? currentProject.id,
			responsibleManagerId: data?.responsibleManager?.id ?? currentProject.responsibleManager?.id,
			issueStatusId: issueId ? prepDto?.issueStatusId : ISSUE_STATUS.open
		}
		const initialValues = removeEmpty(initForm)
		const currentValues = removeEmpty(formValues)

		const initialWithoutStatus = omit(initialValues, ['issueStatusId'])
		const currentWithoutStatus = omit(currentValues, ['issueStatusId'])
		
		const isStatusChanged = initialValues.issueStatusId !== currentValues.issueStatusId
		const otherFieldsChanged = !isEqual(initialWithoutStatus, currentWithoutStatus)
		const isOnlyStatusChanged = isStatusChanged && !otherFieldsChanged

		if (data?.ordinance !== null && data?.ordinance !== undefined && !isOnlyStatusChanged) {
			modal.confirm({
				title: 'Внимание',
				content:
					'По данному замечанию уже создано предписание! Внесённые изменения не попадут в печатную форму предписания. Сохранить?',
				onOk: () => {
					mutate(dto)
				}
			})
		} else {
			mutate(dto)
		}
	}
	const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
		<>
			{label}
			{required && <sup style={{ color: 'red' }}>*</sup>}
		</>
	)

	return (
		<Form
			name="issueCardForm"
			autoComplete='off'
			scrollToFirstError={{
				block: 'center'
			}}
			colon={false}
			requiredMark={customizeRequiredMark}
			form={issueCardForm}
			labelAlign="left"
			labelCol={{ span: 6 }}
			layout="inline"
			onValuesChange={changedValues => onChangeHandler(changedValues)}
			onFinish={onSave}
		>
			<IssueCardChangeStatusModal
				form={issueCardForm}
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
			/>
			<Drawer
				classNames={{
					body: 'app-entity-card__body',
					header: `app-entity-card__header app-entity-card__header--${Object.entries(ISSUE_STATUS).find(s => s[1] === formValues?.issueStatusId)?.[0] ??
						'open'
						}`
				}}
				rootClassName="app-entity-card"
				styles={{ body: { background: '#fafafa', padding: 0 } }}
				loading={isFetching}
				onClose={onCloseHandler}
				destroyOnClose={true}
				open={issueId !== null || showCard}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={
					data
						? `Замечание №${data.number} от ${dayjs(data.createdAt).format(DATE_FORMAT.view)}`
						: 'Новое замечание'
				}
				extra={
					<IssueCardHeader
						isNew={!!!data?.id}
						canEdit={canEdit!}
						form={issueCardForm}
						isLoading={isLoading || isLoadingAttachments}
					/>
				}
			>
				<Tabs items={tabItems} className="app-tabs-sticky" size="large" tabBarGutter={16} />
			</Drawer>
		</Form>
	)
}
