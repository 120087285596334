import {
	Column,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Header,
	Row,
	RowData,
	useReactTable
} from '@tanstack/react-table'
import {
	defaultRangeExtractor,
	elementScroll,
	Range,
	useVirtualizer,
	VirtualizerOptions
} from '@tanstack/react-virtual'
import { Card } from 'antd'
import lodash, { isEmpty } from 'lodash'
import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TEstimateType } from 'shared/schema'
import LoaderSpinner from 'UI/loaderSpinner'
import { RoundedNumber } from 'widgets/estimate/model/estimate-helper'
import 'widgets/estimate/ui/estimate.sass'
import { useShallow } from 'zustand/react/shallow'
import { useAdjustmentColumnConfig } from '../model/adjustment-column-config-state'
import { useAdjustmentSectionsQuery } from '../model/adjustment-query'
import {
	IAdjustmentPosition,
	IAdjustmentPositionMaterial,
	IAdjustmentSection
} from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { useAdjustmentColumns } from '../model/useAdjustmentColumns'
import './styles.sass'
declare module '@tanstack/react-table' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface TableMeta<TData extends RowData> {
		updateData: (rowIndex: number, columnId: string, value: unknown) => void
	}
}

enum TOTAL_COLS {
	totalDeviationCurrencyOrigin = 'totalDeviationCurrencyOrigin',
	totalDeviationCurrencySdu = 'totalDeviationCurrencySdu',
	totalDeviationPercentageOrigin = 'totalDeviationPercentageOrigin',
	totalDeviationPercentageSdu = 'totalDeviationPercentageSdu',
	totalSum = 'totalSum',
	totalSumMaterials = 'totalSumMaterials',
	totalSumMaterialsSdu = 'totalSumMaterialsSdu',
	totalSumMaterialsSecond = 'totalSumMaterialsSecond',
	totalSumSdu = 'totalSumSdu',
	totalSumSecond = 'totalSumSecond',
	totalSumService = 'totalSumService',
	totalSumServiceSdu = 'totalSumServiceSdu',
	totalSumServiceSecond = 'totalSumServiceSecond'
}

interface IProps {
	type: TEstimateType
}

function easeInOutQuint(t: number) {
	return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
}

export const AdjustmentSections: FC<IProps> = ({ type }) => {
	const { isFetching } = useAdjustmentSectionsQuery(type)
	const {
		container,
		data,
		expandedRows,
		isLoading,
		scrollToNewElement,
		selectedRows,
		setExpandedRows
	} = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			data: state.sections,
			expandedRows: state.expandedRows,
			isLoading: state.isLoading,
			scrollToNewElement: state.scrollToNewElement,
			selectedRows: state.selectedRows,
			setExpandedRows: state.setExpandedRows,
			setSelectedRows: state.setSelectedRows
		}))
	)

	const visibleColumns = useAdjustmentColumnConfig(useShallow(state => state[type]))

	const [expanded, setExpanded] = useState<ExpandedState>({})
	const tableHeight = useMemo(() => {
		const appHeader = document.getElementById('app-header')?.offsetHeight ?? 0
		const breadcrumbs = document.getElementById('app-breadcrumbs')?.offsetHeight ?? 0
		const estimateHeader = document.getElementById('app-estimateHeader')?.offsetHeight ?? 0
		return window.innerHeight - (appHeader + breadcrumbs + estimateHeader + 250)
	}, [])
	const columns = useAdjustmentColumns()
	useEffect(() => {
		setExpandedRows(expanded)
	}, [expanded])

	useEffect(() => {
		if (isEmpty(expandedRows)) {
			table.resetExpanded()
		}
	}, [expandedRows])

	const getColTotalSum = (cellId: string) => {
		const col = Object.values(TOTAL_COLS).find(t => t === cellId)
		if (typeof col !== 'undefined') {
			return RoundedNumber(container?.workTotal[col])
		}
		return null
	}

	const table = useReactTable({
		data,
		columns,

		state: {
			expanded: expandedRows,
			rowSelection: selectedRows.rows,
			columnPinning: {
				left: ['name', 'measureUnit']
			},
			columnVisibility: {
				//origin start
				amount: visibleColumns.origin,
				rate: visibleColumns.origin,
				amountWithRate: visibleColumns.origin,
				confirmedVolumeAmount: type === 'local' ? visibleColumns?.done ?? false : false,
				confirmedVolumeSum: type === 'local' ? visibleColumns?.done ?? false : false,
				priceMaterial: visibleColumns.origin,
				priceService: visibleColumns.origin,
				totalPrice: visibleColumns.origin,
				totalSumMaterials: visibleColumns.origin,
				totalSumService: visibleColumns.origin,
				totalSum: visibleColumns.origin,
				isNominated: visibleColumns.origin,
				amountWithRateInclude: visibleColumns.origin,
				priceMaterialInclude: visibleColumns.origin,
				priceServiceInclude: visibleColumns.origin,
				totalSumInclude: visibleColumns.origin,
				amountWithRateExclude: visibleColumns.origin,
				priceMaterialExclude: visibleColumns.origin,
				priceServiceExclude: visibleColumns.origin,
				totalSumExclude: visibleColumns.origin,
				//origin end

				//second start
				amountSecond: visibleColumns.second,
				rateSecond: visibleColumns.second,
				amountWithRateSecond: visibleColumns.second,
				priceMaterialSecond: visibleColumns.second,
				priceServiceSecond: visibleColumns.second,
				totalPriceSecond: visibleColumns.second,
				totalSumMaterialsSecond: visibleColumns.second,
				totalSumServiceSecond: visibleColumns.second,
				totalSumSecond: visibleColumns.second,
				isNominatedSecond: visibleColumns.second,
				amountWithRateSecondInclude: visibleColumns.second,
				priceMaterialSecondInclude: visibleColumns.second,
				priceServiceSecondInclude: visibleColumns.second,
				totalSumSecondInclude: visibleColumns.second,
				amountWithRateSecondExclude: visibleColumns.second,
				priceMaterialSecondExclude: visibleColumns.second,
				priceServiceSecondExclude: visibleColumns.second,
				totalSumSecondExclude: visibleColumns.second,
				//second end

				//sdu start
				amountSdu: visibleColumns.sdu,
				rateSdu: visibleColumns.sdu,
				amountWithRateSdu: visibleColumns.sdu,
				priceMaterialSdu: visibleColumns.sdu,
				priceServiceSdu: visibleColumns.sdu,
				totalPriceSdu: visibleColumns.sdu,
				totalSumMaterialsSdu: visibleColumns.sdu,
				totalSumServiceSdu: visibleColumns.sdu,
				totalSumSdu: visibleColumns.sdu,
				isNominatedSdu: visibleColumns.sdu,
				//sdu end

				//deviation start
				totalDeviationCurrencySdu:
					visibleColumns.origin || visibleColumns.second || visibleColumns.sdu,
				totalDeviationPercentageSdu:
					visibleColumns.origin || visibleColumns.second || visibleColumns.sdu,
				totalDeviationCurrencyOrigin:
					visibleColumns.origin || visibleColumns.second || visibleColumns.sdu,
				totalDeviationPercentageOrigin:
					visibleColumns.origin || visibleColumns.second || visibleColumns.sdu,
				//deviation end

				//comments start
				commentSdu: visibleColumns.comments,
				justification: visibleColumns.comments
				//comments end
			}
		},
		enableColumnResizing: false,
		getRowId: originalRow => originalRow.id,
		getSubRows: row => (!('materialName' in row) ? row.children : []),
		getRowCanExpand: (
			row: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>
		) =>
			!('materialName' in row.original) &&
			(('hasPositions' in row.original && row.original.hasPositions) ||
				(('codifier' in row.original || 'workName' in row.original) &&
					!!row.original.children?.length)),
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel()
	})

	const { rows } = table.getRowModel()

	const parentRef = useRef<HTMLDivElement>(null)

	const activeStickyIndexRef = useRef(0)

	const stickyIndexes = useMemo(() => {
		const arr: number[] = []
		rows.forEach((r, index) => {
			if ('codifier' in r.original && r.getIsExpanded()) {
				arr.push(index)
			}
		})
		return arr
	}, [data, rows])

	const isSticky = (index: number) => stickyIndexes.includes(index)

	const isActiveSticky = (index: number) => activeStickyIndexRef.current === index
	const scrollingRef = useRef<number>()

	const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback(
		(offset, canSmooth, instance) => {
			const duration = 1000
			const start = parentRef?.current?.scrollTop ?? 0
			const startTime = (scrollingRef.current = Date.now())

			const run = () => {
				if (scrollingRef.current !== startTime) return
				const now = Date.now()
				const elapsed = now - startTime
				const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
				const interpolated = start + (offset - start) * progress

				if (elapsed < duration) {
					elementScroll(interpolated, canSmooth, instance)
					requestAnimationFrame(run)
				} else {
					elementScroll(interpolated, canSmooth, instance)
				}
			}
			requestAnimationFrame(run)
		},
		[]
	)

	const virtualizer = useVirtualizer({
		count: rows.length,
		overscan: 30,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 32,
		scrollToFn,
		rangeExtractor: useCallback(
			(range: Range) => {
				activeStickyIndexRef.current =
					[...stickyIndexes].reverse().find(index => range.startIndex >= index) ?? 0
				const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)])
				return [...next].sort((a, b) => a - b)
			},
			[stickyIndexes]
		)
	})

	useEffect(() => {
		if (scrollToNewElement) {
			const elem = rows.findIndex(r => r.id === scrollToNewElement)
			virtualizer.scrollToIndex(elem, { align: 'center', behavior: 'smooth' })
			useAdjustmentState.setState({ scrollToNewElement: undefined })
		}
	}, [scrollToNewElement])

	const getCommonPinningStyles = (
		column: Column<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>
	): CSSProperties => {
		const isPinned = column.getIsPinned()
		const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
		const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')

		return {
			boxShadow: isLastLeftPinnedColumn
				? '-4px 0 4px -4px gray inset'
				: isFirstRightPinnedColumn
				? '4px 0 4px -4px gray inset'
				: undefined,
			left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
			right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
			position: isPinned ? 'sticky' : 'relative',
			width: column.getSize(),
			maxWidth: column.getSize(),
			zIndex: isPinned ? 1 : 0
		}
	}

	const getHeaderGroupsStyle = (
		row: Header<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial, unknown>,
		type: 'first' | 'last',
		index: number,
		groupName?: string
	) => {
		if (groupName) {
			const groupCells = row.headerGroup.headers.filter(c =>
				c.column.parent?.id.includes(groupName)
			)

			if (type === 'first' && groupCells[0]?.column.getIndex() === index) {
				return 'estimate-header__cell--group-first'
			}

			if (type === 'last' && lodash.last(groupCells)?.column.getIndex() === index) {
				return 'estimate-header__cell--group-last'
			}
		}
		return ''
	}

	const getRowGroupsStyle = (
		row: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>,
		type: 'first' | 'last',
		index: number,
		groupName?: string
	) => {
		if (groupName) {
			const groupCells = row.getAllCells().filter(c => c.column.parent?.id.includes(groupName))

			if (type === 'first' && groupCells[0]?.column.getIndex() === index) {
				return 'estimate-body__cell--group-first'
			}

			if (type === 'last' && lodash.last(groupCells)?.column.getIndex() === index) {
				return 'estimate-body__cell--group-last'
			}
		}
		return ''
	}

	return (
		<Card size="small">
			{isLoading && <LoaderSpinner />}
			<div
				ref={parentRef}
				style={{
					width: '100%',
					maxWidth: '100dvw',
					height: tableHeight,
					position: 'relative',
					overflow: 'auto'
				}}
			>
				<table
					className="estimate"
					style={{
						display: 'grid',
						width: table
							.getVisibleLeafColumns()
							.reduce((partialSum, a) => partialSum + a.getSize(), 0)
					}}
				>
					<thead className="estimate-header" id="estimate-header">
						{table.getHeaderGroups().map((headerGroup, i) => (
							<tr
								key={headerGroup.id}
								className="estimate-header__row"
								id={`estimate-header__row${i}`}
							>
								{headerGroup.headers.map((header, i) => {
									const columnRelativeDepth = header.depth - header.column.depth

									if (
										!header.isPlaceholder &&
										columnRelativeDepth > 1 &&
										header.id === header.column.id
									) {
										return null
									}

									let rowSpan = 1
									if (header.isPlaceholder) {
										const leafs = header.getLeafHeaders()
										rowSpan = leafs[leafs.length - 1].depth - header.depth
									}

									return (
										<th
											key={header.id}
											rowSpan={header.id === 'name' ? 3 : rowSpan}
											colSpan={header.colSpan}
											className={`${
												header.column.getIsPinned()
													? 'estimate-header__cell estimate-header__cell--fixed'
													: 'estimate-header__cell'
											}
												${
													header.column.id.toLowerCase().includes('group') ||
													header.column.parent?.id.toLowerCase().includes('group')
														? 'estimate-header__cell--group'
														: ''
												}
												${getHeaderGroupsStyle(
													header,
													'first',
													i,
													header.column.id.toLowerCase().includes('group') ||
														header.column.parent?.id.toLowerCase().includes('group')
														? header.column.parent?.id ?? header.column.id
														: ''
												)}
												${getHeaderGroupsStyle(
													header,
													'last',
													i,
													header.column.id.toLowerCase().includes('group') ||
														header.column.parent?.id.toLowerCase().includes('group')
														? header.column.parent?.id ?? header.column.id
														: ''
												)}
												`}
											data-qa={rowSpan}
											style={{
												width: header.getSize(),
												...getCommonPinningStyles(header.column)
											}}
										>
											{flexRender(header.column.columnDef.header, header.getContext())}
										</th>
									)
								})}
							</tr>
						))}
					</thead>
					<tbody className="estimate-body" style={{ height: `${virtualizer.getTotalSize()}px` }}>
						<tr style={{ height: 32 }} className="estimate-body__row estimate-body__row--summary ">
							{table.getVisibleLeafColumns().map(cell => (
								<td
									data-qa={cell.id}
									className={`${
										cell.getIsPinned()
											? 'estimate-body__cell estimate-body__cell--fixed'
											: 'estimate-body__cell'
									}
											
											`}
									key={cell.id}
									style={{
										width: cell.getSize(),
										...getCommonPinningStyles(cell)
									}}
								>
									{getColTotalSum(cell.id)}
								</td>
							))}
						</tr>
						{virtualizer.getVirtualItems().map((virtualRow, index) => {
							const row = rows[virtualRow.index] as Row<
								IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial
							>

							return (
								<tr
									data-index={row.id}
									className={`estimate-body__row estimate-body__row-expanded estimate-body__row-expanded-level-${
										row.getParentRows().length
									}${
										'isActualRelationship' in row.original &&
										row.original.isActualRelationship === false
											? 'estimate-body__row--danger'
											: ''
									} ${
										'isUnrelated' in row.original && row.original.isUnrelated === true
											? 'workName' in row.original
												? 'estimate-body__row--unrelated'
												: 'estimate-body__row--material-unrelated'
											: ''
									}
										${
											!('codifier' in row.original) &&
											'isDeleted' in row.original &&
											row.original.isDeleted === true
												? 'estimate-body__row--deleted'
												: ''
										}
									${'workName' in row.original ? 'estimate-body__row--work' : ''}
										`}
									key={virtualRow.key}
									style={{
										...(isSticky(virtualRow.index)
											? {
													background: '#fff',
													borderBottom: '1px solid #ddd',
													zIndex: 9
											  }
											: {}),
										...(isActiveSticky(virtualRow.index)
											? {
													position: 'sticky',
													top: document.getElementById('estimate-header')?.offsetHeight
											  }
											: {
													transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`
											  }),
										height: `${virtualRow.size}px`
									}}
								>
									{row.getVisibleCells().map((cell, i) => {
										return (
											<td
												data-qa={cell.column.parent?.id}
												className={`
													estimate-body__cell 
													${cell.column.getIsPinned() ? 'estimate-body__cell estimate-body__cell--fixed' : ''} 
														${cell.column.parent?.id.toLowerCase().includes('group') ? 'estimate-body__cell--group' : ''}
														${getRowGroupsStyle(
															row,
															'first',
															i,
															cell.column.parent?.id.toLowerCase().includes('group')
																? cell.column.parent?.id
																: ''
														)}
													${getRowGroupsStyle(
														row,
														'last',
														i,
														cell.column.parent?.id.toLowerCase().includes('group')
															? cell.column.parent?.id
															: ''
													)}
													`}
												key={cell.id}
												style={{
													...getCommonPinningStyles(cell.column),
													paddingLeft:
														cell.column.getIndex() === 0
															? row.depth === 0
																? '1rem'
																: row.depth * 2 + 'rem'
															: 'auto'
												}}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			</div>
		</Card>
	)
}
