import { Tabs, message } from 'antd'
import { useAppDispatch } from 'hooks/appReduxHook'
import { ICurrentChecklistItem, ISectionIssue } from 'interfaces/IChecklist'
import { FC, useEffect, useState } from 'react'
import { getIssueById } from 'services/IssuesService'
import { setCurrentIssueId } from 'store/checklistsSlice'
import SectionFiles from './SectionFiles'
import SectionIssues from './SectionIssues'

interface IProps {
	item: ICurrentChecklistItem
	onItemUpdate: (
		item: ICurrentChecklistItem,
		answer: number | null,
		issueIndex?: number
	) => Promise<void>
	onAddIssue: (item?: ICurrentChecklistItem, action?: 'add' | 'show') => void
	onAddFile: (e: any, itemId: string) => void
	allDisabled: boolean
	getItemColor: (item: ICurrentChecklistItem, type: string) => string
	issueDisabled: boolean
	fileDisabled: boolean
	// checkPermissions: (currentPermits: string[]) => boolean
}

const SectionAttachments: FC<IProps> = ({
	item,
	onItemUpdate,
	onAddIssue,
	onAddFile,
	allDisabled,
	getItemColor,
	issueDisabled,
	fileDisabled
	// checkPermissions
}) => {
	const dispatch = useAppDispatch()
	const [view, setView] = useState<string>('files')
	const [issuesList, setIssuesList] = useState<ISectionIssue[]>([])
	const items = [
		{
			label: (
				<div
					style={{ color: getItemColor(item, 'file') }}
				>{`Файлы: ${item.attachments.length}`}</div>
			),
			key: 'files'
		},
		{
			label: (
				<div
					style={{ color: getItemColor(item, 'issue') }}
				>{`Замечания: ${item.issues.length}`}</div>
			),
			key: 'issues'
		}
	]

	const onTabChange = (key: string) => {
		setView(key)
	}
	const openIssueHandler = (issueId?: string) => {
		if (issueId) {
			getIssueById(issueId)
				.then(() => {
					// item && dispatch(setCurrentItem(item))
					// setIssueModalVisible(true)
					dispatch(setCurrentIssueId(issueId))
					onAddIssue(item, 'show')
				})
				.catch(() => {
					message.error('Замечание не принадлежит вашей компании')
				})
		} else {
			onAddIssue(item, 'add')
		}
	}

	useEffect(() => {
		setIssuesList(item.issues)
	}, [item])

	return (
		<div className="section-files-and-issues">
			<Tabs items={items} defaultActiveKey="checklistTab" onTabClick={key => onTabChange(key)} />
			{view === 'files' ? (
				<SectionFiles
					item={item}
					onItemUpdate={onItemUpdate}
					onAddFile={onAddFile}
					allDisabled={fileDisabled}
					// checkPermissions={checkPermissions}
				/>
			) : (
				<SectionIssues
					issuesList={issuesList}
					openIssueHandler={openIssueHandler}
					allDisabled={issueDisabled}
					// checkPermissions={checkPermissions}
				/>
			)}
		</div>
	)
}

export default SectionAttachments
