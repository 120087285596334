import { API } from "services/ApiService"

export const wbsTaskVisasApi = {
	async get(id: string) {
		return await API({
			url: `/v1/wbsTask/${id}/visas`,
			method: 'get'
		}).then(response => response.data)
	},
    async post(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approve`,
			method: 'post',
			params: { visaId }
		}).then(response => response.data)
	},
    async getCanVisaApprove(id: string) {
		return await API<string[]>({
			url: `/v1/wbsTask/${id}/approvalAvailable`,
			method: 'get'
		}).then(response => response.data)
	},
    async setVisaApprove(id: string, visaId?: string) {
		return await API({
			url: `/v1/wbsTask/${id}/approve`,
			method: 'post',
			params: { visaId }
		}).then(response => response.data)
	},
    async unsetVisaApprove(id: string, visaId: string) {
		return await API({
			url: `/v1/wbsTask/${id}/unapprove`,
			method: 'post',
			params: { visaId }
		}).then(response => response.data)
	}
}