import { IIssueCard } from 'entities/issues'
import { IIssueCardAttachment, TIssueDto } from 'entities/issues/model/issue-card-schema'
import { API } from 'services/ApiService'

export const issueCardApi = {
	async get(id: string) {
		return await API<IIssueCard>({
			url: `/v1/issues/${id}`,
			method: 'get'
		}).then(response => response.data)
	},
	async getAttachments(id: string) {
		return await API<IIssueCardAttachment[]>({
			url: `/v1/issues/${id}/attachments`,
			method: 'get'
		}).then(response => response.data)
	},

	async save(data: TIssueDto, method: 'post' | 'patch') {
		return await API({
			url: `/v1/issues`,
			method,
			data
		}).then(response => response.data)
	}
}
