import { Descriptions, message, Modal } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { ICurrentChecklist } from 'interfaces/IChecklist'
import { FC, useState } from 'react'
import { createAct } from 'services/ChecklistsService'
import LoaderSpinner from 'UI/loaderSpinner'

interface IPreviewActModalProps {
	open: boolean
	checklist: ICurrentChecklist
	onClose: (mustUpdate?: boolean) => void
}

export const PreviewActModal: FC<IPreviewActModalProps> = ({ open, checklist, onClose }) => {
	const [isLoading, setIsLoading] = useState(false)
	const onSendEliminationAct = async () => {
		setIsLoading(true)
		await createAct(checklist.id)
			.then(response => {
				message.success(
					`Формируется Акт передачи. В течении минуты файл будет отображаться в MyABS`
				)
				onClose(true)
			})
			.catch((error: AxiosError<IErrorDetail>) =>
				Modal.error({
					content: `Произошла ошибка во время сохранения: ${error.response?.data.detail}`,
					zIndex: 99999999999,
					onOk: () => onClose()
				})
			)
			.finally(() => setIsLoading(false))
	}

	return (
		<Modal
			open={open}
			okText="Сформировать"
			onOk={onSendEliminationAct}
			onCancel={() => onClose()}
			width="50vw"
			centered
			zIndex={9999999999}
			title={'Предпросмотр акта передачи'}
		>
			{isLoading && <LoaderSpinner />}
			<Descriptions
				title="Основные параметры:"
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				<Descriptions.Item label="Генподрядчик:" span={12}>
					{checklist?.contractor!?.name!}
				</Descriptions.Item>
				{/* <Descriptions.Item label="Объект строительства:" span={12}>
					{ordinance?.constructionObject!?.name!}
				</Descriptions.Item> */}
			</Descriptions>

			<Descriptions
				style={{ marginTop: '1rem' }}
				// title="Выбранные замечания:"
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				{
					<>
						<Descriptions.Item label="Описание нарушения:" span={12}>
							{checklist!?.description!}
						</Descriptions.Item>
						{/* <Descriptions.Item
								label={`Принятые действия по ${
									eliminationActPreview.eliminationActPreview === true
										? 'устранению'
										: 'неустранению'
								}:`}
								span={12}
							>
								{checklist?.response!?.answer!}
							</Descriptions.Item>
							<Descriptions.Item
								label={`Дата ${
									eliminationActPreview.eliminationActPreview === true
										? 'устранения'
										: 'неустранения'
								}:`}
								span={12}
							>
								{checklist!?.response!?.date! !== null
									? dayjs(
										checklist!?.response!?.date!
									  ).format('DD.MM.YYYY')
									: ''}
							</Descriptions.Item>
							{selectedIssueRows.length > 1 && (
								<Descriptions.Item span={12}>
									<Divider />
								</Descriptions.Item>
							)} */}
					</>
				}
			</Descriptions>
		</Modal>
	)
}
